// source: qai_hub/public_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.qai_hub.api.CompileDetail', null, global);
goog.exportSymbol('proto.qai_hub.api.CompileJob', null, global);
goog.exportSymbol('proto.qai_hub.api.CompileJobResult', null, global);
goog.exportSymbol('proto.qai_hub.api.CompileJobSummary', null, global);
goog.exportSymbol('proto.qai_hub.api.ComputeUnit', null, global);
goog.exportSymbol('proto.qai_hub.api.CreateUpdateResponse', null, global);
goog.exportSymbol('proto.qai_hub.api.Dataset', null, global);
goog.exportSymbol('proto.qai_hub.api.DatasetList', null, global);
goog.exportSymbol('proto.qai_hub.api.Device', null, global);
goog.exportSymbol('proto.qai_hub.api.DeviceList', null, global);
goog.exportSymbol('proto.qai_hub.api.FileDownloadURL', null, global);
goog.exportSymbol('proto.qai_hub.api.FileUploadURL', null, global);
goog.exportSymbol('proto.qai_hub.api.InferenceJob', null, global);
goog.exportSymbol('proto.qai_hub.api.InferenceJobResult', null, global);
goog.exportSymbol('proto.qai_hub.api.InferenceJobSummary', null, global);
goog.exportSymbol('proto.qai_hub.api.Job', null, global);
goog.exportSymbol('proto.qai_hub.api.Job.JobCase', null, global);
goog.exportSymbol('proto.qai_hub.api.JobList', null, global);
goog.exportSymbol('proto.qai_hub.api.JobPublicUpdate', null, global);
goog.exportSymbol('proto.qai_hub.api.JobResult', null, global);
goog.exportSymbol('proto.qai_hub.api.JobResult.ResultCase', null, global);
goog.exportSymbol('proto.qai_hub.api.JobState', null, global);
goog.exportSymbol('proto.qai_hub.api.JobSummary', null, global);
goog.exportSymbol('proto.qai_hub.api.JobSummary.JobCase', null, global);
goog.exportSymbol('proto.qai_hub.api.JobSummaryList', null, global);
goog.exportSymbol('proto.qai_hub.api.JobType', null, global);
goog.exportSymbol('proto.qai_hub.api.LayerDetail', null, global);
goog.exportSymbol('proto.qai_hub.api.LinkDetail', null, global);
goog.exportSymbol('proto.qai_hub.api.LinkJob', null, global);
goog.exportSymbol('proto.qai_hub.api.LinkJobResult', null, global);
goog.exportSymbol('proto.qai_hub.api.LinkJobSummary', null, global);
goog.exportSymbol('proto.qai_hub.api.Model', null, global);
goog.exportSymbol('proto.qai_hub.api.ModelList', null, global);
goog.exportSymbol('proto.qai_hub.api.ModelMetadata', null, global);
goog.exportSymbol('proto.qai_hub.api.ModelMetadataKey', null, global);
goog.exportSymbol('proto.qai_hub.api.ModelType', null, global);
goog.exportSymbol('proto.qai_hub.api.NamedTensorType', null, global);
goog.exportSymbol('proto.qai_hub.api.NamedTensorTypeList', null, global);
goog.exportSymbol('proto.qai_hub.api.Notice', null, global);
goog.exportSymbol('proto.qai_hub.api.Notice.Context', null, global);
goog.exportSymbol('proto.qai_hub.api.Notice.Severity', null, global);
goog.exportSymbol('proto.qai_hub.api.Organization', null, global);
goog.exportSymbol('proto.qai_hub.api.ProfileDetail', null, global);
goog.exportSymbol('proto.qai_hub.api.ProfileDetail.MemoryUsage', null, global);
goog.exportSymbol('proto.qai_hub.api.ProfileJob', null, global);
goog.exportSymbol('proto.qai_hub.api.ProfileJobResult', null, global);
goog.exportSymbol('proto.qai_hub.api.ProfileJobSummary', null, global);
goog.exportSymbol('proto.qai_hub.api.QuantizeDetail', null, global);
goog.exportSymbol('proto.qai_hub.api.QuantizeDtype', null, global);
goog.exportSymbol('proto.qai_hub.api.QuantizeJob', null, global);
goog.exportSymbol('proto.qai_hub.api.QuantizeJobResult', null, global);
goog.exportSymbol('proto.qai_hub.api.QuantizeJobSummary', null, global);
goog.exportSymbol('proto.qai_hub.api.Range', null, global);
goog.exportSymbol('proto.qai_hub.api.RuntimeConfig', null, global);
goog.exportSymbol('proto.qai_hub.api.RuntimeConfigProperty', null, global);
goog.exportSymbol('proto.qai_hub.api.SegmentDetail', null, global);
goog.exportSymbol('proto.qai_hub.api.ServiceStatus', null, global);
goog.exportSymbol('proto.qai_hub.api.SharedAccess', null, global);
goog.exportSymbol('proto.qai_hub.api.SharedAccessChange', null, global);
goog.exportSymbol('proto.qai_hub.api.TensorDtype', null, global);
goog.exportSymbol('proto.qai_hub.api.TensorType', null, global);
goog.exportSymbol('proto.qai_hub.api.ToolVersion', null, global);
goog.exportSymbol('proto.qai_hub.api.User', null, global);
goog.exportSymbol('proto.qai_hub.api.UserChangePassword', null, global);
goog.exportSymbol('proto.qai_hub.api.UserList', null, global);
goog.exportSymbol('proto.qai_hub.api.VizAttribute', null, global);
goog.exportSymbol('proto.qai_hub.api.VizAttribute.TypeCase', null, global);
goog.exportSymbol('proto.qai_hub.api.VizDtype', null, global);
goog.exportSymbol('proto.qai_hub.api.VizGraph', null, global);
goog.exportSymbol('proto.qai_hub.api.VizGraphType', null, global);
goog.exportSymbol('proto.qai_hub.api.VizNode', null, global);
goog.exportSymbol('proto.qai_hub.api.VizShape', null, global);
goog.exportSymbol('proto.qai_hub.api.VizSubgraph', null, global);
goog.exportSymbol('proto.qai_hub.api.VizTensor', null, global);
goog.exportSymbol('proto.qai_hub.api.VizValue', null, global);
goog.exportSymbol('proto.qai_hub.api.VizValue.BoolList', null, global);
goog.exportSymbol('proto.qai_hub.api.VizValue.FloatList', null, global);
goog.exportSymbol('proto.qai_hub.api.VizValue.IntegerList', null, global);
goog.exportSymbol('proto.qai_hub.api.VizValue.StringList', null, global);
goog.exportSymbol('proto.qai_hub.api.VizValue.ValueCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.CreateUpdateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.CreateUpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.CreateUpdateResponse.displayName = 'proto.qai_hub.api.CreateUpdateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.FileDownloadURL = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.FileDownloadURL, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.FileDownloadURL.displayName = 'proto.qai_hub.api.FileDownloadURL';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.SharedAccess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.SharedAccess.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.SharedAccess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.SharedAccess.displayName = 'proto.qai_hub.api.SharedAccess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.SharedAccessChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.SharedAccessChange.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.SharedAccessChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.SharedAccessChange.displayName = 'proto.qai_hub.api.SharedAccessChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.FileUploadURL = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.FileUploadURL, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.FileUploadURL.displayName = 'proto.qai_hub.api.FileUploadURL';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.JobPublicUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.JobPublicUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.JobPublicUpdate.displayName = 'proto.qai_hub.api.JobPublicUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.ServiceStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.ServiceStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.ServiceStatus.displayName = 'proto.qai_hub.api.ServiceStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.User.displayName = 'proto.qai_hub.api.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.UserList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.UserList.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.UserList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.UserList.displayName = 'proto.qai_hub.api.UserList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.UserChangePassword = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.UserChangePassword, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.UserChangePassword.displayName = 'proto.qai_hub.api.UserChangePassword';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.Organization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.Organization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.Organization.displayName = 'proto.qai_hub.api.Organization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.Device = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.Device.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.Device, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.Device.displayName = 'proto.qai_hub.api.Device';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.DeviceList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.DeviceList.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.DeviceList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.DeviceList.displayName = 'proto.qai_hub.api.DeviceList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.Dataset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.Dataset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.Dataset.displayName = 'proto.qai_hub.api.Dataset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.DatasetList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.DatasetList.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.DatasetList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.DatasetList.displayName = 'proto.qai_hub.api.DatasetList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.TensorType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.TensorType.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.TensorType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.TensorType.displayName = 'proto.qai_hub.api.TensorType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.NamedTensorType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.NamedTensorType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.NamedTensorType.displayName = 'proto.qai_hub.api.NamedTensorType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.NamedTensorTypeList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.NamedTensorTypeList.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.NamedTensorTypeList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.NamedTensorTypeList.displayName = 'proto.qai_hub.api.NamedTensorTypeList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.ModelMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.ModelMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.ModelMetadata.displayName = 'proto.qai_hub.api.ModelMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.Model = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.Model, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.Model.displayName = 'proto.qai_hub.api.Model';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.ModelList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.ModelList.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.ModelList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.ModelList.displayName = 'proto.qai_hub.api.ModelList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.Notice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.Notice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.Notice.displayName = 'proto.qai_hub.api.Notice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.CompileJob = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.CompileJob, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.CompileJob.displayName = 'proto.qai_hub.api.CompileJob';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.LinkJob = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.LinkJob, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.LinkJob.displayName = 'proto.qai_hub.api.LinkJob';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.QuantizeJob = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.QuantizeJob, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.QuantizeJob.displayName = 'proto.qai_hub.api.QuantizeJob';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.ProfileJob = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.ProfileJob, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.ProfileJob.displayName = 'proto.qai_hub.api.ProfileJob';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.InferenceJob = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.InferenceJob, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.InferenceJob.displayName = 'proto.qai_hub.api.InferenceJob';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.Job = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.Job.repeatedFields_, proto.qai_hub.api.Job.oneofGroups_);
};
goog.inherits(proto.qai_hub.api.Job, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.Job.displayName = 'proto.qai_hub.api.Job';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.JobList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.JobList.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.JobList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.JobList.displayName = 'proto.qai_hub.api.JobList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.JobSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.qai_hub.api.JobSummary.oneofGroups_);
};
goog.inherits(proto.qai_hub.api.JobSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.JobSummary.displayName = 'proto.qai_hub.api.JobSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.CompileJobSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.CompileJobSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.CompileJobSummary.displayName = 'proto.qai_hub.api.CompileJobSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.LinkJobSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.LinkJobSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.LinkJobSummary.displayName = 'proto.qai_hub.api.LinkJobSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.QuantizeJobSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.QuantizeJobSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.QuantizeJobSummary.displayName = 'proto.qai_hub.api.QuantizeJobSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.ProfileJobSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.ProfileJobSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.ProfileJobSummary.displayName = 'proto.qai_hub.api.ProfileJobSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.InferenceJobSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.InferenceJobSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.InferenceJobSummary.displayName = 'proto.qai_hub.api.InferenceJobSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.JobSummaryList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.JobSummaryList.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.JobSummaryList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.JobSummaryList.displayName = 'proto.qai_hub.api.JobSummaryList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.CompileJobResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.CompileJobResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.CompileJobResult.displayName = 'proto.qai_hub.api.CompileJobResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.LinkJobResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.LinkJobResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.LinkJobResult.displayName = 'proto.qai_hub.api.LinkJobResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.QuantizeJobResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.QuantizeJobResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.QuantizeJobResult.displayName = 'proto.qai_hub.api.QuantizeJobResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.ProfileJobResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.ProfileJobResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.ProfileJobResult.displayName = 'proto.qai_hub.api.ProfileJobResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.InferenceJobResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.InferenceJobResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.InferenceJobResult.displayName = 'proto.qai_hub.api.InferenceJobResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.JobResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.qai_hub.api.JobResult.oneofGroups_);
};
goog.inherits(proto.qai_hub.api.JobResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.JobResult.displayName = 'proto.qai_hub.api.JobResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.VizGraph = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.VizGraph.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.VizGraph, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.VizGraph.displayName = 'proto.qai_hub.api.VizGraph';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.VizSubgraph = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.VizSubgraph.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.VizSubgraph, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.VizSubgraph.displayName = 'proto.qai_hub.api.VizSubgraph';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.VizShape = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.VizShape.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.VizShape, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.VizShape.displayName = 'proto.qai_hub.api.VizShape';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.VizTensor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.VizTensor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.VizTensor.displayName = 'proto.qai_hub.api.VizTensor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.VizNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.VizNode.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.VizNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.VizNode.displayName = 'proto.qai_hub.api.VizNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.VizValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.qai_hub.api.VizValue.oneofGroups_);
};
goog.inherits(proto.qai_hub.api.VizValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.VizValue.displayName = 'proto.qai_hub.api.VizValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.VizValue.StringList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.VizValue.StringList.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.VizValue.StringList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.VizValue.StringList.displayName = 'proto.qai_hub.api.VizValue.StringList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.VizValue.IntegerList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.VizValue.IntegerList.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.VizValue.IntegerList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.VizValue.IntegerList.displayName = 'proto.qai_hub.api.VizValue.IntegerList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.VizValue.FloatList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.VizValue.FloatList.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.VizValue.FloatList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.VizValue.FloatList.displayName = 'proto.qai_hub.api.VizValue.FloatList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.VizValue.BoolList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.VizValue.BoolList.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.VizValue.BoolList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.VizValue.BoolList.displayName = 'proto.qai_hub.api.VizValue.BoolList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.VizAttribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.qai_hub.api.VizAttribute.oneofGroups_);
};
goog.inherits(proto.qai_hub.api.VizAttribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.VizAttribute.displayName = 'proto.qai_hub.api.VizAttribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.LayerDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.LayerDetail.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.LayerDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.LayerDetail.displayName = 'proto.qai_hub.api.LayerDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.SegmentDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.SegmentDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.SegmentDetail.displayName = 'proto.qai_hub.api.SegmentDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.Range = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.Range, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.Range.displayName = 'proto.qai_hub.api.Range';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.RuntimeConfigProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.RuntimeConfigProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.RuntimeConfigProperty.displayName = 'proto.qai_hub.api.RuntimeConfigProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.RuntimeConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.RuntimeConfig.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.RuntimeConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.RuntimeConfig.displayName = 'proto.qai_hub.api.RuntimeConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.ToolVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.ToolVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.ToolVersion.displayName = 'proto.qai_hub.api.ToolVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.ProfileDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.ProfileDetail.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.ProfileDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.ProfileDetail.displayName = 'proto.qai_hub.api.ProfileDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.ProfileDetail.MemoryUsage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qai_hub.api.ProfileDetail.MemoryUsage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.ProfileDetail.MemoryUsage.displayName = 'proto.qai_hub.api.ProfileDetail.MemoryUsage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.CompileDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.CompileDetail.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.CompileDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.CompileDetail.displayName = 'proto.qai_hub.api.CompileDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.LinkDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.LinkDetail.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.LinkDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.LinkDetail.displayName = 'proto.qai_hub.api.LinkDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qai_hub.api.QuantizeDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qai_hub.api.QuantizeDetail.repeatedFields_, null);
};
goog.inherits(proto.qai_hub.api.QuantizeDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qai_hub.api.QuantizeDetail.displayName = 'proto.qai_hub.api.QuantizeDetail';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.CreateUpdateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.CreateUpdateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.CreateUpdateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.CreateUpdateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expirationTime: (f = msg.getExpirationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.CreateUpdateResponse}
 */
proto.qai_hub.api.CreateUpdateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.CreateUpdateResponse;
  return proto.qai_hub.api.CreateUpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.CreateUpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.CreateUpdateResponse}
 */
proto.qai_hub.api.CreateUpdateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.CreateUpdateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.CreateUpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.CreateUpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.CreateUpdateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpirationTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.qai_hub.api.CreateUpdateResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.CreateUpdateResponse} returns this
 */
proto.qai_hub.api.CreateUpdateResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.qai_hub.api.CreateUpdateResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.CreateUpdateResponse} returns this
 */
proto.qai_hub.api.CreateUpdateResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp creation_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.CreateUpdateResponse.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.CreateUpdateResponse} returns this
*/
proto.qai_hub.api.CreateUpdateResponse.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CreateUpdateResponse} returns this
 */
proto.qai_hub.api.CreateUpdateResponse.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CreateUpdateResponse.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp expiration_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.CreateUpdateResponse.prototype.getExpirationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.CreateUpdateResponse} returns this
*/
proto.qai_hub.api.CreateUpdateResponse.prototype.setExpirationTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CreateUpdateResponse} returns this
 */
proto.qai_hub.api.CreateUpdateResponse.prototype.clearExpirationTime = function() {
  return this.setExpirationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CreateUpdateResponse.prototype.hasExpirationTime = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.FileDownloadURL.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.FileDownloadURL.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.FileDownloadURL} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.FileDownloadURL.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filename: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.FileDownloadURL}
 */
proto.qai_hub.api.FileDownloadURL.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.FileDownloadURL;
  return proto.qai_hub.api.FileDownloadURL.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.FileDownloadURL} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.FileDownloadURL}
 */
proto.qai_hub.api.FileDownloadURL.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.FileDownloadURL.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.FileDownloadURL.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.FileDownloadURL} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.FileDownloadURL.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.qai_hub.api.FileDownloadURL.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.FileDownloadURL} returns this
 */
proto.qai_hub.api.FileDownloadURL.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string filename = 2;
 * @return {string}
 */
proto.qai_hub.api.FileDownloadURL.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.FileDownloadURL} returns this
 */
proto.qai_hub.api.FileDownloadURL.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.SharedAccess.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.SharedAccess.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.SharedAccess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.SharedAccess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.SharedAccess.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.SharedAccess}
 */
proto.qai_hub.api.SharedAccess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.SharedAccess;
  return proto.qai_hub.api.SharedAccess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.SharedAccess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.SharedAccess}
 */
proto.qai_hub.api.SharedAccess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.SharedAccess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.SharedAccess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.SharedAccess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.SharedAccess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string email = 1;
 * @return {!Array<string>}
 */
proto.qai_hub.api.SharedAccess.prototype.getEmailList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qai_hub.api.SharedAccess} returns this
 */
proto.qai_hub.api.SharedAccess.prototype.setEmailList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.SharedAccess} returns this
 */
proto.qai_hub.api.SharedAccess.prototype.addEmail = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.SharedAccess} returns this
 */
proto.qai_hub.api.SharedAccess.prototype.clearEmailList = function() {
  return this.setEmailList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.SharedAccessChange.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.SharedAccessChange.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.SharedAccessChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.SharedAccessChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.SharedAccessChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    addEmailList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    removeEmailList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.SharedAccessChange}
 */
proto.qai_hub.api.SharedAccessChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.SharedAccessChange;
  return proto.qai_hub.api.SharedAccessChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.SharedAccessChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.SharedAccessChange}
 */
proto.qai_hub.api.SharedAccessChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addAddEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addRemoveEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.SharedAccessChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.SharedAccessChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.SharedAccessChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.SharedAccessChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddEmailList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getRemoveEmailList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string add_email = 1;
 * @return {!Array<string>}
 */
proto.qai_hub.api.SharedAccessChange.prototype.getAddEmailList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qai_hub.api.SharedAccessChange} returns this
 */
proto.qai_hub.api.SharedAccessChange.prototype.setAddEmailList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.SharedAccessChange} returns this
 */
proto.qai_hub.api.SharedAccessChange.prototype.addAddEmail = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.SharedAccessChange} returns this
 */
proto.qai_hub.api.SharedAccessChange.prototype.clearAddEmailList = function() {
  return this.setAddEmailList([]);
};


/**
 * repeated string remove_email = 2;
 * @return {!Array<string>}
 */
proto.qai_hub.api.SharedAccessChange.prototype.getRemoveEmailList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qai_hub.api.SharedAccessChange} returns this
 */
proto.qai_hub.api.SharedAccessChange.prototype.setRemoveEmailList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.SharedAccessChange} returns this
 */
proto.qai_hub.api.SharedAccessChange.prototype.addRemoveEmail = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.SharedAccessChange} returns this
 */
proto.qai_hub.api.SharedAccessChange.prototype.clearRemoveEmailList = function() {
  return this.setRemoveEmailList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.FileUploadURL.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.FileUploadURL.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.FileUploadURL} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.FileUploadURL.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileFieldName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldsMap: (f = msg.getFieldsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.FileUploadURL}
 */
proto.qai_hub.api.FileUploadURL.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.FileUploadURL;
  return proto.qai_hub.api.FileUploadURL.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.FileUploadURL} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.FileUploadURL}
 */
proto.qai_hub.api.FileUploadURL.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileFieldName(value);
      break;
    case 3:
      var value = msg.getFieldsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.FileUploadURL.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.FileUploadURL.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.FileUploadURL} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.FileUploadURL.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileFieldName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.qai_hub.api.FileUploadURL.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.FileUploadURL} returns this
 */
proto.qai_hub.api.FileUploadURL.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_field_name = 2;
 * @return {string}
 */
proto.qai_hub.api.FileUploadURL.prototype.getFileFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.FileUploadURL} returns this
 */
proto.qai_hub.api.FileUploadURL.prototype.setFileFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * map<string, string> fields = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.qai_hub.api.FileUploadURL.prototype.getFieldsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qai_hub.api.FileUploadURL} returns this
 */
proto.qai_hub.api.FileUploadURL.prototype.clearFieldsMap = function() {
  this.getFieldsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.JobPublicUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.JobPublicUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.JobPublicUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.JobPublicUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.JobPublicUpdate}
 */
proto.qai_hub.api.JobPublicUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.JobPublicUpdate;
  return proto.qai_hub.api.JobPublicUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.JobPublicUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.JobPublicUpdate}
 */
proto.qai_hub.api.JobPublicUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.JobPublicUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.JobPublicUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.JobPublicUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.JobPublicUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.qai_hub.api.JobPublicUpdate.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.JobPublicUpdate} returns this
 */
proto.qai_hub.api.JobPublicUpdate.prototype.setName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.JobPublicUpdate} returns this
 */
proto.qai_hub.api.JobPublicUpdate.prototype.clearName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.JobPublicUpdate.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.ServiceStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.ServiceStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.ServiceStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.ServiceStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.ServiceStatus}
 */
proto.qai_hub.api.ServiceStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.ServiceStatus;
  return proto.qai_hub.api.ServiceStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.ServiceStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.ServiceStatus}
 */
proto.qai_hub.api.ServiceStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.ServiceStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.ServiceStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.ServiceStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.ServiceStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.qai_hub.api.ServiceStatus.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.ServiceStatus} returns this
 */
proto.qai_hub.api.ServiceStatus.prototype.setMessage = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.ServiceStatus} returns this
 */
proto.qai_hub.api.ServiceStatus.prototype.clearMessage = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ServiceStatus.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.User.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    token: jspb.Message.getFieldWithDefault(msg, 5, ""),
    orgId: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.User}
 */
proto.qai_hub.api.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.User;
  return proto.qai_hub.api.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.User}
 */
proto.qai_hub.api.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOrgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrgId();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
};


/**
 * optional uint64 id = 1;
 * @return {number}
 */
proto.qai_hub.api.User.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.User} returns this
 */
proto.qai_hub.api.User.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.qai_hub.api.User.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.User} returns this
 */
proto.qai_hub.api.User.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.qai_hub.api.User.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.User} returns this
 */
proto.qai_hub.api.User.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.qai_hub.api.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.User} returns this
 */
proto.qai_hub.api.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string token = 5;
 * @return {string}
 */
proto.qai_hub.api.User.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.User} returns this
 */
proto.qai_hub.api.User.prototype.setToken = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.User} returns this
 */
proto.qai_hub.api.User.prototype.clearToken = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.User.prototype.hasToken = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint64 org_id = 6;
 * @return {number}
 */
proto.qai_hub.api.User.prototype.getOrgId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.User} returns this
 */
proto.qai_hub.api.User.prototype.setOrgId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.UserList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.UserList.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.UserList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.UserList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.UserList.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.qai_hub.api.User.toObject, includeInstance),
    totalQueryCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.UserList}
 */
proto.qai_hub.api.UserList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.UserList;
  return proto.qai_hub.api.UserList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.UserList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.UserList}
 */
proto.qai_hub.api.UserList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qai_hub.api.User;
      reader.readMessage(value,proto.qai_hub.api.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalQueryCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.UserList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.UserList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.UserList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.UserList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.qai_hub.api.User.serializeBinaryToWriter
    );
  }
  f = message.getTotalQueryCount();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * repeated User users = 1;
 * @return {!Array<!proto.qai_hub.api.User>}
 */
proto.qai_hub.api.UserList.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.qai_hub.api.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qai_hub.api.User, 1));
};


/**
 * @param {!Array<!proto.qai_hub.api.User>} value
 * @return {!proto.qai_hub.api.UserList} returns this
*/
proto.qai_hub.api.UserList.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.qai_hub.api.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.User}
 */
proto.qai_hub.api.UserList.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.qai_hub.api.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.UserList} returns this
 */
proto.qai_hub.api.UserList.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional uint64 total_query_count = 2;
 * @return {number}
 */
proto.qai_hub.api.UserList.prototype.getTotalQueryCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.UserList} returns this
 */
proto.qai_hub.api.UserList.prototype.setTotalQueryCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.UserChangePassword.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.UserChangePassword.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.UserChangePassword} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.UserChangePassword.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldPassword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newPassword: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.UserChangePassword}
 */
proto.qai_hub.api.UserChangePassword.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.UserChangePassword;
  return proto.qai_hub.api.UserChangePassword.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.UserChangePassword} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.UserChangePassword}
 */
proto.qai_hub.api.UserChangePassword.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldPassword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.UserChangePassword.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.UserChangePassword.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.UserChangePassword} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.UserChangePassword.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string old_password = 1;
 * @return {string}
 */
proto.qai_hub.api.UserChangePassword.prototype.getOldPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.UserChangePassword} returns this
 */
proto.qai_hub.api.UserChangePassword.prototype.setOldPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_password = 2;
 * @return {string}
 */
proto.qai_hub.api.UserChangePassword.prototype.getNewPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.UserChangePassword} returns this
 */
proto.qai_hub.api.UserChangePassword.prototype.setNewPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.Organization.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.Organization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.Organization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.Organization.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    members: (f = msg.getMembers()) && proto.qai_hub.api.UserList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.Organization}
 */
proto.qai_hub.api.Organization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.Organization;
  return proto.qai_hub.api.Organization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.Organization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.Organization}
 */
proto.qai_hub.api.Organization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOrgId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.qai_hub.api.UserList;
      reader.readMessage(value,proto.qai_hub.api.UserList.deserializeBinaryFromReader);
      msg.setMembers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.Organization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.Organization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.Organization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.Organization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMembers();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qai_hub.api.UserList.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 org_id = 1;
 * @return {number}
 */
proto.qai_hub.api.Organization.prototype.getOrgId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.Organization} returns this
 */
proto.qai_hub.api.Organization.prototype.setOrgId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.qai_hub.api.Organization.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.Organization} returns this
 */
proto.qai_hub.api.Organization.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional UserList members = 3;
 * @return {?proto.qai_hub.api.UserList}
 */
proto.qai_hub.api.Organization.prototype.getMembers = function() {
  return /** @type{?proto.qai_hub.api.UserList} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.UserList, 3));
};


/**
 * @param {?proto.qai_hub.api.UserList|undefined} value
 * @return {!proto.qai_hub.api.Organization} returns this
*/
proto.qai_hub.api.Organization.prototype.setMembers = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.Organization} returns this
 */
proto.qai_hub.api.Organization.prototype.clearMembers = function() {
  return this.setMembers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Organization.prototype.hasMembers = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.Device.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.Device.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.Device.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.Device} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.Device.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    os: jspb.Message.getFieldWithDefault(msg, 2, ""),
    attributesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    socDescription: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.Device}
 */
proto.qai_hub.api.Device.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.Device;
  return proto.qai_hub.api.Device.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.Device} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.Device}
 */
proto.qai_hub.api.Device.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOs(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAttributes(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSocDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.Device.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.Device.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.Device} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.Device.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOs();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getSocDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.qai_hub.api.Device.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.Device} returns this
 */
proto.qai_hub.api.Device.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string os = 2;
 * @return {string}
 */
proto.qai_hub.api.Device.prototype.getOs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.Device} returns this
 */
proto.qai_hub.api.Device.prototype.setOs = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string attributes = 3;
 * @return {!Array<string>}
 */
proto.qai_hub.api.Device.prototype.getAttributesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qai_hub.api.Device} returns this
 */
proto.qai_hub.api.Device.prototype.setAttributesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.Device} returns this
 */
proto.qai_hub.api.Device.prototype.addAttributes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.Device} returns this
 */
proto.qai_hub.api.Device.prototype.clearAttributesList = function() {
  return this.setAttributesList([]);
};


/**
 * optional string soc_description = 4;
 * @return {string}
 */
proto.qai_hub.api.Device.prototype.getSocDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.Device} returns this
 */
proto.qai_hub.api.Device.prototype.setSocDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.DeviceList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.DeviceList.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.DeviceList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.DeviceList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.DeviceList.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.qai_hub.api.Device.toObject, includeInstance),
    totalQueryCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.DeviceList}
 */
proto.qai_hub.api.DeviceList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.DeviceList;
  return proto.qai_hub.api.DeviceList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.DeviceList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.DeviceList}
 */
proto.qai_hub.api.DeviceList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qai_hub.api.Device;
      reader.readMessage(value,proto.qai_hub.api.Device.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalQueryCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.DeviceList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.DeviceList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.DeviceList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.DeviceList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.qai_hub.api.Device.serializeBinaryToWriter
    );
  }
  f = message.getTotalQueryCount();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * repeated Device devices = 1;
 * @return {!Array<!proto.qai_hub.api.Device>}
 */
proto.qai_hub.api.DeviceList.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.qai_hub.api.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qai_hub.api.Device, 1));
};


/**
 * @param {!Array<!proto.qai_hub.api.Device>} value
 * @return {!proto.qai_hub.api.DeviceList} returns this
*/
proto.qai_hub.api.DeviceList.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.qai_hub.api.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.Device}
 */
proto.qai_hub.api.DeviceList.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.qai_hub.api.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.DeviceList} returns this
 */
proto.qai_hub.api.DeviceList.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};


/**
 * optional uint64 total_query_count = 2;
 * @return {number}
 */
proto.qai_hub.api.DeviceList.prototype.getTotalQueryCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.DeviceList} returns this
 */
proto.qai_hub.api.DeviceList.prototype.setTotalQueryCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.Dataset.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.Dataset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.Dataset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.Dataset.toObject = function(includeInstance, msg) {
  var f, obj = {
    datasetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    owner: (f = msg.getOwner()) && proto.qai_hub.api.User.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expirationTime: (f = msg.getExpirationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fileUploadComplete: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    readOnly: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.Dataset}
 */
proto.qai_hub.api.Dataset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.Dataset;
  return proto.qai_hub.api.Dataset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.Dataset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.Dataset}
 */
proto.qai_hub.api.Dataset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatasetId(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.User;
      reader.readMessage(value,proto.qai_hub.api.User.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationTime(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFileUploadComplete(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.Dataset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.Dataset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.Dataset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.Dataset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.User.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpirationTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string dataset_id = 1;
 * @return {string}
 */
proto.qai_hub.api.Dataset.prototype.getDatasetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.Dataset} returns this
 */
proto.qai_hub.api.Dataset.prototype.setDatasetId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.Dataset} returns this
 */
proto.qai_hub.api.Dataset.prototype.clearDatasetId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Dataset.prototype.hasDatasetId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User owner = 2;
 * @return {?proto.qai_hub.api.User}
 */
proto.qai_hub.api.Dataset.prototype.getOwner = function() {
  return /** @type{?proto.qai_hub.api.User} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.User, 2));
};


/**
 * @param {?proto.qai_hub.api.User|undefined} value
 * @return {!proto.qai_hub.api.Dataset} returns this
*/
proto.qai_hub.api.Dataset.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.Dataset} returns this
 */
proto.qai_hub.api.Dataset.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Dataset.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.qai_hub.api.Dataset.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.Dataset} returns this
 */
proto.qai_hub.api.Dataset.prototype.setName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.Dataset} returns this
 */
proto.qai_hub.api.Dataset.prototype.clearName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Dataset.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp creation_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.Dataset.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.Dataset} returns this
*/
proto.qai_hub.api.Dataset.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.Dataset} returns this
 */
proto.qai_hub.api.Dataset.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Dataset.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp expiration_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.Dataset.prototype.getExpirationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.Dataset} returns this
*/
proto.qai_hub.api.Dataset.prototype.setExpirationTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.Dataset} returns this
 */
proto.qai_hub.api.Dataset.prototype.clearExpirationTime = function() {
  return this.setExpirationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Dataset.prototype.hasExpirationTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool file_upload_complete = 6;
 * @return {boolean}
 */
proto.qai_hub.api.Dataset.prototype.getFileUploadComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qai_hub.api.Dataset} returns this
 */
proto.qai_hub.api.Dataset.prototype.setFileUploadComplete = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.Dataset} returns this
 */
proto.qai_hub.api.Dataset.prototype.clearFileUploadComplete = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Dataset.prototype.hasFileUploadComplete = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool read_only = 7;
 * @return {boolean}
 */
proto.qai_hub.api.Dataset.prototype.getReadOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qai_hub.api.Dataset} returns this
 */
proto.qai_hub.api.Dataset.prototype.setReadOnly = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.Dataset} returns this
 */
proto.qai_hub.api.Dataset.prototype.clearReadOnly = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Dataset.prototype.hasReadOnly = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.DatasetList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.DatasetList.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.DatasetList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.DatasetList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.DatasetList.toObject = function(includeInstance, msg) {
  var f, obj = {
    datasetsList: jspb.Message.toObjectList(msg.getDatasetsList(),
    proto.qai_hub.api.Dataset.toObject, includeInstance),
    totalQueryCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.DatasetList}
 */
proto.qai_hub.api.DatasetList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.DatasetList;
  return proto.qai_hub.api.DatasetList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.DatasetList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.DatasetList}
 */
proto.qai_hub.api.DatasetList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qai_hub.api.Dataset;
      reader.readMessage(value,proto.qai_hub.api.Dataset.deserializeBinaryFromReader);
      msg.addDatasets(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalQueryCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.DatasetList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.DatasetList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.DatasetList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.DatasetList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatasetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.qai_hub.api.Dataset.serializeBinaryToWriter
    );
  }
  f = message.getTotalQueryCount();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * repeated Dataset datasets = 1;
 * @return {!Array<!proto.qai_hub.api.Dataset>}
 */
proto.qai_hub.api.DatasetList.prototype.getDatasetsList = function() {
  return /** @type{!Array<!proto.qai_hub.api.Dataset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qai_hub.api.Dataset, 1));
};


/**
 * @param {!Array<!proto.qai_hub.api.Dataset>} value
 * @return {!proto.qai_hub.api.DatasetList} returns this
*/
proto.qai_hub.api.DatasetList.prototype.setDatasetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.qai_hub.api.Dataset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.Dataset}
 */
proto.qai_hub.api.DatasetList.prototype.addDatasets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.qai_hub.api.Dataset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.DatasetList} returns this
 */
proto.qai_hub.api.DatasetList.prototype.clearDatasetsList = function() {
  return this.setDatasetsList([]);
};


/**
 * optional uint64 total_query_count = 2;
 * @return {number}
 */
proto.qai_hub.api.DatasetList.prototype.getTotalQueryCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.DatasetList} returns this
 */
proto.qai_hub.api.DatasetList.prototype.setTotalQueryCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.TensorType.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.TensorType.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.TensorType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.TensorType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.TensorType.toObject = function(includeInstance, msg) {
  var f, obj = {
    shapeList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    dtype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.TensorType}
 */
proto.qai_hub.api.TensorType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.TensorType;
  return proto.qai_hub.api.TensorType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.TensorType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.TensorType}
 */
proto.qai_hub.api.TensorType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addShape(values[i]);
      }
      break;
    case 2:
      var value = /** @type {!proto.qai_hub.api.TensorDtype} */ (reader.readEnum());
      msg.setDtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.TensorType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.TensorType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.TensorType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.TensorType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShapeList();
  if (f.length > 0) {
    writer.writePackedUint64(
      1,
      f
    );
  }
  f = message.getDtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated uint64 shape = 1;
 * @return {!Array<number>}
 */
proto.qai_hub.api.TensorType.prototype.getShapeList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.qai_hub.api.TensorType} returns this
 */
proto.qai_hub.api.TensorType.prototype.setShapeList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.TensorType} returns this
 */
proto.qai_hub.api.TensorType.prototype.addShape = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.TensorType} returns this
 */
proto.qai_hub.api.TensorType.prototype.clearShapeList = function() {
  return this.setShapeList([]);
};


/**
 * optional TensorDtype dtype = 2;
 * @return {!proto.qai_hub.api.TensorDtype}
 */
proto.qai_hub.api.TensorType.prototype.getDtype = function() {
  return /** @type {!proto.qai_hub.api.TensorDtype} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.qai_hub.api.TensorDtype} value
 * @return {!proto.qai_hub.api.TensorType} returns this
 */
proto.qai_hub.api.TensorType.prototype.setDtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.NamedTensorType.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.NamedTensorType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.NamedTensorType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.NamedTensorType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tensorType: (f = msg.getTensorType()) && proto.qai_hub.api.TensorType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.NamedTensorType}
 */
proto.qai_hub.api.NamedTensorType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.NamedTensorType;
  return proto.qai_hub.api.NamedTensorType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.NamedTensorType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.NamedTensorType}
 */
proto.qai_hub.api.NamedTensorType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.TensorType;
      reader.readMessage(value,proto.qai_hub.api.TensorType.deserializeBinaryFromReader);
      msg.setTensorType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.NamedTensorType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.NamedTensorType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.NamedTensorType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.NamedTensorType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTensorType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.TensorType.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.qai_hub.api.NamedTensorType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.NamedTensorType} returns this
 */
proto.qai_hub.api.NamedTensorType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TensorType tensor_type = 2;
 * @return {?proto.qai_hub.api.TensorType}
 */
proto.qai_hub.api.NamedTensorType.prototype.getTensorType = function() {
  return /** @type{?proto.qai_hub.api.TensorType} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.TensorType, 2));
};


/**
 * @param {?proto.qai_hub.api.TensorType|undefined} value
 * @return {!proto.qai_hub.api.NamedTensorType} returns this
*/
proto.qai_hub.api.NamedTensorType.prototype.setTensorType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.NamedTensorType} returns this
 */
proto.qai_hub.api.NamedTensorType.prototype.clearTensorType = function() {
  return this.setTensorType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.NamedTensorType.prototype.hasTensorType = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.NamedTensorTypeList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.NamedTensorTypeList.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.NamedTensorTypeList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.NamedTensorTypeList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.NamedTensorTypeList.toObject = function(includeInstance, msg) {
  var f, obj = {
    typesList: jspb.Message.toObjectList(msg.getTypesList(),
    proto.qai_hub.api.NamedTensorType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.NamedTensorTypeList}
 */
proto.qai_hub.api.NamedTensorTypeList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.NamedTensorTypeList;
  return proto.qai_hub.api.NamedTensorTypeList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.NamedTensorTypeList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.NamedTensorTypeList}
 */
proto.qai_hub.api.NamedTensorTypeList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qai_hub.api.NamedTensorType;
      reader.readMessage(value,proto.qai_hub.api.NamedTensorType.deserializeBinaryFromReader);
      msg.addTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.NamedTensorTypeList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.NamedTensorTypeList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.NamedTensorTypeList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.NamedTensorTypeList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.qai_hub.api.NamedTensorType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NamedTensorType types = 1;
 * @return {!Array<!proto.qai_hub.api.NamedTensorType>}
 */
proto.qai_hub.api.NamedTensorTypeList.prototype.getTypesList = function() {
  return /** @type{!Array<!proto.qai_hub.api.NamedTensorType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qai_hub.api.NamedTensorType, 1));
};


/**
 * @param {!Array<!proto.qai_hub.api.NamedTensorType>} value
 * @return {!proto.qai_hub.api.NamedTensorTypeList} returns this
*/
proto.qai_hub.api.NamedTensorTypeList.prototype.setTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.qai_hub.api.NamedTensorType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.NamedTensorType}
 */
proto.qai_hub.api.NamedTensorTypeList.prototype.addTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.qai_hub.api.NamedTensorType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.NamedTensorTypeList} returns this
 */
proto.qai_hub.api.NamedTensorTypeList.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.ModelMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.ModelMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.ModelMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.ModelMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    entriesMap: (f = msg.getEntriesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.ModelMetadata}
 */
proto.qai_hub.api.ModelMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.ModelMetadata;
  return proto.qai_hub.api.ModelMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.ModelMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.ModelMetadata}
 */
proto.qai_hub.api.ModelMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getEntriesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readString, null, 0, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.ModelMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.ModelMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.ModelMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.ModelMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<uint32, string> entries = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,string>}
 */
proto.qai_hub.api.ModelMetadata.prototype.getEntriesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qai_hub.api.ModelMetadata} returns this
 */
proto.qai_hub.api.ModelMetadata.prototype.clearEntriesMap = function() {
  this.getEntriesMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.Model.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.Model.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.Model} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.Model.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    owner: (f = msg.getOwner()) && proto.qai_hub.api.User.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modelType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    fileUploadComplete: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    producerId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    readOnly: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isDirectory: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    hidden: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    producerAttemptNumber: jspb.Message.getFieldWithDefault(msg, 11, 0),
    metadata: (f = msg.getMetadata()) && proto.qai_hub.api.ModelMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.Model}
 */
proto.qai_hub.api.Model.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.Model;
  return proto.qai_hub.api.Model.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.Model} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.Model}
 */
proto.qai_hub.api.Model.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.User;
      reader.readMessage(value,proto.qai_hub.api.User.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 5:
      var value = /** @type {!proto.qai_hub.api.ModelType} */ (reader.readEnum());
      msg.setModelType(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFileUploadComplete(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducerId(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadOnly(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDirectory(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHidden(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerAttemptNumber(value);
      break;
    case 12:
      var value = new proto.qai_hub.api.ModelMetadata;
      reader.readMessage(value,proto.qai_hub.api.ModelMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.Model.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.Model.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.Model} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.Model.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.User.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.qai_hub.api.ModelType} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeBool(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.qai_hub.api.ModelMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string model_id = 1;
 * @return {string}
 */
proto.qai_hub.api.Model.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.setModelId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.clearModelId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Model.prototype.hasModelId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User owner = 2;
 * @return {?proto.qai_hub.api.User}
 */
proto.qai_hub.api.Model.prototype.getOwner = function() {
  return /** @type{?proto.qai_hub.api.User} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.User, 2));
};


/**
 * @param {?proto.qai_hub.api.User|undefined} value
 * @return {!proto.qai_hub.api.Model} returns this
*/
proto.qai_hub.api.Model.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Model.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.qai_hub.api.Model.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.setName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.clearName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Model.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp creation_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.Model.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.Model} returns this
*/
proto.qai_hub.api.Model.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Model.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ModelType model_type = 5;
 * @return {!proto.qai_hub.api.ModelType}
 */
proto.qai_hub.api.Model.prototype.getModelType = function() {
  return /** @type {!proto.qai_hub.api.ModelType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.qai_hub.api.ModelType} value
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.setModelType = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.clearModelType = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Model.prototype.hasModelType = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool file_upload_complete = 6;
 * @return {boolean}
 */
proto.qai_hub.api.Model.prototype.getFileUploadComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.setFileUploadComplete = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.clearFileUploadComplete = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Model.prototype.hasFileUploadComplete = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string producer_id = 7;
 * @return {string}
 */
proto.qai_hub.api.Model.prototype.getProducerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.setProducerId = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.clearProducerId = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Model.prototype.hasProducerId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool read_only = 8;
 * @return {boolean}
 */
proto.qai_hub.api.Model.prototype.getReadOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.setReadOnly = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.clearReadOnly = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Model.prototype.hasReadOnly = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool is_directory = 9;
 * @return {boolean}
 */
proto.qai_hub.api.Model.prototype.getIsDirectory = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.setIsDirectory = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.clearIsDirectory = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Model.prototype.hasIsDirectory = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool hidden = 10;
 * @return {boolean}
 */
proto.qai_hub.api.Model.prototype.getHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.setHidden = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.clearHidden = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Model.prototype.hasHidden = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional uint32 producer_attempt_number = 11;
 * @return {number}
 */
proto.qai_hub.api.Model.prototype.getProducerAttemptNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.setProducerAttemptNumber = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.clearProducerAttemptNumber = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Model.prototype.hasProducerAttemptNumber = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ModelMetadata metadata = 12;
 * @return {?proto.qai_hub.api.ModelMetadata}
 */
proto.qai_hub.api.Model.prototype.getMetadata = function() {
  return /** @type{?proto.qai_hub.api.ModelMetadata} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.ModelMetadata, 12));
};


/**
 * @param {?proto.qai_hub.api.ModelMetadata|undefined} value
 * @return {!proto.qai_hub.api.Model} returns this
*/
proto.qai_hub.api.Model.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.Model} returns this
 */
proto.qai_hub.api.Model.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Model.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.ModelList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.ModelList.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.ModelList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.ModelList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.ModelList.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelsList: jspb.Message.toObjectList(msg.getModelsList(),
    proto.qai_hub.api.Model.toObject, includeInstance),
    totalQueryCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.ModelList}
 */
proto.qai_hub.api.ModelList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.ModelList;
  return proto.qai_hub.api.ModelList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.ModelList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.ModelList}
 */
proto.qai_hub.api.ModelList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qai_hub.api.Model;
      reader.readMessage(value,proto.qai_hub.api.Model.deserializeBinaryFromReader);
      msg.addModels(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalQueryCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.ModelList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.ModelList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.ModelList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.ModelList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.qai_hub.api.Model.serializeBinaryToWriter
    );
  }
  f = message.getTotalQueryCount();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * repeated Model models = 1;
 * @return {!Array<!proto.qai_hub.api.Model>}
 */
proto.qai_hub.api.ModelList.prototype.getModelsList = function() {
  return /** @type{!Array<!proto.qai_hub.api.Model>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qai_hub.api.Model, 1));
};


/**
 * @param {!Array<!proto.qai_hub.api.Model>} value
 * @return {!proto.qai_hub.api.ModelList} returns this
*/
proto.qai_hub.api.ModelList.prototype.setModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.qai_hub.api.Model=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.Model}
 */
proto.qai_hub.api.ModelList.prototype.addModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.qai_hub.api.Model, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.ModelList} returns this
 */
proto.qai_hub.api.ModelList.prototype.clearModelsList = function() {
  return this.setModelsList([]);
};


/**
 * optional uint64 total_query_count = 2;
 * @return {number}
 */
proto.qai_hub.api.ModelList.prototype.getTotalQueryCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ModelList} returns this
 */
proto.qai_hub.api.ModelList.prototype.setTotalQueryCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.Notice.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.Notice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.Notice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.Notice.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: jspb.Message.getFieldWithDefault(msg, 1, 0),
    severity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.Notice}
 */
proto.qai_hub.api.Notice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.Notice;
  return proto.qai_hub.api.Notice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.Notice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.Notice}
 */
proto.qai_hub.api.Notice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.qai_hub.api.Notice.Context} */ (reader.readEnum());
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {!proto.qai_hub.api.Notice.Severity} */ (reader.readEnum());
      msg.setSeverity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.Notice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.Notice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.Notice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.Notice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSeverity();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.qai_hub.api.Notice.Severity = {
  DEBUG: 0,
  INFO: 1,
  WARNING: 2,
  ERROR: 3
};

/**
 * @enum {number}
 */
proto.qai_hub.api.Notice.Context = {
  DEVICE: 0
};

/**
 * optional Context context = 1;
 * @return {!proto.qai_hub.api.Notice.Context}
 */
proto.qai_hub.api.Notice.prototype.getContext = function() {
  return /** @type {!proto.qai_hub.api.Notice.Context} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.qai_hub.api.Notice.Context} value
 * @return {!proto.qai_hub.api.Notice} returns this
 */
proto.qai_hub.api.Notice.prototype.setContext = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Severity severity = 2;
 * @return {!proto.qai_hub.api.Notice.Severity}
 */
proto.qai_hub.api.Notice.prototype.getSeverity = function() {
  return /** @type {!proto.qai_hub.api.Notice.Severity} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.qai_hub.api.Notice.Severity} value
 * @return {!proto.qai_hub.api.Notice} returns this
 */
proto.qai_hub.api.Notice.prototype.setSeverity = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.qai_hub.api.Notice.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.Notice} returns this
 */
proto.qai_hub.api.Notice.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.CompileJob.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.CompileJob.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.CompileJob} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.CompileJob.toObject = function(includeInstance, msg) {
  var f, obj = {
    compileJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && proto.qai_hub.api.User.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    model: (f = msg.getModel()) && proto.qai_hub.api.Model.toObject(includeInstance, f),
    jobState: jspb.Message.getFieldWithDefault(msg, 5, 0),
    device: (f = msg.getDevice()) && proto.qai_hub.api.Device.toObject(includeInstance, f),
    devices: (f = msg.getDevices()) && proto.qai_hub.api.DeviceList.toObject(includeInstance, f),
    tensorTypeList: (f = msg.getTensorTypeList()) && proto.qai_hub.api.NamedTensorTypeList.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 9, ""),
    options: jspb.Message.getFieldWithDefault(msg, 10, ""),
    failureReason: jspb.Message.getFieldWithDefault(msg, 11, ""),
    targetModel: (f = msg.getTargetModel()) && proto.qai_hub.api.Model.toObject(includeInstance, f),
    hasVizgraph: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    completionTime: (f = msg.getCompletionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastUpdatedTime: (f = msg.getLastUpdatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    adminUrl: jspb.Message.getFieldWithDefault(msg, 18, ""),
    targetTensorTypeList: (f = msg.getTargetTensorTypeList()) && proto.qai_hub.api.NamedTensorTypeList.toObject(includeInstance, f),
    calibrationDataset: (f = msg.getCalibrationDataset()) && proto.qai_hub.api.Dataset.toObject(includeInstance, f),
    queuedTime: (f = msg.getQueuedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.CompileJob}
 */
proto.qai_hub.api.CompileJob.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.CompileJob;
  return proto.qai_hub.api.CompileJob.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.CompileJob} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.CompileJob}
 */
proto.qai_hub.api.CompileJob.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompileJobId(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.User;
      reader.readMessage(value,proto.qai_hub.api.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserId(value);
      break;
    case 4:
      var value = new proto.qai_hub.api.Model;
      reader.readMessage(value,proto.qai_hub.api.Model.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    case 5:
      var value = /** @type {!proto.qai_hub.api.JobState} */ (reader.readEnum());
      msg.setJobState(value);
      break;
    case 6:
      var value = new proto.qai_hub.api.Device;
      reader.readMessage(value,proto.qai_hub.api.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 7:
      var value = new proto.qai_hub.api.DeviceList;
      reader.readMessage(value,proto.qai_hub.api.DeviceList.deserializeBinaryFromReader);
      msg.setDevices(value);
      break;
    case 8:
      var value = new proto.qai_hub.api.NamedTensorTypeList;
      reader.readMessage(value,proto.qai_hub.api.NamedTensorTypeList.deserializeBinaryFromReader);
      msg.setTensorTypeList(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptions(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFailureReason(value);
      break;
    case 12:
      var value = new proto.qai_hub.api.Model;
      reader.readMessage(value,proto.qai_hub.api.Model.deserializeBinaryFromReader);
      msg.setTargetModel(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasVizgraph(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletionTime(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdatedTime(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminUrl(value);
      break;
    case 19:
      var value = new proto.qai_hub.api.NamedTensorTypeList;
      reader.readMessage(value,proto.qai_hub.api.NamedTensorTypeList.deserializeBinaryFromReader);
      msg.setTargetTensorTypeList(value);
      break;
    case 20:
      var value = new proto.qai_hub.api.Dataset;
      reader.readMessage(value,proto.qai_hub.api.Dataset.deserializeBinaryFromReader);
      msg.setCalibrationDataset(value);
      break;
    case 21:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setQueuedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.CompileJob.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.CompileJob.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.CompileJob} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.CompileJob.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompileJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.User.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.qai_hub.api.Model.serializeBinaryToWriter
    );
  }
  f = message.getJobState();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.qai_hub.api.Device.serializeBinaryToWriter
    );
  }
  f = message.getDevices();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.qai_hub.api.DeviceList.serializeBinaryToWriter
    );
  }
  f = message.getTensorTypeList();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.qai_hub.api.NamedTensorTypeList.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOptions();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTargetModel();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.qai_hub.api.Model.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCompletionTime();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastUpdatedTime();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getTargetTensorTypeList();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.qai_hub.api.NamedTensorTypeList.serializeBinaryToWriter
    );
  }
  f = message.getCalibrationDataset();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.qai_hub.api.Dataset.serializeBinaryToWriter
    );
  }
  f = message.getQueuedTime();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string compile_job_id = 1;
 * @return {string}
 */
proto.qai_hub.api.CompileJob.prototype.getCompileJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.setCompileJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 2;
 * @return {?proto.qai_hub.api.User}
 */
proto.qai_hub.api.CompileJob.prototype.getUser = function() {
  return /** @type{?proto.qai_hub.api.User} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.User, 2));
};


/**
 * @param {?proto.qai_hub.api.User|undefined} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
*/
proto.qai_hub.api.CompileJob.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJob.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 user_id = 3;
 * @return {number}
 */
proto.qai_hub.api.CompileJob.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Model model = 4;
 * @return {?proto.qai_hub.api.Model}
 */
proto.qai_hub.api.CompileJob.prototype.getModel = function() {
  return /** @type{?proto.qai_hub.api.Model} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Model, 4));
};


/**
 * @param {?proto.qai_hub.api.Model|undefined} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
*/
proto.qai_hub.api.CompileJob.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJob.prototype.hasModel = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional JobState job_state = 5;
 * @return {!proto.qai_hub.api.JobState}
 */
proto.qai_hub.api.CompileJob.prototype.getJobState = function() {
  return /** @type {!proto.qai_hub.api.JobState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.qai_hub.api.JobState} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.setJobState = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional Device device = 6;
 * @return {?proto.qai_hub.api.Device}
 */
proto.qai_hub.api.CompileJob.prototype.getDevice = function() {
  return /** @type{?proto.qai_hub.api.Device} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Device, 6));
};


/**
 * @param {?proto.qai_hub.api.Device|undefined} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
*/
proto.qai_hub.api.CompileJob.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJob.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DeviceList devices = 7;
 * @return {?proto.qai_hub.api.DeviceList}
 */
proto.qai_hub.api.CompileJob.prototype.getDevices = function() {
  return /** @type{?proto.qai_hub.api.DeviceList} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.DeviceList, 7));
};


/**
 * @param {?proto.qai_hub.api.DeviceList|undefined} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
*/
proto.qai_hub.api.CompileJob.prototype.setDevices = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.clearDevices = function() {
  return this.setDevices(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJob.prototype.hasDevices = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NamedTensorTypeList tensor_type_list = 8;
 * @return {?proto.qai_hub.api.NamedTensorTypeList}
 */
proto.qai_hub.api.CompileJob.prototype.getTensorTypeList = function() {
  return /** @type{?proto.qai_hub.api.NamedTensorTypeList} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.NamedTensorTypeList, 8));
};


/**
 * @param {?proto.qai_hub.api.NamedTensorTypeList|undefined} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
*/
proto.qai_hub.api.CompileJob.prototype.setTensorTypeList = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.clearTensorTypeList = function() {
  return this.setTensorTypeList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJob.prototype.hasTensorTypeList = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string name = 9;
 * @return {string}
 */
proto.qai_hub.api.CompileJob.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string options = 10;
 * @return {string}
 */
proto.qai_hub.api.CompileJob.prototype.getOptions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.setOptions = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string failure_reason = 11;
 * @return {string}
 */
proto.qai_hub.api.CompileJob.prototype.getFailureReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.setFailureReason = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.clearFailureReason = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJob.prototype.hasFailureReason = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional Model target_model = 12;
 * @return {?proto.qai_hub.api.Model}
 */
proto.qai_hub.api.CompileJob.prototype.getTargetModel = function() {
  return /** @type{?proto.qai_hub.api.Model} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Model, 12));
};


/**
 * @param {?proto.qai_hub.api.Model|undefined} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
*/
proto.qai_hub.api.CompileJob.prototype.setTargetModel = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.clearTargetModel = function() {
  return this.setTargetModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJob.prototype.hasTargetModel = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool has_vizgraph = 13;
 * @return {boolean}
 */
proto.qai_hub.api.CompileJob.prototype.getHasVizgraph = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.setHasVizgraph = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.clearHasVizgraph = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJob.prototype.hasHasVizgraph = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Timestamp creation_time = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.CompileJob.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
*/
proto.qai_hub.api.CompileJob.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJob.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp start_time = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.CompileJob.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
*/
proto.qai_hub.api.CompileJob.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJob.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp completion_time = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.CompileJob.prototype.getCompletionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
*/
proto.qai_hub.api.CompileJob.prototype.setCompletionTime = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.clearCompletionTime = function() {
  return this.setCompletionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJob.prototype.hasCompletionTime = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Timestamp last_updated_time = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.CompileJob.prototype.getLastUpdatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
*/
proto.qai_hub.api.CompileJob.prototype.setLastUpdatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.clearLastUpdatedTime = function() {
  return this.setLastUpdatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJob.prototype.hasLastUpdatedTime = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string admin_url = 18;
 * @return {string}
 */
proto.qai_hub.api.CompileJob.prototype.getAdminUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.setAdminUrl = function(value) {
  return jspb.Message.setField(this, 18, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.clearAdminUrl = function() {
  return jspb.Message.setField(this, 18, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJob.prototype.hasAdminUrl = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional NamedTensorTypeList target_tensor_type_list = 19;
 * @return {?proto.qai_hub.api.NamedTensorTypeList}
 */
proto.qai_hub.api.CompileJob.prototype.getTargetTensorTypeList = function() {
  return /** @type{?proto.qai_hub.api.NamedTensorTypeList} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.NamedTensorTypeList, 19));
};


/**
 * @param {?proto.qai_hub.api.NamedTensorTypeList|undefined} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
*/
proto.qai_hub.api.CompileJob.prototype.setTargetTensorTypeList = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.clearTargetTensorTypeList = function() {
  return this.setTargetTensorTypeList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJob.prototype.hasTargetTensorTypeList = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional Dataset calibration_dataset = 20;
 * @return {?proto.qai_hub.api.Dataset}
 */
proto.qai_hub.api.CompileJob.prototype.getCalibrationDataset = function() {
  return /** @type{?proto.qai_hub.api.Dataset} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Dataset, 20));
};


/**
 * @param {?proto.qai_hub.api.Dataset|undefined} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
*/
proto.qai_hub.api.CompileJob.prototype.setCalibrationDataset = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.clearCalibrationDataset = function() {
  return this.setCalibrationDataset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJob.prototype.hasCalibrationDataset = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.protobuf.Timestamp queued_time = 21;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.CompileJob.prototype.getQueuedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 21));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.CompileJob} returns this
*/
proto.qai_hub.api.CompileJob.prototype.setQueuedTime = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJob} returns this
 */
proto.qai_hub.api.CompileJob.prototype.clearQueuedTime = function() {
  return this.setQueuedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJob.prototype.hasQueuedTime = function() {
  return jspb.Message.getField(this, 21) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.LinkJob.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.LinkJob.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.LinkJob} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.LinkJob.toObject = function(includeInstance, msg) {
  var f, obj = {
    linkJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && proto.qai_hub.api.User.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    models: (f = msg.getModels()) && proto.qai_hub.api.ModelList.toObject(includeInstance, f),
    jobState: jspb.Message.getFieldWithDefault(msg, 5, 0),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    options: jspb.Message.getFieldWithDefault(msg, 7, ""),
    failureReason: jspb.Message.getFieldWithDefault(msg, 8, ""),
    targetModel: (f = msg.getTargetModel()) && proto.qai_hub.api.Model.toObject(includeInstance, f),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    completionTime: (f = msg.getCompletionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastUpdatedTime: (f = msg.getLastUpdatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    adminUrl: jspb.Message.getFieldWithDefault(msg, 14, ""),
    queuedTime: (f = msg.getQueuedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.LinkJob}
 */
proto.qai_hub.api.LinkJob.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.LinkJob;
  return proto.qai_hub.api.LinkJob.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.LinkJob} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.LinkJob}
 */
proto.qai_hub.api.LinkJob.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkJobId(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.User;
      reader.readMessage(value,proto.qai_hub.api.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserId(value);
      break;
    case 4:
      var value = new proto.qai_hub.api.ModelList;
      reader.readMessage(value,proto.qai_hub.api.ModelList.deserializeBinaryFromReader);
      msg.setModels(value);
      break;
    case 5:
      var value = /** @type {!proto.qai_hub.api.JobState} */ (reader.readEnum());
      msg.setJobState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptions(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFailureReason(value);
      break;
    case 9:
      var value = new proto.qai_hub.api.Model;
      reader.readMessage(value,proto.qai_hub.api.Model.deserializeBinaryFromReader);
      msg.setTargetModel(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletionTime(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdatedTime(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminUrl(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setQueuedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.LinkJob.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.LinkJob.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.LinkJob} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.LinkJob.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinkJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.User.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getModels();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.qai_hub.api.ModelList.serializeBinaryToWriter
    );
  }
  f = message.getJobState();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOptions();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTargetModel();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.qai_hub.api.Model.serializeBinaryToWriter
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCompletionTime();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastUpdatedTime();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getQueuedTime();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string link_job_id = 1;
 * @return {string}
 */
proto.qai_hub.api.LinkJob.prototype.getLinkJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.LinkJob} returns this
 */
proto.qai_hub.api.LinkJob.prototype.setLinkJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 2;
 * @return {?proto.qai_hub.api.User}
 */
proto.qai_hub.api.LinkJob.prototype.getUser = function() {
  return /** @type{?proto.qai_hub.api.User} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.User, 2));
};


/**
 * @param {?proto.qai_hub.api.User|undefined} value
 * @return {!proto.qai_hub.api.LinkJob} returns this
*/
proto.qai_hub.api.LinkJob.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.LinkJob} returns this
 */
proto.qai_hub.api.LinkJob.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LinkJob.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 user_id = 3;
 * @return {number}
 */
proto.qai_hub.api.LinkJob.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.LinkJob} returns this
 */
proto.qai_hub.api.LinkJob.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional ModelList models = 4;
 * @return {?proto.qai_hub.api.ModelList}
 */
proto.qai_hub.api.LinkJob.prototype.getModels = function() {
  return /** @type{?proto.qai_hub.api.ModelList} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.ModelList, 4));
};


/**
 * @param {?proto.qai_hub.api.ModelList|undefined} value
 * @return {!proto.qai_hub.api.LinkJob} returns this
*/
proto.qai_hub.api.LinkJob.prototype.setModels = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.LinkJob} returns this
 */
proto.qai_hub.api.LinkJob.prototype.clearModels = function() {
  return this.setModels(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LinkJob.prototype.hasModels = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional JobState job_state = 5;
 * @return {!proto.qai_hub.api.JobState}
 */
proto.qai_hub.api.LinkJob.prototype.getJobState = function() {
  return /** @type {!proto.qai_hub.api.JobState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.qai_hub.api.JobState} value
 * @return {!proto.qai_hub.api.LinkJob} returns this
 */
proto.qai_hub.api.LinkJob.prototype.setJobState = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.qai_hub.api.LinkJob.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.LinkJob} returns this
 */
proto.qai_hub.api.LinkJob.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string options = 7;
 * @return {string}
 */
proto.qai_hub.api.LinkJob.prototype.getOptions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.LinkJob} returns this
 */
proto.qai_hub.api.LinkJob.prototype.setOptions = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string failure_reason = 8;
 * @return {string}
 */
proto.qai_hub.api.LinkJob.prototype.getFailureReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.LinkJob} returns this
 */
proto.qai_hub.api.LinkJob.prototype.setFailureReason = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.LinkJob} returns this
 */
proto.qai_hub.api.LinkJob.prototype.clearFailureReason = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LinkJob.prototype.hasFailureReason = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Model target_model = 9;
 * @return {?proto.qai_hub.api.Model}
 */
proto.qai_hub.api.LinkJob.prototype.getTargetModel = function() {
  return /** @type{?proto.qai_hub.api.Model} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Model, 9));
};


/**
 * @param {?proto.qai_hub.api.Model|undefined} value
 * @return {!proto.qai_hub.api.LinkJob} returns this
*/
proto.qai_hub.api.LinkJob.prototype.setTargetModel = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.LinkJob} returns this
 */
proto.qai_hub.api.LinkJob.prototype.clearTargetModel = function() {
  return this.setTargetModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LinkJob.prototype.hasTargetModel = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp creation_time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.LinkJob.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.LinkJob} returns this
*/
proto.qai_hub.api.LinkJob.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.LinkJob} returns this
 */
proto.qai_hub.api.LinkJob.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LinkJob.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp start_time = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.LinkJob.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.LinkJob} returns this
*/
proto.qai_hub.api.LinkJob.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.LinkJob} returns this
 */
proto.qai_hub.api.LinkJob.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LinkJob.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp completion_time = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.LinkJob.prototype.getCompletionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.LinkJob} returns this
*/
proto.qai_hub.api.LinkJob.prototype.setCompletionTime = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.LinkJob} returns this
 */
proto.qai_hub.api.LinkJob.prototype.clearCompletionTime = function() {
  return this.setCompletionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LinkJob.prototype.hasCompletionTime = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp last_updated_time = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.LinkJob.prototype.getLastUpdatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.LinkJob} returns this
*/
proto.qai_hub.api.LinkJob.prototype.setLastUpdatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.LinkJob} returns this
 */
proto.qai_hub.api.LinkJob.prototype.clearLastUpdatedTime = function() {
  return this.setLastUpdatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LinkJob.prototype.hasLastUpdatedTime = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string admin_url = 14;
 * @return {string}
 */
proto.qai_hub.api.LinkJob.prototype.getAdminUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.LinkJob} returns this
 */
proto.qai_hub.api.LinkJob.prototype.setAdminUrl = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.LinkJob} returns this
 */
proto.qai_hub.api.LinkJob.prototype.clearAdminUrl = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LinkJob.prototype.hasAdminUrl = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp queued_time = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.LinkJob.prototype.getQueuedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.LinkJob} returns this
*/
proto.qai_hub.api.LinkJob.prototype.setQueuedTime = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.LinkJob} returns this
 */
proto.qai_hub.api.LinkJob.prototype.clearQueuedTime = function() {
  return this.setQueuedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LinkJob.prototype.hasQueuedTime = function() {
  return jspb.Message.getField(this, 15) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.QuantizeJob.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.QuantizeJob.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.QuantizeJob} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.QuantizeJob.toObject = function(includeInstance, msg) {
  var f, obj = {
    quantizeJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && proto.qai_hub.api.User.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    model: (f = msg.getModel()) && proto.qai_hub.api.Model.toObject(includeInstance, f),
    calibrationDataset: (f = msg.getCalibrationDataset()) && proto.qai_hub.api.Dataset.toObject(includeInstance, f),
    weightsDtype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    activationsDtype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    name: jspb.Message.getFieldWithDefault(msg, 8, ""),
    jobState: jspb.Message.getFieldWithDefault(msg, 9, 0),
    options: jspb.Message.getFieldWithDefault(msg, 10, ""),
    tensorTypeList: (f = msg.getTensorTypeList()) && proto.qai_hub.api.NamedTensorTypeList.toObject(includeInstance, f),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    completionTime: (f = msg.getCompletionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastUpdatedTime: (f = msg.getLastUpdatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    failureReason: jspb.Message.getFieldWithDefault(msg, 16, ""),
    targetModel: (f = msg.getTargetModel()) && proto.qai_hub.api.Model.toObject(includeInstance, f),
    adminUrl: jspb.Message.getFieldWithDefault(msg, 18, ""),
    hasVizgraph: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    queuedTime: (f = msg.getQueuedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.QuantizeJob}
 */
proto.qai_hub.api.QuantizeJob.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.QuantizeJob;
  return proto.qai_hub.api.QuantizeJob.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.QuantizeJob} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.QuantizeJob}
 */
proto.qai_hub.api.QuantizeJob.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuantizeJobId(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.User;
      reader.readMessage(value,proto.qai_hub.api.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserId(value);
      break;
    case 4:
      var value = new proto.qai_hub.api.Model;
      reader.readMessage(value,proto.qai_hub.api.Model.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    case 5:
      var value = new proto.qai_hub.api.Dataset;
      reader.readMessage(value,proto.qai_hub.api.Dataset.deserializeBinaryFromReader);
      msg.setCalibrationDataset(value);
      break;
    case 6:
      var value = /** @type {!proto.qai_hub.api.QuantizeDtype} */ (reader.readEnum());
      msg.setWeightsDtype(value);
      break;
    case 7:
      var value = /** @type {!proto.qai_hub.api.QuantizeDtype} */ (reader.readEnum());
      msg.setActivationsDtype(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 9:
      var value = /** @type {!proto.qai_hub.api.JobState} */ (reader.readEnum());
      msg.setJobState(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptions(value);
      break;
    case 11:
      var value = new proto.qai_hub.api.NamedTensorTypeList;
      reader.readMessage(value,proto.qai_hub.api.NamedTensorTypeList.deserializeBinaryFromReader);
      msg.setTensorTypeList(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletionTime(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdatedTime(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setFailureReason(value);
      break;
    case 17:
      var value = new proto.qai_hub.api.Model;
      reader.readMessage(value,proto.qai_hub.api.Model.deserializeBinaryFromReader);
      msg.setTargetModel(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminUrl(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasVizgraph(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setQueuedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.QuantizeJob.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.QuantizeJob.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.QuantizeJob} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.QuantizeJob.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuantizeJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.User.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.qai_hub.api.Model.serializeBinaryToWriter
    );
  }
  f = message.getCalibrationDataset();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.qai_hub.api.Dataset.serializeBinaryToWriter
    );
  }
  f = message.getWeightsDtype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getActivationsDtype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getJobState();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getOptions();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTensorTypeList();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.qai_hub.api.NamedTensorTypeList.serializeBinaryToWriter
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCompletionTime();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastUpdatedTime();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTargetModel();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.qai_hub.api.Model.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeString(
      18,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 19));
  if (f != null) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getQueuedTime();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string quantize_job_id = 1;
 * @return {string}
 */
proto.qai_hub.api.QuantizeJob.prototype.getQuantizeJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.setQuantizeJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 2;
 * @return {?proto.qai_hub.api.User}
 */
proto.qai_hub.api.QuantizeJob.prototype.getUser = function() {
  return /** @type{?proto.qai_hub.api.User} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.User, 2));
};


/**
 * @param {?proto.qai_hub.api.User|undefined} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
*/
proto.qai_hub.api.QuantizeJob.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJob.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 user_id = 3;
 * @return {number}
 */
proto.qai_hub.api.QuantizeJob.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Model model = 4;
 * @return {?proto.qai_hub.api.Model}
 */
proto.qai_hub.api.QuantizeJob.prototype.getModel = function() {
  return /** @type{?proto.qai_hub.api.Model} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Model, 4));
};


/**
 * @param {?proto.qai_hub.api.Model|undefined} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
*/
proto.qai_hub.api.QuantizeJob.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJob.prototype.hasModel = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Dataset calibration_dataset = 5;
 * @return {?proto.qai_hub.api.Dataset}
 */
proto.qai_hub.api.QuantizeJob.prototype.getCalibrationDataset = function() {
  return /** @type{?proto.qai_hub.api.Dataset} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Dataset, 5));
};


/**
 * @param {?proto.qai_hub.api.Dataset|undefined} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
*/
proto.qai_hub.api.QuantizeJob.prototype.setCalibrationDataset = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.clearCalibrationDataset = function() {
  return this.setCalibrationDataset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJob.prototype.hasCalibrationDataset = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional QuantizeDtype weights_dtype = 6;
 * @return {!proto.qai_hub.api.QuantizeDtype}
 */
proto.qai_hub.api.QuantizeJob.prototype.getWeightsDtype = function() {
  return /** @type {!proto.qai_hub.api.QuantizeDtype} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.qai_hub.api.QuantizeDtype} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.setWeightsDtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional QuantizeDtype activations_dtype = 7;
 * @return {!proto.qai_hub.api.QuantizeDtype}
 */
proto.qai_hub.api.QuantizeJob.prototype.getActivationsDtype = function() {
  return /** @type {!proto.qai_hub.api.QuantizeDtype} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.qai_hub.api.QuantizeDtype} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.setActivationsDtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string name = 8;
 * @return {string}
 */
proto.qai_hub.api.QuantizeJob.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional JobState job_state = 9;
 * @return {!proto.qai_hub.api.JobState}
 */
proto.qai_hub.api.QuantizeJob.prototype.getJobState = function() {
  return /** @type {!proto.qai_hub.api.JobState} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.qai_hub.api.JobState} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.setJobState = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string options = 10;
 * @return {string}
 */
proto.qai_hub.api.QuantizeJob.prototype.getOptions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.setOptions = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional NamedTensorTypeList tensor_type_list = 11;
 * @return {?proto.qai_hub.api.NamedTensorTypeList}
 */
proto.qai_hub.api.QuantizeJob.prototype.getTensorTypeList = function() {
  return /** @type{?proto.qai_hub.api.NamedTensorTypeList} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.NamedTensorTypeList, 11));
};


/**
 * @param {?proto.qai_hub.api.NamedTensorTypeList|undefined} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
*/
proto.qai_hub.api.QuantizeJob.prototype.setTensorTypeList = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.clearTensorTypeList = function() {
  return this.setTensorTypeList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJob.prototype.hasTensorTypeList = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp creation_time = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.QuantizeJob.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
*/
proto.qai_hub.api.QuantizeJob.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJob.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp start_time = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.QuantizeJob.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
*/
proto.qai_hub.api.QuantizeJob.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJob.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Timestamp completion_time = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.QuantizeJob.prototype.getCompletionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
*/
proto.qai_hub.api.QuantizeJob.prototype.setCompletionTime = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.clearCompletionTime = function() {
  return this.setCompletionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJob.prototype.hasCompletionTime = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp last_updated_time = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.QuantizeJob.prototype.getLastUpdatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
*/
proto.qai_hub.api.QuantizeJob.prototype.setLastUpdatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.clearLastUpdatedTime = function() {
  return this.setLastUpdatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJob.prototype.hasLastUpdatedTime = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string failure_reason = 16;
 * @return {string}
 */
proto.qai_hub.api.QuantizeJob.prototype.getFailureReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.setFailureReason = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.clearFailureReason = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJob.prototype.hasFailureReason = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Model target_model = 17;
 * @return {?proto.qai_hub.api.Model}
 */
proto.qai_hub.api.QuantizeJob.prototype.getTargetModel = function() {
  return /** @type{?proto.qai_hub.api.Model} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Model, 17));
};


/**
 * @param {?proto.qai_hub.api.Model|undefined} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
*/
proto.qai_hub.api.QuantizeJob.prototype.setTargetModel = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.clearTargetModel = function() {
  return this.setTargetModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJob.prototype.hasTargetModel = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string admin_url = 18;
 * @return {string}
 */
proto.qai_hub.api.QuantizeJob.prototype.getAdminUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.setAdminUrl = function(value) {
  return jspb.Message.setField(this, 18, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.clearAdminUrl = function() {
  return jspb.Message.setField(this, 18, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJob.prototype.hasAdminUrl = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional bool has_vizgraph = 19;
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJob.prototype.getHasVizgraph = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.setHasVizgraph = function(value) {
  return jspb.Message.setField(this, 19, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.clearHasVizgraph = function() {
  return jspb.Message.setField(this, 19, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJob.prototype.hasHasVizgraph = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.Timestamp queued_time = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.QuantizeJob.prototype.getQueuedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
*/
proto.qai_hub.api.QuantizeJob.prototype.setQueuedTime = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJob} returns this
 */
proto.qai_hub.api.QuantizeJob.prototype.clearQueuedTime = function() {
  return this.setQueuedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJob.prototype.hasQueuedTime = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.ProfileJob.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.ProfileJob.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.ProfileJob} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.ProfileJob.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && proto.qai_hub.api.User.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    model: (f = msg.getModel()) && proto.qai_hub.api.Model.toObject(includeInstance, f),
    jobState: jspb.Message.getFieldWithDefault(msg, 5, 0),
    device: (f = msg.getDevice()) && proto.qai_hub.api.Device.toObject(includeInstance, f),
    tensorTypeList: (f = msg.getTensorTypeList()) && proto.qai_hub.api.NamedTensorTypeList.toObject(includeInstance, f),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    completionTime: (f = msg.getCompletionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    executionTime: jspb.Message.getFieldWithDefault(msg, 11, 0),
    failureReason: jspb.Message.getFieldWithDefault(msg, 12, ""),
    peakMemoryUsage: jspb.Message.getFieldWithDefault(msg, 13, 0),
    name: jspb.Message.getFieldWithDefault(msg, 14, ""),
    options: jspb.Message.getFieldWithDefault(msg, 15, ""),
    dataset: (f = msg.getDataset()) && proto.qai_hub.api.Dataset.toObject(includeInstance, f),
    targetModel: (f = msg.getTargetModel()) && proto.qai_hub.api.Model.toObject(includeInstance, f),
    executionPeakMemory: (f = msg.getExecutionPeakMemory()) && proto.qai_hub.api.Range.toObject(includeInstance, f),
    hasVizgraph: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    lastUpdatedTime: (f = msg.getLastUpdatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    adminUrl: jspb.Message.getFieldWithDefault(msg, 21, ""),
    includesCompile: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    errorDetails: jspb.Message.getFieldWithDefault(msg, 23, ""),
    queuedTime: (f = msg.getQueuedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.ProfileJob}
 */
proto.qai_hub.api.ProfileJob.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.ProfileJob;
  return proto.qai_hub.api.ProfileJob.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.ProfileJob} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.ProfileJob}
 */
proto.qai_hub.api.ProfileJob.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileJobId(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.User;
      reader.readMessage(value,proto.qai_hub.api.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserId(value);
      break;
    case 4:
      var value = new proto.qai_hub.api.Model;
      reader.readMessage(value,proto.qai_hub.api.Model.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    case 5:
      var value = /** @type {!proto.qai_hub.api.JobState} */ (reader.readEnum());
      msg.setJobState(value);
      break;
    case 6:
      var value = new proto.qai_hub.api.Device;
      reader.readMessage(value,proto.qai_hub.api.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 7:
      var value = new proto.qai_hub.api.NamedTensorTypeList;
      reader.readMessage(value,proto.qai_hub.api.NamedTensorTypeList.deserializeBinaryFromReader);
      msg.setTensorTypeList(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletionTime(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setExecutionTime(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFailureReason(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPeakMemoryUsage(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptions(value);
      break;
    case 16:
      var value = new proto.qai_hub.api.Dataset;
      reader.readMessage(value,proto.qai_hub.api.Dataset.deserializeBinaryFromReader);
      msg.setDataset(value);
      break;
    case 17:
      var value = new proto.qai_hub.api.Model;
      reader.readMessage(value,proto.qai_hub.api.Model.deserializeBinaryFromReader);
      msg.setTargetModel(value);
      break;
    case 18:
      var value = new proto.qai_hub.api.Range;
      reader.readMessage(value,proto.qai_hub.api.Range.deserializeBinaryFromReader);
      msg.setExecutionPeakMemory(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasVizgraph(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdatedTime(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminUrl(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludesCompile(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorDetails(value);
      break;
    case 24:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setQueuedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.ProfileJob.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.ProfileJob.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.ProfileJob} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.ProfileJob.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.User.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.qai_hub.api.Model.serializeBinaryToWriter
    );
  }
  f = message.getJobState();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.qai_hub.api.Device.serializeBinaryToWriter
    );
  }
  f = message.getTensorTypeList();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.qai_hub.api.NamedTensorTypeList.serializeBinaryToWriter
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCompletionTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeUint64(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeUint64(
      13,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getOptions();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDataset();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.qai_hub.api.Dataset.serializeBinaryToWriter
    );
  }
  f = message.getTargetModel();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.qai_hub.api.Model.serializeBinaryToWriter
    );
  }
  f = message.getExecutionPeakMemory();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.qai_hub.api.Range.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 19));
  if (f != null) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getLastUpdatedTime();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 21));
  if (f != null) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getIncludesCompile();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 23));
  if (f != null) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getQueuedTime();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string profile_job_id = 1;
 * @return {string}
 */
proto.qai_hub.api.ProfileJob.prototype.getProfileJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.setProfileJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 2;
 * @return {?proto.qai_hub.api.User}
 */
proto.qai_hub.api.ProfileJob.prototype.getUser = function() {
  return /** @type{?proto.qai_hub.api.User} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.User, 2));
};


/**
 * @param {?proto.qai_hub.api.User|undefined} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
*/
proto.qai_hub.api.ProfileJob.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 user_id = 3;
 * @return {number}
 */
proto.qai_hub.api.ProfileJob.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Model model = 4;
 * @return {?proto.qai_hub.api.Model}
 */
proto.qai_hub.api.ProfileJob.prototype.getModel = function() {
  return /** @type{?proto.qai_hub.api.Model} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Model, 4));
};


/**
 * @param {?proto.qai_hub.api.Model|undefined} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
*/
proto.qai_hub.api.ProfileJob.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.hasModel = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional JobState job_state = 5;
 * @return {!proto.qai_hub.api.JobState}
 */
proto.qai_hub.api.ProfileJob.prototype.getJobState = function() {
  return /** @type {!proto.qai_hub.api.JobState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.qai_hub.api.JobState} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.setJobState = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional Device device = 6;
 * @return {?proto.qai_hub.api.Device}
 */
proto.qai_hub.api.ProfileJob.prototype.getDevice = function() {
  return /** @type{?proto.qai_hub.api.Device} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Device, 6));
};


/**
 * @param {?proto.qai_hub.api.Device|undefined} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
*/
proto.qai_hub.api.ProfileJob.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NamedTensorTypeList tensor_type_list = 7;
 * @return {?proto.qai_hub.api.NamedTensorTypeList}
 */
proto.qai_hub.api.ProfileJob.prototype.getTensorTypeList = function() {
  return /** @type{?proto.qai_hub.api.NamedTensorTypeList} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.NamedTensorTypeList, 7));
};


/**
 * @param {?proto.qai_hub.api.NamedTensorTypeList|undefined} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
*/
proto.qai_hub.api.ProfileJob.prototype.setTensorTypeList = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.clearTensorTypeList = function() {
  return this.setTensorTypeList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.hasTensorTypeList = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp creation_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.ProfileJob.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
*/
proto.qai_hub.api.ProfileJob.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp start_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.ProfileJob.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
*/
proto.qai_hub.api.ProfileJob.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp completion_time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.ProfileJob.prototype.getCompletionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
*/
proto.qai_hub.api.ProfileJob.prototype.setCompletionTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.clearCompletionTime = function() {
  return this.setCompletionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.hasCompletionTime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional uint64 execution_time = 11;
 * @return {number}
 */
proto.qai_hub.api.ProfileJob.prototype.getExecutionTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.setExecutionTime = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.clearExecutionTime = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.hasExecutionTime = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string failure_reason = 12;
 * @return {string}
 */
proto.qai_hub.api.ProfileJob.prototype.getFailureReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.setFailureReason = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.clearFailureReason = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.hasFailureReason = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional uint64 peak_memory_usage = 13;
 * @return {number}
 */
proto.qai_hub.api.ProfileJob.prototype.getPeakMemoryUsage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.setPeakMemoryUsage = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.clearPeakMemoryUsage = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.hasPeakMemoryUsage = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string name = 14;
 * @return {string}
 */
proto.qai_hub.api.ProfileJob.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string options = 15;
 * @return {string}
 */
proto.qai_hub.api.ProfileJob.prototype.getOptions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.setOptions = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional Dataset dataset = 16;
 * @return {?proto.qai_hub.api.Dataset}
 */
proto.qai_hub.api.ProfileJob.prototype.getDataset = function() {
  return /** @type{?proto.qai_hub.api.Dataset} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Dataset, 16));
};


/**
 * @param {?proto.qai_hub.api.Dataset|undefined} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
*/
proto.qai_hub.api.ProfileJob.prototype.setDataset = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.clearDataset = function() {
  return this.setDataset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.hasDataset = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Model target_model = 17;
 * @return {?proto.qai_hub.api.Model}
 */
proto.qai_hub.api.ProfileJob.prototype.getTargetModel = function() {
  return /** @type{?proto.qai_hub.api.Model} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Model, 17));
};


/**
 * @param {?proto.qai_hub.api.Model|undefined} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
*/
proto.qai_hub.api.ProfileJob.prototype.setTargetModel = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.clearTargetModel = function() {
  return this.setTargetModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.hasTargetModel = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional Range execution_peak_memory = 18;
 * @return {?proto.qai_hub.api.Range}
 */
proto.qai_hub.api.ProfileJob.prototype.getExecutionPeakMemory = function() {
  return /** @type{?proto.qai_hub.api.Range} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Range, 18));
};


/**
 * @param {?proto.qai_hub.api.Range|undefined} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
*/
proto.qai_hub.api.ProfileJob.prototype.setExecutionPeakMemory = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.clearExecutionPeakMemory = function() {
  return this.setExecutionPeakMemory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.hasExecutionPeakMemory = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional bool has_vizgraph = 19;
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.getHasVizgraph = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.setHasVizgraph = function(value) {
  return jspb.Message.setField(this, 19, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.clearHasVizgraph = function() {
  return jspb.Message.setField(this, 19, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.hasHasVizgraph = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.Timestamp last_updated_time = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.ProfileJob.prototype.getLastUpdatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
*/
proto.qai_hub.api.ProfileJob.prototype.setLastUpdatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.clearLastUpdatedTime = function() {
  return this.setLastUpdatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.hasLastUpdatedTime = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string admin_url = 21;
 * @return {string}
 */
proto.qai_hub.api.ProfileJob.prototype.getAdminUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.setAdminUrl = function(value) {
  return jspb.Message.setField(this, 21, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.clearAdminUrl = function() {
  return jspb.Message.setField(this, 21, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.hasAdminUrl = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional bool includes_compile = 22;
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.getIncludesCompile = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.setIncludesCompile = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional string error_details = 23;
 * @return {string}
 */
proto.qai_hub.api.ProfileJob.prototype.getErrorDetails = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.setErrorDetails = function(value) {
  return jspb.Message.setField(this, 23, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.clearErrorDetails = function() {
  return jspb.Message.setField(this, 23, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.hasErrorDetails = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.protobuf.Timestamp queued_time = 24;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.ProfileJob.prototype.getQueuedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 24));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.ProfileJob} returns this
*/
proto.qai_hub.api.ProfileJob.prototype.setQueuedTime = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJob} returns this
 */
proto.qai_hub.api.ProfileJob.prototype.clearQueuedTime = function() {
  return this.setQueuedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJob.prototype.hasQueuedTime = function() {
  return jspb.Message.getField(this, 24) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.InferenceJob.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.InferenceJob.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.InferenceJob} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.InferenceJob.toObject = function(includeInstance, msg) {
  var f, obj = {
    inferenceJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && proto.qai_hub.api.User.toObject(includeInstance, f),
    model: (f = msg.getModel()) && proto.qai_hub.api.Model.toObject(includeInstance, f),
    device: (f = msg.getDevice()) && proto.qai_hub.api.Device.toObject(includeInstance, f),
    jobState: jspb.Message.getFieldWithDefault(msg, 5, 0),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dataset: (f = msg.getDataset()) && proto.qai_hub.api.Dataset.toObject(includeInstance, f),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    completionTime: (f = msg.getCompletionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    failureReason: jspb.Message.getFieldWithDefault(msg, 11, ""),
    options: jspb.Message.getFieldWithDefault(msg, 12, ""),
    lastUpdatedTime: (f = msg.getLastUpdatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    adminUrl: jspb.Message.getFieldWithDefault(msg, 14, ""),
    errorDetails: jspb.Message.getFieldWithDefault(msg, 15, ""),
    queuedTime: (f = msg.getQueuedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.InferenceJob}
 */
proto.qai_hub.api.InferenceJob.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.InferenceJob;
  return proto.qai_hub.api.InferenceJob.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.InferenceJob} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.InferenceJob}
 */
proto.qai_hub.api.InferenceJob.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInferenceJobId(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.User;
      reader.readMessage(value,proto.qai_hub.api.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = new proto.qai_hub.api.Model;
      reader.readMessage(value,proto.qai_hub.api.Model.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    case 4:
      var value = new proto.qai_hub.api.Device;
      reader.readMessage(value,proto.qai_hub.api.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 5:
      var value = /** @type {!proto.qai_hub.api.JobState} */ (reader.readEnum());
      msg.setJobState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = new proto.qai_hub.api.Dataset;
      reader.readMessage(value,proto.qai_hub.api.Dataset.deserializeBinaryFromReader);
      msg.setDataset(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletionTime(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFailureReason(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptions(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdatedTime(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminUrl(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorDetails(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setQueuedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.InferenceJob.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.InferenceJob.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.InferenceJob} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.InferenceJob.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInferenceJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.User.serializeBinaryToWriter
    );
  }
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qai_hub.api.Model.serializeBinaryToWriter
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.qai_hub.api.Device.serializeBinaryToWriter
    );
  }
  f = message.getJobState();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDataset();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.qai_hub.api.Dataset.serializeBinaryToWriter
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCompletionTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOptions();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLastUpdatedTime();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getQueuedTime();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string inference_job_id = 1;
 * @return {string}
 */
proto.qai_hub.api.InferenceJob.prototype.getInferenceJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.setInferenceJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 2;
 * @return {?proto.qai_hub.api.User}
 */
proto.qai_hub.api.InferenceJob.prototype.getUser = function() {
  return /** @type{?proto.qai_hub.api.User} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.User, 2));
};


/**
 * @param {?proto.qai_hub.api.User|undefined} value
 * @return {!proto.qai_hub.api.InferenceJob} returns this
*/
proto.qai_hub.api.InferenceJob.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJob.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Model model = 3;
 * @return {?proto.qai_hub.api.Model}
 */
proto.qai_hub.api.InferenceJob.prototype.getModel = function() {
  return /** @type{?proto.qai_hub.api.Model} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Model, 3));
};


/**
 * @param {?proto.qai_hub.api.Model|undefined} value
 * @return {!proto.qai_hub.api.InferenceJob} returns this
*/
proto.qai_hub.api.InferenceJob.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJob.prototype.hasModel = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Device device = 4;
 * @return {?proto.qai_hub.api.Device}
 */
proto.qai_hub.api.InferenceJob.prototype.getDevice = function() {
  return /** @type{?proto.qai_hub.api.Device} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Device, 4));
};


/**
 * @param {?proto.qai_hub.api.Device|undefined} value
 * @return {!proto.qai_hub.api.InferenceJob} returns this
*/
proto.qai_hub.api.InferenceJob.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJob.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional JobState job_state = 5;
 * @return {!proto.qai_hub.api.JobState}
 */
proto.qai_hub.api.InferenceJob.prototype.getJobState = function() {
  return /** @type {!proto.qai_hub.api.JobState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.qai_hub.api.JobState} value
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.setJobState = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.qai_hub.api.InferenceJob.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Dataset dataset = 7;
 * @return {?proto.qai_hub.api.Dataset}
 */
proto.qai_hub.api.InferenceJob.prototype.getDataset = function() {
  return /** @type{?proto.qai_hub.api.Dataset} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Dataset, 7));
};


/**
 * @param {?proto.qai_hub.api.Dataset|undefined} value
 * @return {!proto.qai_hub.api.InferenceJob} returns this
*/
proto.qai_hub.api.InferenceJob.prototype.setDataset = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.clearDataset = function() {
  return this.setDataset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJob.prototype.hasDataset = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp creation_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.InferenceJob.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.InferenceJob} returns this
*/
proto.qai_hub.api.InferenceJob.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJob.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp start_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.InferenceJob.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.InferenceJob} returns this
*/
proto.qai_hub.api.InferenceJob.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJob.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp completion_time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.InferenceJob.prototype.getCompletionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.InferenceJob} returns this
*/
proto.qai_hub.api.InferenceJob.prototype.setCompletionTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.clearCompletionTime = function() {
  return this.setCompletionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJob.prototype.hasCompletionTime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string failure_reason = 11;
 * @return {string}
 */
proto.qai_hub.api.InferenceJob.prototype.getFailureReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.setFailureReason = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.clearFailureReason = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJob.prototype.hasFailureReason = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string options = 12;
 * @return {string}
 */
proto.qai_hub.api.InferenceJob.prototype.getOptions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.setOptions = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional google.protobuf.Timestamp last_updated_time = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.InferenceJob.prototype.getLastUpdatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.InferenceJob} returns this
*/
proto.qai_hub.api.InferenceJob.prototype.setLastUpdatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.clearLastUpdatedTime = function() {
  return this.setLastUpdatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJob.prototype.hasLastUpdatedTime = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string admin_url = 14;
 * @return {string}
 */
proto.qai_hub.api.InferenceJob.prototype.getAdminUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.setAdminUrl = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.clearAdminUrl = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJob.prototype.hasAdminUrl = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string error_details = 15;
 * @return {string}
 */
proto.qai_hub.api.InferenceJob.prototype.getErrorDetails = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.setErrorDetails = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.clearErrorDetails = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJob.prototype.hasErrorDetails = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp queued_time = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.InferenceJob.prototype.getQueuedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.InferenceJob} returns this
*/
proto.qai_hub.api.InferenceJob.prototype.setQueuedTime = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJob} returns this
 */
proto.qai_hub.api.InferenceJob.prototype.clearQueuedTime = function() {
  return this.setQueuedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJob.prototype.hasQueuedTime = function() {
  return jspb.Message.getField(this, 16) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.Job.repeatedFields_ = [8];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.qai_hub.api.Job.oneofGroups_ = [[1,2,4,6,7]];

/**
 * @enum {number}
 */
proto.qai_hub.api.Job.JobCase = {
  JOB_NOT_SET: 0,
  PROFILE_JOB: 1,
  INFERENCE_JOB: 2,
  COMPILE_JOB: 4,
  QUANTIZE_JOB: 6,
  LINK_JOB: 7
};

/**
 * @return {proto.qai_hub.api.Job.JobCase}
 */
proto.qai_hub.api.Job.prototype.getJobCase = function() {
  return /** @type {proto.qai_hub.api.Job.JobCase} */(jspb.Message.computeOneofCase(this, proto.qai_hub.api.Job.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.Job.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.Job.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.Job} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.Job.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileJob: (f = msg.getProfileJob()) && proto.qai_hub.api.ProfileJob.toObject(includeInstance, f),
    inferenceJob: (f = msg.getInferenceJob()) && proto.qai_hub.api.InferenceJob.toObject(includeInstance, f),
    compileJob: (f = msg.getCompileJob()) && proto.qai_hub.api.CompileJob.toObject(includeInstance, f),
    quantizeJob: (f = msg.getQuantizeJob()) && proto.qai_hub.api.QuantizeJob.toObject(includeInstance, f),
    linkJob: (f = msg.getLinkJob()) && proto.qai_hub.api.LinkJob.toObject(includeInstance, f),
    hubVersion: jspb.Message.getFieldWithDefault(msg, 3, ""),
    readOnly: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    noticesList: jspb.Message.toObjectList(msg.getNoticesList(),
    proto.qai_hub.api.Notice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.Job}
 */
proto.qai_hub.api.Job.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.Job;
  return proto.qai_hub.api.Job.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.Job} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.Job}
 */
proto.qai_hub.api.Job.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qai_hub.api.ProfileJob;
      reader.readMessage(value,proto.qai_hub.api.ProfileJob.deserializeBinaryFromReader);
      msg.setProfileJob(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.InferenceJob;
      reader.readMessage(value,proto.qai_hub.api.InferenceJob.deserializeBinaryFromReader);
      msg.setInferenceJob(value);
      break;
    case 4:
      var value = new proto.qai_hub.api.CompileJob;
      reader.readMessage(value,proto.qai_hub.api.CompileJob.deserializeBinaryFromReader);
      msg.setCompileJob(value);
      break;
    case 6:
      var value = new proto.qai_hub.api.QuantizeJob;
      reader.readMessage(value,proto.qai_hub.api.QuantizeJob.deserializeBinaryFromReader);
      msg.setQuantizeJob(value);
      break;
    case 7:
      var value = new proto.qai_hub.api.LinkJob;
      reader.readMessage(value,proto.qai_hub.api.LinkJob.deserializeBinaryFromReader);
      msg.setLinkJob(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHubVersion(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadOnly(value);
      break;
    case 8:
      var value = new proto.qai_hub.api.Notice;
      reader.readMessage(value,proto.qai_hub.api.Notice.deserializeBinaryFromReader);
      msg.addNotices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.Job.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.Job.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.Job} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.Job.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileJob();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qai_hub.api.ProfileJob.serializeBinaryToWriter
    );
  }
  f = message.getInferenceJob();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.InferenceJob.serializeBinaryToWriter
    );
  }
  f = message.getCompileJob();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.qai_hub.api.CompileJob.serializeBinaryToWriter
    );
  }
  f = message.getQuantizeJob();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.qai_hub.api.QuantizeJob.serializeBinaryToWriter
    );
  }
  f = message.getLinkJob();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.qai_hub.api.LinkJob.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getNoticesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.qai_hub.api.Notice.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProfileJob profile_job = 1;
 * @return {?proto.qai_hub.api.ProfileJob}
 */
proto.qai_hub.api.Job.prototype.getProfileJob = function() {
  return /** @type{?proto.qai_hub.api.ProfileJob} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.ProfileJob, 1));
};


/**
 * @param {?proto.qai_hub.api.ProfileJob|undefined} value
 * @return {!proto.qai_hub.api.Job} returns this
*/
proto.qai_hub.api.Job.prototype.setProfileJob = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.qai_hub.api.Job.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.Job} returns this
 */
proto.qai_hub.api.Job.prototype.clearProfileJob = function() {
  return this.setProfileJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Job.prototype.hasProfileJob = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional InferenceJob inference_job = 2;
 * @return {?proto.qai_hub.api.InferenceJob}
 */
proto.qai_hub.api.Job.prototype.getInferenceJob = function() {
  return /** @type{?proto.qai_hub.api.InferenceJob} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.InferenceJob, 2));
};


/**
 * @param {?proto.qai_hub.api.InferenceJob|undefined} value
 * @return {!proto.qai_hub.api.Job} returns this
*/
proto.qai_hub.api.Job.prototype.setInferenceJob = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.qai_hub.api.Job.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.Job} returns this
 */
proto.qai_hub.api.Job.prototype.clearInferenceJob = function() {
  return this.setInferenceJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Job.prototype.hasInferenceJob = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CompileJob compile_job = 4;
 * @return {?proto.qai_hub.api.CompileJob}
 */
proto.qai_hub.api.Job.prototype.getCompileJob = function() {
  return /** @type{?proto.qai_hub.api.CompileJob} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.CompileJob, 4));
};


/**
 * @param {?proto.qai_hub.api.CompileJob|undefined} value
 * @return {!proto.qai_hub.api.Job} returns this
*/
proto.qai_hub.api.Job.prototype.setCompileJob = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.qai_hub.api.Job.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.Job} returns this
 */
proto.qai_hub.api.Job.prototype.clearCompileJob = function() {
  return this.setCompileJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Job.prototype.hasCompileJob = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional QuantizeJob quantize_job = 6;
 * @return {?proto.qai_hub.api.QuantizeJob}
 */
proto.qai_hub.api.Job.prototype.getQuantizeJob = function() {
  return /** @type{?proto.qai_hub.api.QuantizeJob} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.QuantizeJob, 6));
};


/**
 * @param {?proto.qai_hub.api.QuantizeJob|undefined} value
 * @return {!proto.qai_hub.api.Job} returns this
*/
proto.qai_hub.api.Job.prototype.setQuantizeJob = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.qai_hub.api.Job.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.Job} returns this
 */
proto.qai_hub.api.Job.prototype.clearQuantizeJob = function() {
  return this.setQuantizeJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Job.prototype.hasQuantizeJob = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional LinkJob link_job = 7;
 * @return {?proto.qai_hub.api.LinkJob}
 */
proto.qai_hub.api.Job.prototype.getLinkJob = function() {
  return /** @type{?proto.qai_hub.api.LinkJob} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.LinkJob, 7));
};


/**
 * @param {?proto.qai_hub.api.LinkJob|undefined} value
 * @return {!proto.qai_hub.api.Job} returns this
*/
proto.qai_hub.api.Job.prototype.setLinkJob = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.qai_hub.api.Job.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.Job} returns this
 */
proto.qai_hub.api.Job.prototype.clearLinkJob = function() {
  return this.setLinkJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Job.prototype.hasLinkJob = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string hub_version = 3;
 * @return {string}
 */
proto.qai_hub.api.Job.prototype.getHubVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.Job} returns this
 */
proto.qai_hub.api.Job.prototype.setHubVersion = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.Job} returns this
 */
proto.qai_hub.api.Job.prototype.clearHubVersion = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Job.prototype.hasHubVersion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool read_only = 5;
 * @return {boolean}
 */
proto.qai_hub.api.Job.prototype.getReadOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qai_hub.api.Job} returns this
 */
proto.qai_hub.api.Job.prototype.setReadOnly = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.Job} returns this
 */
proto.qai_hub.api.Job.prototype.clearReadOnly = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.Job.prototype.hasReadOnly = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated Notice notices = 8;
 * @return {!Array<!proto.qai_hub.api.Notice>}
 */
proto.qai_hub.api.Job.prototype.getNoticesList = function() {
  return /** @type{!Array<!proto.qai_hub.api.Notice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qai_hub.api.Notice, 8));
};


/**
 * @param {!Array<!proto.qai_hub.api.Notice>} value
 * @return {!proto.qai_hub.api.Job} returns this
*/
proto.qai_hub.api.Job.prototype.setNoticesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.qai_hub.api.Notice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.Notice}
 */
proto.qai_hub.api.Job.prototype.addNotices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.qai_hub.api.Notice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.Job} returns this
 */
proto.qai_hub.api.Job.prototype.clearNoticesList = function() {
  return this.setNoticesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.JobList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.JobList.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.JobList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.JobList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.JobList.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobsList: jspb.Message.toObjectList(msg.getJobsList(),
    proto.qai_hub.api.Job.toObject, includeInstance),
    totalQueryCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.JobList}
 */
proto.qai_hub.api.JobList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.JobList;
  return proto.qai_hub.api.JobList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.JobList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.JobList}
 */
proto.qai_hub.api.JobList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qai_hub.api.Job;
      reader.readMessage(value,proto.qai_hub.api.Job.deserializeBinaryFromReader);
      msg.addJobs(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalQueryCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.JobList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.JobList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.JobList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.JobList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.qai_hub.api.Job.serializeBinaryToWriter
    );
  }
  f = message.getTotalQueryCount();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * repeated Job jobs = 1;
 * @return {!Array<!proto.qai_hub.api.Job>}
 */
proto.qai_hub.api.JobList.prototype.getJobsList = function() {
  return /** @type{!Array<!proto.qai_hub.api.Job>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qai_hub.api.Job, 1));
};


/**
 * @param {!Array<!proto.qai_hub.api.Job>} value
 * @return {!proto.qai_hub.api.JobList} returns this
*/
proto.qai_hub.api.JobList.prototype.setJobsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.qai_hub.api.Job=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.Job}
 */
proto.qai_hub.api.JobList.prototype.addJobs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.qai_hub.api.Job, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.JobList} returns this
 */
proto.qai_hub.api.JobList.prototype.clearJobsList = function() {
  return this.setJobsList([]);
};


/**
 * optional uint64 total_query_count = 2;
 * @return {number}
 */
proto.qai_hub.api.JobList.prototype.getTotalQueryCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.JobList} returns this
 */
proto.qai_hub.api.JobList.prototype.setTotalQueryCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.qai_hub.api.JobSummary.oneofGroups_ = [[1,2,4,5,6]];

/**
 * @enum {number}
 */
proto.qai_hub.api.JobSummary.JobCase = {
  JOB_NOT_SET: 0,
  PROFILE_JOB_SUMMARY: 1,
  INFERENCE_JOB_SUMMARY: 2,
  COMPILE_JOB_SUMMARY: 4,
  QUANTIZE_JOB_SUMMARY: 5,
  LINK_JOB_SUMMARY: 6
};

/**
 * @return {proto.qai_hub.api.JobSummary.JobCase}
 */
proto.qai_hub.api.JobSummary.prototype.getJobCase = function() {
  return /** @type {proto.qai_hub.api.JobSummary.JobCase} */(jspb.Message.computeOneofCase(this, proto.qai_hub.api.JobSummary.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.JobSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.JobSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.JobSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.JobSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileJobSummary: (f = msg.getProfileJobSummary()) && proto.qai_hub.api.ProfileJobSummary.toObject(includeInstance, f),
    inferenceJobSummary: (f = msg.getInferenceJobSummary()) && proto.qai_hub.api.InferenceJobSummary.toObject(includeInstance, f),
    compileJobSummary: (f = msg.getCompileJobSummary()) && proto.qai_hub.api.CompileJobSummary.toObject(includeInstance, f),
    quantizeJobSummary: (f = msg.getQuantizeJobSummary()) && proto.qai_hub.api.QuantizeJobSummary.toObject(includeInstance, f),
    linkJobSummary: (f = msg.getLinkJobSummary()) && proto.qai_hub.api.LinkJobSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.JobSummary}
 */
proto.qai_hub.api.JobSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.JobSummary;
  return proto.qai_hub.api.JobSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.JobSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.JobSummary}
 */
proto.qai_hub.api.JobSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qai_hub.api.ProfileJobSummary;
      reader.readMessage(value,proto.qai_hub.api.ProfileJobSummary.deserializeBinaryFromReader);
      msg.setProfileJobSummary(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.InferenceJobSummary;
      reader.readMessage(value,proto.qai_hub.api.InferenceJobSummary.deserializeBinaryFromReader);
      msg.setInferenceJobSummary(value);
      break;
    case 4:
      var value = new proto.qai_hub.api.CompileJobSummary;
      reader.readMessage(value,proto.qai_hub.api.CompileJobSummary.deserializeBinaryFromReader);
      msg.setCompileJobSummary(value);
      break;
    case 5:
      var value = new proto.qai_hub.api.QuantizeJobSummary;
      reader.readMessage(value,proto.qai_hub.api.QuantizeJobSummary.deserializeBinaryFromReader);
      msg.setQuantizeJobSummary(value);
      break;
    case 6:
      var value = new proto.qai_hub.api.LinkJobSummary;
      reader.readMessage(value,proto.qai_hub.api.LinkJobSummary.deserializeBinaryFromReader);
      msg.setLinkJobSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.JobSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.JobSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.JobSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.JobSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileJobSummary();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qai_hub.api.ProfileJobSummary.serializeBinaryToWriter
    );
  }
  f = message.getInferenceJobSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.InferenceJobSummary.serializeBinaryToWriter
    );
  }
  f = message.getCompileJobSummary();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.qai_hub.api.CompileJobSummary.serializeBinaryToWriter
    );
  }
  f = message.getQuantizeJobSummary();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.qai_hub.api.QuantizeJobSummary.serializeBinaryToWriter
    );
  }
  f = message.getLinkJobSummary();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.qai_hub.api.LinkJobSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProfileJobSummary profile_job_summary = 1;
 * @return {?proto.qai_hub.api.ProfileJobSummary}
 */
proto.qai_hub.api.JobSummary.prototype.getProfileJobSummary = function() {
  return /** @type{?proto.qai_hub.api.ProfileJobSummary} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.ProfileJobSummary, 1));
};


/**
 * @param {?proto.qai_hub.api.ProfileJobSummary|undefined} value
 * @return {!proto.qai_hub.api.JobSummary} returns this
*/
proto.qai_hub.api.JobSummary.prototype.setProfileJobSummary = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.qai_hub.api.JobSummary.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.JobSummary} returns this
 */
proto.qai_hub.api.JobSummary.prototype.clearProfileJobSummary = function() {
  return this.setProfileJobSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.JobSummary.prototype.hasProfileJobSummary = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional InferenceJobSummary inference_job_summary = 2;
 * @return {?proto.qai_hub.api.InferenceJobSummary}
 */
proto.qai_hub.api.JobSummary.prototype.getInferenceJobSummary = function() {
  return /** @type{?proto.qai_hub.api.InferenceJobSummary} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.InferenceJobSummary, 2));
};


/**
 * @param {?proto.qai_hub.api.InferenceJobSummary|undefined} value
 * @return {!proto.qai_hub.api.JobSummary} returns this
*/
proto.qai_hub.api.JobSummary.prototype.setInferenceJobSummary = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.qai_hub.api.JobSummary.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.JobSummary} returns this
 */
proto.qai_hub.api.JobSummary.prototype.clearInferenceJobSummary = function() {
  return this.setInferenceJobSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.JobSummary.prototype.hasInferenceJobSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CompileJobSummary compile_job_summary = 4;
 * @return {?proto.qai_hub.api.CompileJobSummary}
 */
proto.qai_hub.api.JobSummary.prototype.getCompileJobSummary = function() {
  return /** @type{?proto.qai_hub.api.CompileJobSummary} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.CompileJobSummary, 4));
};


/**
 * @param {?proto.qai_hub.api.CompileJobSummary|undefined} value
 * @return {!proto.qai_hub.api.JobSummary} returns this
*/
proto.qai_hub.api.JobSummary.prototype.setCompileJobSummary = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.qai_hub.api.JobSummary.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.JobSummary} returns this
 */
proto.qai_hub.api.JobSummary.prototype.clearCompileJobSummary = function() {
  return this.setCompileJobSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.JobSummary.prototype.hasCompileJobSummary = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional QuantizeJobSummary quantize_job_summary = 5;
 * @return {?proto.qai_hub.api.QuantizeJobSummary}
 */
proto.qai_hub.api.JobSummary.prototype.getQuantizeJobSummary = function() {
  return /** @type{?proto.qai_hub.api.QuantizeJobSummary} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.QuantizeJobSummary, 5));
};


/**
 * @param {?proto.qai_hub.api.QuantizeJobSummary|undefined} value
 * @return {!proto.qai_hub.api.JobSummary} returns this
*/
proto.qai_hub.api.JobSummary.prototype.setQuantizeJobSummary = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.qai_hub.api.JobSummary.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.JobSummary} returns this
 */
proto.qai_hub.api.JobSummary.prototype.clearQuantizeJobSummary = function() {
  return this.setQuantizeJobSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.JobSummary.prototype.hasQuantizeJobSummary = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional LinkJobSummary link_job_summary = 6;
 * @return {?proto.qai_hub.api.LinkJobSummary}
 */
proto.qai_hub.api.JobSummary.prototype.getLinkJobSummary = function() {
  return /** @type{?proto.qai_hub.api.LinkJobSummary} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.LinkJobSummary, 6));
};


/**
 * @param {?proto.qai_hub.api.LinkJobSummary|undefined} value
 * @return {!proto.qai_hub.api.JobSummary} returns this
*/
proto.qai_hub.api.JobSummary.prototype.setLinkJobSummary = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.qai_hub.api.JobSummary.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.JobSummary} returns this
 */
proto.qai_hub.api.JobSummary.prototype.clearLinkJobSummary = function() {
  return this.setLinkJobSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.JobSummary.prototype.hasLinkJobSummary = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.CompileJobSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.CompileJobSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.CompileJobSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.CompileJobSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && proto.qai_hub.api.User.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    jobState: jspb.Message.getFieldWithDefault(msg, 4, 0),
    deviceName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    deviceOsVersion: jspb.Message.getFieldWithDefault(msg, 6, ""),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    completionTime: (f = msg.getCompletionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastUpdatedTime: (f = msg.getLastUpdatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    queuedTime: (f = msg.getQueuedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.CompileJobSummary}
 */
proto.qai_hub.api.CompileJobSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.CompileJobSummary;
  return proto.qai_hub.api.CompileJobSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.CompileJobSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.CompileJobSummary}
 */
proto.qai_hub.api.CompileJobSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobId(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.User;
      reader.readMessage(value,proto.qai_hub.api.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!proto.qai_hub.api.JobState} */ (reader.readEnum());
      msg.setJobState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceOsVersion(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletionTime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdatedTime(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setQueuedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.CompileJobSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.CompileJobSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.CompileJobSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.CompileJobSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.User.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getJobState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDeviceName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDeviceOsVersion();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCompletionTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastUpdatedTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getQueuedTime();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string job_id = 1;
 * @return {string}
 */
proto.qai_hub.api.CompileJobSummary.prototype.getJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.CompileJobSummary} returns this
 */
proto.qai_hub.api.CompileJobSummary.prototype.setJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 2;
 * @return {?proto.qai_hub.api.User}
 */
proto.qai_hub.api.CompileJobSummary.prototype.getUser = function() {
  return /** @type{?proto.qai_hub.api.User} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.User, 2));
};


/**
 * @param {?proto.qai_hub.api.User|undefined} value
 * @return {!proto.qai_hub.api.CompileJobSummary} returns this
*/
proto.qai_hub.api.CompileJobSummary.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJobSummary} returns this
 */
proto.qai_hub.api.CompileJobSummary.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJobSummary.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.qai_hub.api.CompileJobSummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.CompileJobSummary} returns this
 */
proto.qai_hub.api.CompileJobSummary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional JobState job_state = 4;
 * @return {!proto.qai_hub.api.JobState}
 */
proto.qai_hub.api.CompileJobSummary.prototype.getJobState = function() {
  return /** @type {!proto.qai_hub.api.JobState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.qai_hub.api.JobState} value
 * @return {!proto.qai_hub.api.CompileJobSummary} returns this
 */
proto.qai_hub.api.CompileJobSummary.prototype.setJobState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string device_name = 5;
 * @return {string}
 */
proto.qai_hub.api.CompileJobSummary.prototype.getDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.CompileJobSummary} returns this
 */
proto.qai_hub.api.CompileJobSummary.prototype.setDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string device_os_version = 6;
 * @return {string}
 */
proto.qai_hub.api.CompileJobSummary.prototype.getDeviceOsVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.CompileJobSummary} returns this
 */
proto.qai_hub.api.CompileJobSummary.prototype.setDeviceOsVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp creation_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.CompileJobSummary.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.CompileJobSummary} returns this
*/
proto.qai_hub.api.CompileJobSummary.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJobSummary} returns this
 */
proto.qai_hub.api.CompileJobSummary.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJobSummary.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp start_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.CompileJobSummary.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.CompileJobSummary} returns this
*/
proto.qai_hub.api.CompileJobSummary.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJobSummary} returns this
 */
proto.qai_hub.api.CompileJobSummary.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJobSummary.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp completion_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.CompileJobSummary.prototype.getCompletionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.CompileJobSummary} returns this
*/
proto.qai_hub.api.CompileJobSummary.prototype.setCompletionTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJobSummary} returns this
 */
proto.qai_hub.api.CompileJobSummary.prototype.clearCompletionTime = function() {
  return this.setCompletionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJobSummary.prototype.hasCompletionTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp last_updated_time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.CompileJobSummary.prototype.getLastUpdatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.CompileJobSummary} returns this
*/
proto.qai_hub.api.CompileJobSummary.prototype.setLastUpdatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJobSummary} returns this
 */
proto.qai_hub.api.CompileJobSummary.prototype.clearLastUpdatedTime = function() {
  return this.setLastUpdatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJobSummary.prototype.hasLastUpdatedTime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp queued_time = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.CompileJobSummary.prototype.getQueuedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.CompileJobSummary} returns this
*/
proto.qai_hub.api.CompileJobSummary.prototype.setQueuedTime = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJobSummary} returns this
 */
proto.qai_hub.api.CompileJobSummary.prototype.clearQueuedTime = function() {
  return this.setQueuedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJobSummary.prototype.hasQueuedTime = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.LinkJobSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.LinkJobSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.LinkJobSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.LinkJobSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && proto.qai_hub.api.User.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    jobState: jspb.Message.getFieldWithDefault(msg, 4, 0),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    completionTime: (f = msg.getCompletionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastUpdatedTime: (f = msg.getLastUpdatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    queuedTime: (f = msg.getQueuedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.LinkJobSummary}
 */
proto.qai_hub.api.LinkJobSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.LinkJobSummary;
  return proto.qai_hub.api.LinkJobSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.LinkJobSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.LinkJobSummary}
 */
proto.qai_hub.api.LinkJobSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobId(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.User;
      reader.readMessage(value,proto.qai_hub.api.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!proto.qai_hub.api.JobState} */ (reader.readEnum());
      msg.setJobState(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletionTime(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdatedTime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setQueuedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.LinkJobSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.LinkJobSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.LinkJobSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.LinkJobSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.User.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getJobState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCompletionTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastUpdatedTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getQueuedTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string job_id = 1;
 * @return {string}
 */
proto.qai_hub.api.LinkJobSummary.prototype.getJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.LinkJobSummary} returns this
 */
proto.qai_hub.api.LinkJobSummary.prototype.setJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 2;
 * @return {?proto.qai_hub.api.User}
 */
proto.qai_hub.api.LinkJobSummary.prototype.getUser = function() {
  return /** @type{?proto.qai_hub.api.User} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.User, 2));
};


/**
 * @param {?proto.qai_hub.api.User|undefined} value
 * @return {!proto.qai_hub.api.LinkJobSummary} returns this
*/
proto.qai_hub.api.LinkJobSummary.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.LinkJobSummary} returns this
 */
proto.qai_hub.api.LinkJobSummary.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LinkJobSummary.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.qai_hub.api.LinkJobSummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.LinkJobSummary} returns this
 */
proto.qai_hub.api.LinkJobSummary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional JobState job_state = 4;
 * @return {!proto.qai_hub.api.JobState}
 */
proto.qai_hub.api.LinkJobSummary.prototype.getJobState = function() {
  return /** @type {!proto.qai_hub.api.JobState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.qai_hub.api.JobState} value
 * @return {!proto.qai_hub.api.LinkJobSummary} returns this
 */
proto.qai_hub.api.LinkJobSummary.prototype.setJobState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp creation_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.LinkJobSummary.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.LinkJobSummary} returns this
*/
proto.qai_hub.api.LinkJobSummary.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.LinkJobSummary} returns this
 */
proto.qai_hub.api.LinkJobSummary.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LinkJobSummary.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp start_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.LinkJobSummary.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.LinkJobSummary} returns this
*/
proto.qai_hub.api.LinkJobSummary.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.LinkJobSummary} returns this
 */
proto.qai_hub.api.LinkJobSummary.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LinkJobSummary.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp completion_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.LinkJobSummary.prototype.getCompletionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.LinkJobSummary} returns this
*/
proto.qai_hub.api.LinkJobSummary.prototype.setCompletionTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.LinkJobSummary} returns this
 */
proto.qai_hub.api.LinkJobSummary.prototype.clearCompletionTime = function() {
  return this.setCompletionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LinkJobSummary.prototype.hasCompletionTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp last_updated_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.LinkJobSummary.prototype.getLastUpdatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.LinkJobSummary} returns this
*/
proto.qai_hub.api.LinkJobSummary.prototype.setLastUpdatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.LinkJobSummary} returns this
 */
proto.qai_hub.api.LinkJobSummary.prototype.clearLastUpdatedTime = function() {
  return this.setLastUpdatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LinkJobSummary.prototype.hasLastUpdatedTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp queued_time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.LinkJobSummary.prototype.getQueuedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.LinkJobSummary} returns this
*/
proto.qai_hub.api.LinkJobSummary.prototype.setQueuedTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.LinkJobSummary} returns this
 */
proto.qai_hub.api.LinkJobSummary.prototype.clearQueuedTime = function() {
  return this.setQueuedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LinkJobSummary.prototype.hasQueuedTime = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.QuantizeJobSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.QuantizeJobSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.QuantizeJobSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && proto.qai_hub.api.User.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    jobState: jspb.Message.getFieldWithDefault(msg, 4, 0),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    completionTime: (f = msg.getCompletionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastUpdatedTime: (f = msg.getLastUpdatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    queuedTime: (f = msg.getQueuedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.QuantizeJobSummary}
 */
proto.qai_hub.api.QuantizeJobSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.QuantizeJobSummary;
  return proto.qai_hub.api.QuantizeJobSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.QuantizeJobSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.QuantizeJobSummary}
 */
proto.qai_hub.api.QuantizeJobSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobId(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.User;
      reader.readMessage(value,proto.qai_hub.api.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!proto.qai_hub.api.JobState} */ (reader.readEnum());
      msg.setJobState(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletionTime(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdatedTime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setQueuedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.QuantizeJobSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.QuantizeJobSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.QuantizeJobSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.User.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getJobState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCompletionTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastUpdatedTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getQueuedTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string job_id = 1;
 * @return {string}
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.getJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.QuantizeJobSummary} returns this
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.setJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 2;
 * @return {?proto.qai_hub.api.User}
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.getUser = function() {
  return /** @type{?proto.qai_hub.api.User} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.User, 2));
};


/**
 * @param {?proto.qai_hub.api.User|undefined} value
 * @return {!proto.qai_hub.api.QuantizeJobSummary} returns this
*/
proto.qai_hub.api.QuantizeJobSummary.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJobSummary} returns this
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.QuantizeJobSummary} returns this
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional JobState job_state = 4;
 * @return {!proto.qai_hub.api.JobState}
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.getJobState = function() {
  return /** @type {!proto.qai_hub.api.JobState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.qai_hub.api.JobState} value
 * @return {!proto.qai_hub.api.QuantizeJobSummary} returns this
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.setJobState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp creation_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.QuantizeJobSummary} returns this
*/
proto.qai_hub.api.QuantizeJobSummary.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJobSummary} returns this
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp start_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.QuantizeJobSummary} returns this
*/
proto.qai_hub.api.QuantizeJobSummary.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJobSummary} returns this
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp completion_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.getCompletionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.QuantizeJobSummary} returns this
*/
proto.qai_hub.api.QuantizeJobSummary.prototype.setCompletionTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJobSummary} returns this
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.clearCompletionTime = function() {
  return this.setCompletionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.hasCompletionTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp last_updated_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.getLastUpdatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.QuantizeJobSummary} returns this
*/
proto.qai_hub.api.QuantizeJobSummary.prototype.setLastUpdatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJobSummary} returns this
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.clearLastUpdatedTime = function() {
  return this.setLastUpdatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.hasLastUpdatedTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp queued_time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.getQueuedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.QuantizeJobSummary} returns this
*/
proto.qai_hub.api.QuantizeJobSummary.prototype.setQueuedTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJobSummary} returns this
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.clearQueuedTime = function() {
  return this.setQueuedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJobSummary.prototype.hasQueuedTime = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.ProfileJobSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.ProfileJobSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.ProfileJobSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && proto.qai_hub.api.User.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    jobState: jspb.Message.getFieldWithDefault(msg, 4, 0),
    deviceName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    deviceOsVersion: jspb.Message.getFieldWithDefault(msg, 6, ""),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    completionTime: (f = msg.getCompletionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastUpdatedTime: (f = msg.getLastUpdatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    executionTime: jspb.Message.getFieldWithDefault(msg, 12, 0),
    executionPeakMemory: (f = msg.getExecutionPeakMemory()) && proto.qai_hub.api.Range.toObject(includeInstance, f),
    queuedTime: (f = msg.getQueuedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.ProfileJobSummary}
 */
proto.qai_hub.api.ProfileJobSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.ProfileJobSummary;
  return proto.qai_hub.api.ProfileJobSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.ProfileJobSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.ProfileJobSummary}
 */
proto.qai_hub.api.ProfileJobSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobId(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.User;
      reader.readMessage(value,proto.qai_hub.api.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!proto.qai_hub.api.JobState} */ (reader.readEnum());
      msg.setJobState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceOsVersion(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletionTime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdatedTime(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setExecutionTime(value);
      break;
    case 13:
      var value = new proto.qai_hub.api.Range;
      reader.readMessage(value,proto.qai_hub.api.Range.deserializeBinaryFromReader);
      msg.setExecutionPeakMemory(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setQueuedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.ProfileJobSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.ProfileJobSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.ProfileJobSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.User.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getJobState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDeviceName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDeviceOsVersion();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCompletionTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastUpdatedTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeUint64(
      12,
      f
    );
  }
  f = message.getExecutionPeakMemory();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.qai_hub.api.Range.serializeBinaryToWriter
    );
  }
  f = message.getQueuedTime();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string job_id = 1;
 * @return {string}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.getJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
 */
proto.qai_hub.api.ProfileJobSummary.prototype.setJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 2;
 * @return {?proto.qai_hub.api.User}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.getUser = function() {
  return /** @type{?proto.qai_hub.api.User} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.User, 2));
};


/**
 * @param {?proto.qai_hub.api.User|undefined} value
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
*/
proto.qai_hub.api.ProfileJobSummary.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
 */
proto.qai_hub.api.ProfileJobSummary.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
 */
proto.qai_hub.api.ProfileJobSummary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional JobState job_state = 4;
 * @return {!proto.qai_hub.api.JobState}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.getJobState = function() {
  return /** @type {!proto.qai_hub.api.JobState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.qai_hub.api.JobState} value
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
 */
proto.qai_hub.api.ProfileJobSummary.prototype.setJobState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string device_name = 5;
 * @return {string}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.getDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
 */
proto.qai_hub.api.ProfileJobSummary.prototype.setDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string device_os_version = 6;
 * @return {string}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.getDeviceOsVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
 */
proto.qai_hub.api.ProfileJobSummary.prototype.setDeviceOsVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp creation_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
*/
proto.qai_hub.api.ProfileJobSummary.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
 */
proto.qai_hub.api.ProfileJobSummary.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp start_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
*/
proto.qai_hub.api.ProfileJobSummary.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
 */
proto.qai_hub.api.ProfileJobSummary.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp completion_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.getCompletionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
*/
proto.qai_hub.api.ProfileJobSummary.prototype.setCompletionTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
 */
proto.qai_hub.api.ProfileJobSummary.prototype.clearCompletionTime = function() {
  return this.setCompletionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.hasCompletionTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp last_updated_time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.getLastUpdatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
*/
proto.qai_hub.api.ProfileJobSummary.prototype.setLastUpdatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
 */
proto.qai_hub.api.ProfileJobSummary.prototype.clearLastUpdatedTime = function() {
  return this.setLastUpdatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.hasLastUpdatedTime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional uint64 execution_time = 12;
 * @return {number}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.getExecutionTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
 */
proto.qai_hub.api.ProfileJobSummary.prototype.setExecutionTime = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
 */
proto.qai_hub.api.ProfileJobSummary.prototype.clearExecutionTime = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.hasExecutionTime = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Range execution_peak_memory = 13;
 * @return {?proto.qai_hub.api.Range}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.getExecutionPeakMemory = function() {
  return /** @type{?proto.qai_hub.api.Range} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Range, 13));
};


/**
 * @param {?proto.qai_hub.api.Range|undefined} value
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
*/
proto.qai_hub.api.ProfileJobSummary.prototype.setExecutionPeakMemory = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
 */
proto.qai_hub.api.ProfileJobSummary.prototype.clearExecutionPeakMemory = function() {
  return this.setExecutionPeakMemory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.hasExecutionPeakMemory = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Timestamp queued_time = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.getQueuedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
*/
proto.qai_hub.api.ProfileJobSummary.prototype.setQueuedTime = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJobSummary} returns this
 */
proto.qai_hub.api.ProfileJobSummary.prototype.clearQueuedTime = function() {
  return this.setQueuedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJobSummary.prototype.hasQueuedTime = function() {
  return jspb.Message.getField(this, 14) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.InferenceJobSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.InferenceJobSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.InferenceJobSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && proto.qai_hub.api.User.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    jobState: jspb.Message.getFieldWithDefault(msg, 4, 0),
    deviceName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    deviceOsVersion: jspb.Message.getFieldWithDefault(msg, 6, ""),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    completionTime: (f = msg.getCompletionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastUpdatedTime: (f = msg.getLastUpdatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    queuedTime: (f = msg.getQueuedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.InferenceJobSummary}
 */
proto.qai_hub.api.InferenceJobSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.InferenceJobSummary;
  return proto.qai_hub.api.InferenceJobSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.InferenceJobSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.InferenceJobSummary}
 */
proto.qai_hub.api.InferenceJobSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobId(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.User;
      reader.readMessage(value,proto.qai_hub.api.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!proto.qai_hub.api.JobState} */ (reader.readEnum());
      msg.setJobState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceOsVersion(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletionTime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdatedTime(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setQueuedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.InferenceJobSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.InferenceJobSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.InferenceJobSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.User.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getJobState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDeviceName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDeviceOsVersion();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCompletionTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastUpdatedTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getQueuedTime();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string job_id = 1;
 * @return {string}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.getJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.InferenceJobSummary} returns this
 */
proto.qai_hub.api.InferenceJobSummary.prototype.setJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 2;
 * @return {?proto.qai_hub.api.User}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.getUser = function() {
  return /** @type{?proto.qai_hub.api.User} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.User, 2));
};


/**
 * @param {?proto.qai_hub.api.User|undefined} value
 * @return {!proto.qai_hub.api.InferenceJobSummary} returns this
*/
proto.qai_hub.api.InferenceJobSummary.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJobSummary} returns this
 */
proto.qai_hub.api.InferenceJobSummary.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.InferenceJobSummary} returns this
 */
proto.qai_hub.api.InferenceJobSummary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional JobState job_state = 4;
 * @return {!proto.qai_hub.api.JobState}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.getJobState = function() {
  return /** @type {!proto.qai_hub.api.JobState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.qai_hub.api.JobState} value
 * @return {!proto.qai_hub.api.InferenceJobSummary} returns this
 */
proto.qai_hub.api.InferenceJobSummary.prototype.setJobState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string device_name = 5;
 * @return {string}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.getDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.InferenceJobSummary} returns this
 */
proto.qai_hub.api.InferenceJobSummary.prototype.setDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string device_os_version = 6;
 * @return {string}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.getDeviceOsVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.InferenceJobSummary} returns this
 */
proto.qai_hub.api.InferenceJobSummary.prototype.setDeviceOsVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp creation_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.InferenceJobSummary} returns this
*/
proto.qai_hub.api.InferenceJobSummary.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJobSummary} returns this
 */
proto.qai_hub.api.InferenceJobSummary.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp start_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.InferenceJobSummary} returns this
*/
proto.qai_hub.api.InferenceJobSummary.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJobSummary} returns this
 */
proto.qai_hub.api.InferenceJobSummary.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp completion_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.getCompletionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.InferenceJobSummary} returns this
*/
proto.qai_hub.api.InferenceJobSummary.prototype.setCompletionTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJobSummary} returns this
 */
proto.qai_hub.api.InferenceJobSummary.prototype.clearCompletionTime = function() {
  return this.setCompletionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.hasCompletionTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp last_updated_time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.getLastUpdatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.InferenceJobSummary} returns this
*/
proto.qai_hub.api.InferenceJobSummary.prototype.setLastUpdatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJobSummary} returns this
 */
proto.qai_hub.api.InferenceJobSummary.prototype.clearLastUpdatedTime = function() {
  return this.setLastUpdatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.hasLastUpdatedTime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp queued_time = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.getQueuedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qai_hub.api.InferenceJobSummary} returns this
*/
proto.qai_hub.api.InferenceJobSummary.prototype.setQueuedTime = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJobSummary} returns this
 */
proto.qai_hub.api.InferenceJobSummary.prototype.clearQueuedTime = function() {
  return this.setQueuedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJobSummary.prototype.hasQueuedTime = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.JobSummaryList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.JobSummaryList.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.JobSummaryList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.JobSummaryList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.JobSummaryList.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobSummariesList: jspb.Message.toObjectList(msg.getJobSummariesList(),
    proto.qai_hub.api.JobSummary.toObject, includeInstance),
    totalQueryCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.JobSummaryList}
 */
proto.qai_hub.api.JobSummaryList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.JobSummaryList;
  return proto.qai_hub.api.JobSummaryList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.JobSummaryList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.JobSummaryList}
 */
proto.qai_hub.api.JobSummaryList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qai_hub.api.JobSummary;
      reader.readMessage(value,proto.qai_hub.api.JobSummary.deserializeBinaryFromReader);
      msg.addJobSummaries(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalQueryCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.JobSummaryList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.JobSummaryList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.JobSummaryList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.JobSummaryList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.qai_hub.api.JobSummary.serializeBinaryToWriter
    );
  }
  f = message.getTotalQueryCount();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * repeated JobSummary job_summaries = 1;
 * @return {!Array<!proto.qai_hub.api.JobSummary>}
 */
proto.qai_hub.api.JobSummaryList.prototype.getJobSummariesList = function() {
  return /** @type{!Array<!proto.qai_hub.api.JobSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qai_hub.api.JobSummary, 1));
};


/**
 * @param {!Array<!proto.qai_hub.api.JobSummary>} value
 * @return {!proto.qai_hub.api.JobSummaryList} returns this
*/
proto.qai_hub.api.JobSummaryList.prototype.setJobSummariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.qai_hub.api.JobSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.JobSummary}
 */
proto.qai_hub.api.JobSummaryList.prototype.addJobSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.qai_hub.api.JobSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.JobSummaryList} returns this
 */
proto.qai_hub.api.JobSummaryList.prototype.clearJobSummariesList = function() {
  return this.setJobSummariesList([]);
};


/**
 * optional uint64 total_query_count = 2;
 * @return {number}
 */
proto.qai_hub.api.JobSummaryList.prototype.getTotalQueryCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.JobSummaryList} returns this
 */
proto.qai_hub.api.JobSummaryList.prototype.setTotalQueryCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.CompileJobResult.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.CompileJobResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.CompileJobResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.CompileJobResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    compileJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    compileDetail: (f = msg.getCompileDetail()) && proto.qai_hub.api.CompileDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.CompileJobResult}
 */
proto.qai_hub.api.CompileJobResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.CompileJobResult;
  return proto.qai_hub.api.CompileJobResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.CompileJobResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.CompileJobResult}
 */
proto.qai_hub.api.CompileJobResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompileJobId(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.CompileDetail;
      reader.readMessage(value,proto.qai_hub.api.CompileDetail.deserializeBinaryFromReader);
      msg.setCompileDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.CompileJobResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.CompileJobResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.CompileJobResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.CompileJobResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompileJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompileDetail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.CompileDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional string compile_job_id = 1;
 * @return {string}
 */
proto.qai_hub.api.CompileJobResult.prototype.getCompileJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.CompileJobResult} returns this
 */
proto.qai_hub.api.CompileJobResult.prototype.setCompileJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CompileDetail compile_detail = 2;
 * @return {?proto.qai_hub.api.CompileDetail}
 */
proto.qai_hub.api.CompileJobResult.prototype.getCompileDetail = function() {
  return /** @type{?proto.qai_hub.api.CompileDetail} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.CompileDetail, 2));
};


/**
 * @param {?proto.qai_hub.api.CompileDetail|undefined} value
 * @return {!proto.qai_hub.api.CompileJobResult} returns this
*/
proto.qai_hub.api.CompileJobResult.prototype.setCompileDetail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.CompileJobResult} returns this
 */
proto.qai_hub.api.CompileJobResult.prototype.clearCompileDetail = function() {
  return this.setCompileDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.CompileJobResult.prototype.hasCompileDetail = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.LinkJobResult.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.LinkJobResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.LinkJobResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.LinkJobResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    linkJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    linkDetail: (f = msg.getLinkDetail()) && proto.qai_hub.api.LinkDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.LinkJobResult}
 */
proto.qai_hub.api.LinkJobResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.LinkJobResult;
  return proto.qai_hub.api.LinkJobResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.LinkJobResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.LinkJobResult}
 */
proto.qai_hub.api.LinkJobResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkJobId(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.LinkDetail;
      reader.readMessage(value,proto.qai_hub.api.LinkDetail.deserializeBinaryFromReader);
      msg.setLinkDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.LinkJobResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.LinkJobResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.LinkJobResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.LinkJobResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinkJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLinkDetail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.LinkDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional string link_job_id = 1;
 * @return {string}
 */
proto.qai_hub.api.LinkJobResult.prototype.getLinkJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.LinkJobResult} returns this
 */
proto.qai_hub.api.LinkJobResult.prototype.setLinkJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LinkDetail link_detail = 2;
 * @return {?proto.qai_hub.api.LinkDetail}
 */
proto.qai_hub.api.LinkJobResult.prototype.getLinkDetail = function() {
  return /** @type{?proto.qai_hub.api.LinkDetail} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.LinkDetail, 2));
};


/**
 * @param {?proto.qai_hub.api.LinkDetail|undefined} value
 * @return {!proto.qai_hub.api.LinkJobResult} returns this
*/
proto.qai_hub.api.LinkJobResult.prototype.setLinkDetail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.LinkJobResult} returns this
 */
proto.qai_hub.api.LinkJobResult.prototype.clearLinkDetail = function() {
  return this.setLinkDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LinkJobResult.prototype.hasLinkDetail = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.QuantizeJobResult.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.QuantizeJobResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.QuantizeJobResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.QuantizeJobResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    quantizeJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantizeDetail: (f = msg.getQuantizeDetail()) && proto.qai_hub.api.QuantizeDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.QuantizeJobResult}
 */
proto.qai_hub.api.QuantizeJobResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.QuantizeJobResult;
  return proto.qai_hub.api.QuantizeJobResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.QuantizeJobResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.QuantizeJobResult}
 */
proto.qai_hub.api.QuantizeJobResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuantizeJobId(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.QuantizeDetail;
      reader.readMessage(value,proto.qai_hub.api.QuantizeDetail.deserializeBinaryFromReader);
      msg.setQuantizeDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.QuantizeJobResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.QuantizeJobResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.QuantizeJobResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.QuantizeJobResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuantizeJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantizeDetail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.QuantizeDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional string quantize_job_id = 1;
 * @return {string}
 */
proto.qai_hub.api.QuantizeJobResult.prototype.getQuantizeJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.QuantizeJobResult} returns this
 */
proto.qai_hub.api.QuantizeJobResult.prototype.setQuantizeJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional QuantizeDetail quantize_detail = 2;
 * @return {?proto.qai_hub.api.QuantizeDetail}
 */
proto.qai_hub.api.QuantizeJobResult.prototype.getQuantizeDetail = function() {
  return /** @type{?proto.qai_hub.api.QuantizeDetail} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.QuantizeDetail, 2));
};


/**
 * @param {?proto.qai_hub.api.QuantizeDetail|undefined} value
 * @return {!proto.qai_hub.api.QuantizeJobResult} returns this
*/
proto.qai_hub.api.QuantizeJobResult.prototype.setQuantizeDetail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.QuantizeJobResult} returns this
 */
proto.qai_hub.api.QuantizeJobResult.prototype.clearQuantizeDetail = function() {
  return this.setQuantizeDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.QuantizeJobResult.prototype.hasQuantizeDetail = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.ProfileJobResult.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.ProfileJobResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.ProfileJobResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.ProfileJobResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profile: (f = msg.getProfile()) && proto.qai_hub.api.ProfileDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.ProfileJobResult}
 */
proto.qai_hub.api.ProfileJobResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.ProfileJobResult;
  return proto.qai_hub.api.ProfileJobResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.ProfileJobResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.ProfileJobResult}
 */
proto.qai_hub.api.ProfileJobResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileJobId(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.ProfileDetail;
      reader.readMessage(value,proto.qai_hub.api.ProfileDetail.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.ProfileJobResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.ProfileJobResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.ProfileJobResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.ProfileJobResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.ProfileDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional string profile_job_id = 1;
 * @return {string}
 */
proto.qai_hub.api.ProfileJobResult.prototype.getProfileJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.ProfileJobResult} returns this
 */
proto.qai_hub.api.ProfileJobResult.prototype.setProfileJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ProfileDetail profile = 2;
 * @return {?proto.qai_hub.api.ProfileDetail}
 */
proto.qai_hub.api.ProfileJobResult.prototype.getProfile = function() {
  return /** @type{?proto.qai_hub.api.ProfileDetail} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.ProfileDetail, 2));
};


/**
 * @param {?proto.qai_hub.api.ProfileDetail|undefined} value
 * @return {!proto.qai_hub.api.ProfileJobResult} returns this
*/
proto.qai_hub.api.ProfileJobResult.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileJobResult} returns this
 */
proto.qai_hub.api.ProfileJobResult.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileJobResult.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.InferenceJobResult.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.InferenceJobResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.InferenceJobResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.InferenceJobResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    inferenceJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    outputDatasetId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    detail: (f = msg.getDetail()) && proto.qai_hub.api.ProfileDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.InferenceJobResult}
 */
proto.qai_hub.api.InferenceJobResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.InferenceJobResult;
  return proto.qai_hub.api.InferenceJobResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.InferenceJobResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.InferenceJobResult}
 */
proto.qai_hub.api.InferenceJobResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInferenceJobId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutputDatasetId(value);
      break;
    case 3:
      var value = new proto.qai_hub.api.ProfileDetail;
      reader.readMessage(value,proto.qai_hub.api.ProfileDetail.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.InferenceJobResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.InferenceJobResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.InferenceJobResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.InferenceJobResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInferenceJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOutputDatasetId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qai_hub.api.ProfileDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional string inference_job_id = 1;
 * @return {string}
 */
proto.qai_hub.api.InferenceJobResult.prototype.getInferenceJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.InferenceJobResult} returns this
 */
proto.qai_hub.api.InferenceJobResult.prototype.setInferenceJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string output_dataset_id = 2;
 * @return {string}
 */
proto.qai_hub.api.InferenceJobResult.prototype.getOutputDatasetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.InferenceJobResult} returns this
 */
proto.qai_hub.api.InferenceJobResult.prototype.setOutputDatasetId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ProfileDetail detail = 3;
 * @return {?proto.qai_hub.api.ProfileDetail}
 */
proto.qai_hub.api.InferenceJobResult.prototype.getDetail = function() {
  return /** @type{?proto.qai_hub.api.ProfileDetail} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.ProfileDetail, 3));
};


/**
 * @param {?proto.qai_hub.api.ProfileDetail|undefined} value
 * @return {!proto.qai_hub.api.InferenceJobResult} returns this
*/
proto.qai_hub.api.InferenceJobResult.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.InferenceJobResult} returns this
 */
proto.qai_hub.api.InferenceJobResult.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.InferenceJobResult.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.qai_hub.api.JobResult.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.qai_hub.api.JobResult.ResultCase = {
  RESULT_NOT_SET: 0,
  PROFILE_JOB_RESULT: 1,
  INFERENCE_JOB_RESULT: 2,
  COMPILE_JOB_RESULT: 3,
  QUANTIZE_JOB_RESULT: 4,
  LINK_JOB_RESULT: 5
};

/**
 * @return {proto.qai_hub.api.JobResult.ResultCase}
 */
proto.qai_hub.api.JobResult.prototype.getResultCase = function() {
  return /** @type {proto.qai_hub.api.JobResult.ResultCase} */(jspb.Message.computeOneofCase(this, proto.qai_hub.api.JobResult.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.JobResult.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.JobResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.JobResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.JobResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileJobResult: (f = msg.getProfileJobResult()) && proto.qai_hub.api.ProfileJobResult.toObject(includeInstance, f),
    inferenceJobResult: (f = msg.getInferenceJobResult()) && proto.qai_hub.api.InferenceJobResult.toObject(includeInstance, f),
    compileJobResult: (f = msg.getCompileJobResult()) && proto.qai_hub.api.CompileJobResult.toObject(includeInstance, f),
    quantizeJobResult: (f = msg.getQuantizeJobResult()) && proto.qai_hub.api.QuantizeJobResult.toObject(includeInstance, f),
    linkJobResult: (f = msg.getLinkJobResult()) && proto.qai_hub.api.LinkJobResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.JobResult}
 */
proto.qai_hub.api.JobResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.JobResult;
  return proto.qai_hub.api.JobResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.JobResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.JobResult}
 */
proto.qai_hub.api.JobResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qai_hub.api.ProfileJobResult;
      reader.readMessage(value,proto.qai_hub.api.ProfileJobResult.deserializeBinaryFromReader);
      msg.setProfileJobResult(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.InferenceJobResult;
      reader.readMessage(value,proto.qai_hub.api.InferenceJobResult.deserializeBinaryFromReader);
      msg.setInferenceJobResult(value);
      break;
    case 3:
      var value = new proto.qai_hub.api.CompileJobResult;
      reader.readMessage(value,proto.qai_hub.api.CompileJobResult.deserializeBinaryFromReader);
      msg.setCompileJobResult(value);
      break;
    case 4:
      var value = new proto.qai_hub.api.QuantizeJobResult;
      reader.readMessage(value,proto.qai_hub.api.QuantizeJobResult.deserializeBinaryFromReader);
      msg.setQuantizeJobResult(value);
      break;
    case 5:
      var value = new proto.qai_hub.api.LinkJobResult;
      reader.readMessage(value,proto.qai_hub.api.LinkJobResult.deserializeBinaryFromReader);
      msg.setLinkJobResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.JobResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.JobResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.JobResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.JobResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileJobResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qai_hub.api.ProfileJobResult.serializeBinaryToWriter
    );
  }
  f = message.getInferenceJobResult();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.InferenceJobResult.serializeBinaryToWriter
    );
  }
  f = message.getCompileJobResult();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qai_hub.api.CompileJobResult.serializeBinaryToWriter
    );
  }
  f = message.getQuantizeJobResult();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.qai_hub.api.QuantizeJobResult.serializeBinaryToWriter
    );
  }
  f = message.getLinkJobResult();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.qai_hub.api.LinkJobResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProfileJobResult profile_job_result = 1;
 * @return {?proto.qai_hub.api.ProfileJobResult}
 */
proto.qai_hub.api.JobResult.prototype.getProfileJobResult = function() {
  return /** @type{?proto.qai_hub.api.ProfileJobResult} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.ProfileJobResult, 1));
};


/**
 * @param {?proto.qai_hub.api.ProfileJobResult|undefined} value
 * @return {!proto.qai_hub.api.JobResult} returns this
*/
proto.qai_hub.api.JobResult.prototype.setProfileJobResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.qai_hub.api.JobResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.JobResult} returns this
 */
proto.qai_hub.api.JobResult.prototype.clearProfileJobResult = function() {
  return this.setProfileJobResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.JobResult.prototype.hasProfileJobResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional InferenceJobResult inference_job_result = 2;
 * @return {?proto.qai_hub.api.InferenceJobResult}
 */
proto.qai_hub.api.JobResult.prototype.getInferenceJobResult = function() {
  return /** @type{?proto.qai_hub.api.InferenceJobResult} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.InferenceJobResult, 2));
};


/**
 * @param {?proto.qai_hub.api.InferenceJobResult|undefined} value
 * @return {!proto.qai_hub.api.JobResult} returns this
*/
proto.qai_hub.api.JobResult.prototype.setInferenceJobResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.qai_hub.api.JobResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.JobResult} returns this
 */
proto.qai_hub.api.JobResult.prototype.clearInferenceJobResult = function() {
  return this.setInferenceJobResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.JobResult.prototype.hasInferenceJobResult = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CompileJobResult compile_job_result = 3;
 * @return {?proto.qai_hub.api.CompileJobResult}
 */
proto.qai_hub.api.JobResult.prototype.getCompileJobResult = function() {
  return /** @type{?proto.qai_hub.api.CompileJobResult} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.CompileJobResult, 3));
};


/**
 * @param {?proto.qai_hub.api.CompileJobResult|undefined} value
 * @return {!proto.qai_hub.api.JobResult} returns this
*/
proto.qai_hub.api.JobResult.prototype.setCompileJobResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.qai_hub.api.JobResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.JobResult} returns this
 */
proto.qai_hub.api.JobResult.prototype.clearCompileJobResult = function() {
  return this.setCompileJobResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.JobResult.prototype.hasCompileJobResult = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional QuantizeJobResult quantize_job_result = 4;
 * @return {?proto.qai_hub.api.QuantizeJobResult}
 */
proto.qai_hub.api.JobResult.prototype.getQuantizeJobResult = function() {
  return /** @type{?proto.qai_hub.api.QuantizeJobResult} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.QuantizeJobResult, 4));
};


/**
 * @param {?proto.qai_hub.api.QuantizeJobResult|undefined} value
 * @return {!proto.qai_hub.api.JobResult} returns this
*/
proto.qai_hub.api.JobResult.prototype.setQuantizeJobResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.qai_hub.api.JobResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.JobResult} returns this
 */
proto.qai_hub.api.JobResult.prototype.clearQuantizeJobResult = function() {
  return this.setQuantizeJobResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.JobResult.prototype.hasQuantizeJobResult = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional LinkJobResult link_job_result = 5;
 * @return {?proto.qai_hub.api.LinkJobResult}
 */
proto.qai_hub.api.JobResult.prototype.getLinkJobResult = function() {
  return /** @type{?proto.qai_hub.api.LinkJobResult} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.LinkJobResult, 5));
};


/**
 * @param {?proto.qai_hub.api.LinkJobResult|undefined} value
 * @return {!proto.qai_hub.api.JobResult} returns this
*/
proto.qai_hub.api.JobResult.prototype.setLinkJobResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.qai_hub.api.JobResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.JobResult} returns this
 */
proto.qai_hub.api.JobResult.prototype.clearLinkJobResult = function() {
  return this.setLinkJobResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.JobResult.prototype.hasLinkJobResult = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.VizGraph.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.VizGraph.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.VizGraph.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.VizGraph} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizGraph.toObject = function(includeInstance, msg) {
  var f, obj = {
    graphType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    subgraphsList: jspb.Message.toObjectList(msg.getSubgraphsList(),
    proto.qai_hub.api.VizSubgraph.toObject, includeInstance),
    parametersMap: (f = msg.getParametersMap()) ? f.toObject(includeInstance, proto.qai_hub.api.VizValue.toObject) : [],
    tensorsMap: (f = msg.getTensorsMap()) ? f.toObject(includeInstance, proto.qai_hub.api.VizTensor.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.VizGraph}
 */
proto.qai_hub.api.VizGraph.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.VizGraph;
  return proto.qai_hub.api.VizGraph.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.VizGraph} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.VizGraph}
 */
proto.qai_hub.api.VizGraph.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.qai_hub.api.VizGraphType} */ (reader.readEnum());
      msg.setGraphType(value);
      break;
    case 3:
      var value = new proto.qai_hub.api.VizSubgraph;
      reader.readMessage(value,proto.qai_hub.api.VizSubgraph.deserializeBinaryFromReader);
      msg.addSubgraphs(value);
      break;
    case 4:
      var value = msg.getParametersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.qai_hub.api.VizValue.deserializeBinaryFromReader, "", new proto.qai_hub.api.VizValue());
         });
      break;
    case 5:
      var value = msg.getTensorsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.qai_hub.api.VizTensor.deserializeBinaryFromReader, "", new proto.qai_hub.api.VizTensor());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.VizGraph.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.VizGraph.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.VizGraph} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizGraph.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGraphType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSubgraphsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.qai_hub.api.VizSubgraph.serializeBinaryToWriter
    );
  }
  f = message.getParametersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.qai_hub.api.VizValue.serializeBinaryToWriter);
  }
  f = message.getTensorsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.qai_hub.api.VizTensor.serializeBinaryToWriter);
  }
};


/**
 * optional VizGraphType graph_type = 1;
 * @return {!proto.qai_hub.api.VizGraphType}
 */
proto.qai_hub.api.VizGraph.prototype.getGraphType = function() {
  return /** @type {!proto.qai_hub.api.VizGraphType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.qai_hub.api.VizGraphType} value
 * @return {!proto.qai_hub.api.VizGraph} returns this
 */
proto.qai_hub.api.VizGraph.prototype.setGraphType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated VizSubgraph subgraphs = 3;
 * @return {!Array<!proto.qai_hub.api.VizSubgraph>}
 */
proto.qai_hub.api.VizGraph.prototype.getSubgraphsList = function() {
  return /** @type{!Array<!proto.qai_hub.api.VizSubgraph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qai_hub.api.VizSubgraph, 3));
};


/**
 * @param {!Array<!proto.qai_hub.api.VizSubgraph>} value
 * @return {!proto.qai_hub.api.VizGraph} returns this
*/
proto.qai_hub.api.VizGraph.prototype.setSubgraphsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.qai_hub.api.VizSubgraph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.VizSubgraph}
 */
proto.qai_hub.api.VizGraph.prototype.addSubgraphs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.qai_hub.api.VizSubgraph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.VizGraph} returns this
 */
proto.qai_hub.api.VizGraph.prototype.clearSubgraphsList = function() {
  return this.setSubgraphsList([]);
};


/**
 * map<string, VizValue> parameters = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.qai_hub.api.VizValue>}
 */
proto.qai_hub.api.VizGraph.prototype.getParametersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.qai_hub.api.VizValue>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.qai_hub.api.VizValue));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qai_hub.api.VizGraph} returns this
 */
proto.qai_hub.api.VizGraph.prototype.clearParametersMap = function() {
  this.getParametersMap().clear();
  return this;};


/**
 * map<string, VizTensor> tensors = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.qai_hub.api.VizTensor>}
 */
proto.qai_hub.api.VizGraph.prototype.getTensorsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.qai_hub.api.VizTensor>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.qai_hub.api.VizTensor));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qai_hub.api.VizGraph} returns this
 */
proto.qai_hub.api.VizGraph.prototype.clearTensorsMap = function() {
  this.getTensorsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.VizSubgraph.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.VizSubgraph.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.VizSubgraph.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.VizSubgraph} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizSubgraph.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.qai_hub.api.VizNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.VizSubgraph}
 */
proto.qai_hub.api.VizSubgraph.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.VizSubgraph;
  return proto.qai_hub.api.VizSubgraph.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.VizSubgraph} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.VizSubgraph}
 */
proto.qai_hub.api.VizSubgraph.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.VizNode;
      reader.readMessage(value,proto.qai_hub.api.VizNode.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.VizSubgraph.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.VizSubgraph.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.VizSubgraph} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizSubgraph.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.qai_hub.api.VizNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.qai_hub.api.VizSubgraph.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.VizSubgraph} returns this
 */
proto.qai_hub.api.VizSubgraph.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated VizNode nodes = 2;
 * @return {!Array<!proto.qai_hub.api.VizNode>}
 */
proto.qai_hub.api.VizSubgraph.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.qai_hub.api.VizNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qai_hub.api.VizNode, 2));
};


/**
 * @param {!Array<!proto.qai_hub.api.VizNode>} value
 * @return {!proto.qai_hub.api.VizSubgraph} returns this
*/
proto.qai_hub.api.VizSubgraph.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.qai_hub.api.VizNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.VizNode}
 */
proto.qai_hub.api.VizSubgraph.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.qai_hub.api.VizNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.VizSubgraph} returns this
 */
proto.qai_hub.api.VizSubgraph.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.VizShape.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.VizShape.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.VizShape.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.VizShape} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizShape.toObject = function(includeInstance, msg) {
  var f, obj = {
    dimList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.VizShape}
 */
proto.qai_hub.api.VizShape.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.VizShape;
  return proto.qai_hub.api.VizShape.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.VizShape} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.VizShape}
 */
proto.qai_hub.api.VizShape.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDim(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.VizShape.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.VizShape.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.VizShape} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizShape.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDimList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 dim = 1;
 * @return {!Array<number>}
 */
proto.qai_hub.api.VizShape.prototype.getDimList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.qai_hub.api.VizShape} returns this
 */
proto.qai_hub.api.VizShape.prototype.setDimList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.VizShape} returns this
 */
proto.qai_hub.api.VizShape.prototype.addDim = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.VizShape} returns this
 */
proto.qai_hub.api.VizShape.prototype.clearDimList = function() {
  return this.setDimList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.VizTensor.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.VizTensor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.VizTensor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizTensor.toObject = function(includeInstance, msg) {
  var f, obj = {
    dtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    shape: (f = msg.getShape()) && proto.qai_hub.api.VizShape.toObject(includeInstance, f),
    parametersMap: (f = msg.getParametersMap()) ? f.toObject(includeInstance, proto.qai_hub.api.VizValue.toObject) : [],
    data: (f = msg.getData()) && proto.qai_hub.api.VizValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.VizTensor}
 */
proto.qai_hub.api.VizTensor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.VizTensor;
  return proto.qai_hub.api.VizTensor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.VizTensor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.VizTensor}
 */
proto.qai_hub.api.VizTensor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {!proto.qai_hub.api.VizDtype} */ (reader.readEnum());
      msg.setDtype(value);
      break;
    case 3:
      var value = new proto.qai_hub.api.VizShape;
      reader.readMessage(value,proto.qai_hub.api.VizShape.deserializeBinaryFromReader);
      msg.setShape(value);
      break;
    case 4:
      var value = msg.getParametersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.qai_hub.api.VizValue.deserializeBinaryFromReader, "", new proto.qai_hub.api.VizValue());
         });
      break;
    case 5:
      var value = new proto.qai_hub.api.VizValue;
      reader.readMessage(value,proto.qai_hub.api.VizValue.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.VizTensor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.VizTensor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.VizTensor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizTensor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getShape();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.qai_hub.api.VizShape.serializeBinaryToWriter
    );
  }
  f = message.getParametersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.qai_hub.api.VizValue.serializeBinaryToWriter);
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.qai_hub.api.VizValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional VizDtype dtype = 2;
 * @return {!proto.qai_hub.api.VizDtype}
 */
proto.qai_hub.api.VizTensor.prototype.getDtype = function() {
  return /** @type {!proto.qai_hub.api.VizDtype} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.qai_hub.api.VizDtype} value
 * @return {!proto.qai_hub.api.VizTensor} returns this
 */
proto.qai_hub.api.VizTensor.prototype.setDtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional VizShape shape = 3;
 * @return {?proto.qai_hub.api.VizShape}
 */
proto.qai_hub.api.VizTensor.prototype.getShape = function() {
  return /** @type{?proto.qai_hub.api.VizShape} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.VizShape, 3));
};


/**
 * @param {?proto.qai_hub.api.VizShape|undefined} value
 * @return {!proto.qai_hub.api.VizTensor} returns this
*/
proto.qai_hub.api.VizTensor.prototype.setShape = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.VizTensor} returns this
 */
proto.qai_hub.api.VizTensor.prototype.clearShape = function() {
  return this.setShape(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.VizTensor.prototype.hasShape = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * map<string, VizValue> parameters = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.qai_hub.api.VizValue>}
 */
proto.qai_hub.api.VizTensor.prototype.getParametersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.qai_hub.api.VizValue>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.qai_hub.api.VizValue));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qai_hub.api.VizTensor} returns this
 */
proto.qai_hub.api.VizTensor.prototype.clearParametersMap = function() {
  this.getParametersMap().clear();
  return this;};


/**
 * optional VizValue data = 5;
 * @return {?proto.qai_hub.api.VizValue}
 */
proto.qai_hub.api.VizTensor.prototype.getData = function() {
  return /** @type{?proto.qai_hub.api.VizValue} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.VizValue, 5));
};


/**
 * @param {?proto.qai_hub.api.VizValue|undefined} value
 * @return {!proto.qai_hub.api.VizTensor} returns this
*/
proto.qai_hub.api.VizTensor.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.VizTensor} returns this
 */
proto.qai_hub.api.VizTensor.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.VizTensor.prototype.hasData = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.VizNode.repeatedFields_ = [3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.VizNode.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.VizNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.VizNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    opType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    inputsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    outputsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    inputKeysList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    outputKeysList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    attributesMap: (f = msg.getAttributesMap()) ? f.toObject(includeInstance, proto.qai_hub.api.VizAttribute.toObject) : [],
    subgraph: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.VizNode}
 */
proto.qai_hub.api.VizNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.VizNode;
  return proto.qai_hub.api.VizNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.VizNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.VizNode}
 */
proto.qai_hub.api.VizNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addInputs(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addOutputs(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addInputKeys(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addOutputKeys(value);
      break;
    case 7:
      var value = msg.getAttributesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.qai_hub.api.VizAttribute.deserializeBinaryFromReader, "", new proto.qai_hub.api.VizAttribute());
         });
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubgraph(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.VizNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.VizNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.VizNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOpType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInputsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getOutputsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getInputKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getOutputKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getAttributesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.qai_hub.api.VizAttribute.serializeBinaryToWriter);
  }
  f = message.getSubgraph();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.qai_hub.api.VizNode.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.VizNode} returns this
 */
proto.qai_hub.api.VizNode.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string op_type = 2;
 * @return {string}
 */
proto.qai_hub.api.VizNode.prototype.getOpType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.VizNode} returns this
 */
proto.qai_hub.api.VizNode.prototype.setOpType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string inputs = 3;
 * @return {!Array<string>}
 */
proto.qai_hub.api.VizNode.prototype.getInputsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qai_hub.api.VizNode} returns this
 */
proto.qai_hub.api.VizNode.prototype.setInputsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.VizNode} returns this
 */
proto.qai_hub.api.VizNode.prototype.addInputs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.VizNode} returns this
 */
proto.qai_hub.api.VizNode.prototype.clearInputsList = function() {
  return this.setInputsList([]);
};


/**
 * repeated string outputs = 4;
 * @return {!Array<string>}
 */
proto.qai_hub.api.VizNode.prototype.getOutputsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qai_hub.api.VizNode} returns this
 */
proto.qai_hub.api.VizNode.prototype.setOutputsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.VizNode} returns this
 */
proto.qai_hub.api.VizNode.prototype.addOutputs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.VizNode} returns this
 */
proto.qai_hub.api.VizNode.prototype.clearOutputsList = function() {
  return this.setOutputsList([]);
};


/**
 * repeated string input_keys = 5;
 * @return {!Array<string>}
 */
proto.qai_hub.api.VizNode.prototype.getInputKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qai_hub.api.VizNode} returns this
 */
proto.qai_hub.api.VizNode.prototype.setInputKeysList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.VizNode} returns this
 */
proto.qai_hub.api.VizNode.prototype.addInputKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.VizNode} returns this
 */
proto.qai_hub.api.VizNode.prototype.clearInputKeysList = function() {
  return this.setInputKeysList([]);
};


/**
 * repeated string output_keys = 6;
 * @return {!Array<string>}
 */
proto.qai_hub.api.VizNode.prototype.getOutputKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qai_hub.api.VizNode} returns this
 */
proto.qai_hub.api.VizNode.prototype.setOutputKeysList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.VizNode} returns this
 */
proto.qai_hub.api.VizNode.prototype.addOutputKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.VizNode} returns this
 */
proto.qai_hub.api.VizNode.prototype.clearOutputKeysList = function() {
  return this.setOutputKeysList([]);
};


/**
 * map<string, VizAttribute> attributes = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.qai_hub.api.VizAttribute>}
 */
proto.qai_hub.api.VizNode.prototype.getAttributesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.qai_hub.api.VizAttribute>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      proto.qai_hub.api.VizAttribute));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qai_hub.api.VizNode} returns this
 */
proto.qai_hub.api.VizNode.prototype.clearAttributesMap = function() {
  this.getAttributesMap().clear();
  return this;};


/**
 * optional int64 subgraph = 8;
 * @return {number}
 */
proto.qai_hub.api.VizNode.prototype.getSubgraph = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.VizNode} returns this
 */
proto.qai_hub.api.VizNode.prototype.setSubgraph = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.qai_hub.api.VizValue.oneofGroups_ = [[1,2,3,4,5,6,7,8,9]];

/**
 * @enum {number}
 */
proto.qai_hub.api.VizValue.ValueCase = {
  VALUE_NOT_SET: 0,
  LITERAL_VALUE: 1,
  STRING_VALUE: 2,
  INTEGER_VALUE: 3,
  FLOAT_VALUE: 4,
  BOOL_VALUE: 5,
  STRING_LIST: 6,
  INTEGER_LIST: 7,
  FLOAT_LIST: 8,
  BOOL_LIST: 9
};

/**
 * @return {proto.qai_hub.api.VizValue.ValueCase}
 */
proto.qai_hub.api.VizValue.prototype.getValueCase = function() {
  return /** @type {proto.qai_hub.api.VizValue.ValueCase} */(jspb.Message.computeOneofCase(this, proto.qai_hub.api.VizValue.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.VizValue.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.VizValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.VizValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    literalValue: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stringValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    integerValue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    floatValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    boolValue: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    stringList: (f = msg.getStringList()) && proto.qai_hub.api.VizValue.StringList.toObject(includeInstance, f),
    integerList: (f = msg.getIntegerList()) && proto.qai_hub.api.VizValue.IntegerList.toObject(includeInstance, f),
    floatList: (f = msg.getFloatList()) && proto.qai_hub.api.VizValue.FloatList.toObject(includeInstance, f),
    boolList: (f = msg.getBoolList()) && proto.qai_hub.api.VizValue.BoolList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.VizValue}
 */
proto.qai_hub.api.VizValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.VizValue;
  return proto.qai_hub.api.VizValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.VizValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.VizValue}
 */
proto.qai_hub.api.VizValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiteralValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIntegerValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatValue(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolValue(value);
      break;
    case 6:
      var value = new proto.qai_hub.api.VizValue.StringList;
      reader.readMessage(value,proto.qai_hub.api.VizValue.StringList.deserializeBinaryFromReader);
      msg.setStringList(value);
      break;
    case 7:
      var value = new proto.qai_hub.api.VizValue.IntegerList;
      reader.readMessage(value,proto.qai_hub.api.VizValue.IntegerList.deserializeBinaryFromReader);
      msg.setIntegerList(value);
      break;
    case 8:
      var value = new proto.qai_hub.api.VizValue.FloatList;
      reader.readMessage(value,proto.qai_hub.api.VizValue.FloatList.deserializeBinaryFromReader);
      msg.setFloatList(value);
      break;
    case 9:
      var value = new proto.qai_hub.api.VizValue.BoolList;
      reader.readMessage(value,proto.qai_hub.api.VizValue.BoolList.deserializeBinaryFromReader);
      msg.setBoolList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.VizValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.VizValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.VizValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getStringList();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.qai_hub.api.VizValue.StringList.serializeBinaryToWriter
    );
  }
  f = message.getIntegerList();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.qai_hub.api.VizValue.IntegerList.serializeBinaryToWriter
    );
  }
  f = message.getFloatList();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.qai_hub.api.VizValue.FloatList.serializeBinaryToWriter
    );
  }
  f = message.getBoolList();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.qai_hub.api.VizValue.BoolList.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.VizValue.StringList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.VizValue.StringList.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.VizValue.StringList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.VizValue.StringList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizValue.StringList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.VizValue.StringList}
 */
proto.qai_hub.api.VizValue.StringList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.VizValue.StringList;
  return proto.qai_hub.api.VizValue.StringList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.VizValue.StringList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.VizValue.StringList}
 */
proto.qai_hub.api.VizValue.StringList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.VizValue.StringList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.VizValue.StringList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.VizValue.StringList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizValue.StringList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string list = 1;
 * @return {!Array<string>}
 */
proto.qai_hub.api.VizValue.StringList.prototype.getListList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qai_hub.api.VizValue.StringList} returns this
 */
proto.qai_hub.api.VizValue.StringList.prototype.setListList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.VizValue.StringList} returns this
 */
proto.qai_hub.api.VizValue.StringList.prototype.addList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.VizValue.StringList} returns this
 */
proto.qai_hub.api.VizValue.StringList.prototype.clearListList = function() {
  return this.setListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.VizValue.IntegerList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.VizValue.IntegerList.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.VizValue.IntegerList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.VizValue.IntegerList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizValue.IntegerList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.VizValue.IntegerList}
 */
proto.qai_hub.api.VizValue.IntegerList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.VizValue.IntegerList;
  return proto.qai_hub.api.VizValue.IntegerList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.VizValue.IntegerList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.VizValue.IntegerList}
 */
proto.qai_hub.api.VizValue.IntegerList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addList(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.VizValue.IntegerList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.VizValue.IntegerList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.VizValue.IntegerList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizValue.IntegerList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 list = 1;
 * @return {!Array<number>}
 */
proto.qai_hub.api.VizValue.IntegerList.prototype.getListList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.qai_hub.api.VizValue.IntegerList} returns this
 */
proto.qai_hub.api.VizValue.IntegerList.prototype.setListList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.VizValue.IntegerList} returns this
 */
proto.qai_hub.api.VizValue.IntegerList.prototype.addList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.VizValue.IntegerList} returns this
 */
proto.qai_hub.api.VizValue.IntegerList.prototype.clearListList = function() {
  return this.setListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.VizValue.FloatList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.VizValue.FloatList.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.VizValue.FloatList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.VizValue.FloatList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizValue.FloatList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.VizValue.FloatList}
 */
proto.qai_hub.api.VizValue.FloatList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.VizValue.FloatList;
  return proto.qai_hub.api.VizValue.FloatList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.VizValue.FloatList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.VizValue.FloatList}
 */
proto.qai_hub.api.VizValue.FloatList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addList(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.VizValue.FloatList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.VizValue.FloatList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.VizValue.FloatList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizValue.FloatList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writePackedFloat(
      1,
      f
    );
  }
};


/**
 * repeated float list = 1;
 * @return {!Array<number>}
 */
proto.qai_hub.api.VizValue.FloatList.prototype.getListList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.qai_hub.api.VizValue.FloatList} returns this
 */
proto.qai_hub.api.VizValue.FloatList.prototype.setListList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.VizValue.FloatList} returns this
 */
proto.qai_hub.api.VizValue.FloatList.prototype.addList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.VizValue.FloatList} returns this
 */
proto.qai_hub.api.VizValue.FloatList.prototype.clearListList = function() {
  return this.setListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.VizValue.BoolList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.VizValue.BoolList.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.VizValue.BoolList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.VizValue.BoolList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizValue.BoolList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: (f = jspb.Message.getRepeatedBooleanField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.VizValue.BoolList}
 */
proto.qai_hub.api.VizValue.BoolList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.VizValue.BoolList;
  return proto.qai_hub.api.VizValue.BoolList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.VizValue.BoolList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.VizValue.BoolList}
 */
proto.qai_hub.api.VizValue.BoolList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<boolean>} */ (reader.isDelimited() ? reader.readPackedBool() : [reader.readBool()]);
      for (var i = 0; i < values.length; i++) {
        msg.addList(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.VizValue.BoolList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.VizValue.BoolList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.VizValue.BoolList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizValue.BoolList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writePackedBool(
      1,
      f
    );
  }
};


/**
 * repeated bool list = 1;
 * @return {!Array<boolean>}
 */
proto.qai_hub.api.VizValue.BoolList.prototype.getListList = function() {
  return /** @type {!Array<boolean>} */ (jspb.Message.getRepeatedBooleanField(this, 1));
};


/**
 * @param {!Array<boolean>} value
 * @return {!proto.qai_hub.api.VizValue.BoolList} returns this
 */
proto.qai_hub.api.VizValue.BoolList.prototype.setListList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {boolean} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.VizValue.BoolList} returns this
 */
proto.qai_hub.api.VizValue.BoolList.prototype.addList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.VizValue.BoolList} returns this
 */
proto.qai_hub.api.VizValue.BoolList.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional string literal_value = 1;
 * @return {string}
 */
proto.qai_hub.api.VizValue.prototype.getLiteralValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.VizValue} returns this
 */
proto.qai_hub.api.VizValue.prototype.setLiteralValue = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.qai_hub.api.VizValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.VizValue} returns this
 */
proto.qai_hub.api.VizValue.prototype.clearLiteralValue = function() {
  return jspb.Message.setOneofField(this, 1, proto.qai_hub.api.VizValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.VizValue.prototype.hasLiteralValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string string_value = 2;
 * @return {string}
 */
proto.qai_hub.api.VizValue.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.VizValue} returns this
 */
proto.qai_hub.api.VizValue.prototype.setStringValue = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.qai_hub.api.VizValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.VizValue} returns this
 */
proto.qai_hub.api.VizValue.prototype.clearStringValue = function() {
  return jspb.Message.setOneofField(this, 2, proto.qai_hub.api.VizValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.VizValue.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 integer_value = 3;
 * @return {number}
 */
proto.qai_hub.api.VizValue.prototype.getIntegerValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.VizValue} returns this
 */
proto.qai_hub.api.VizValue.prototype.setIntegerValue = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.qai_hub.api.VizValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.VizValue} returns this
 */
proto.qai_hub.api.VizValue.prototype.clearIntegerValue = function() {
  return jspb.Message.setOneofField(this, 3, proto.qai_hub.api.VizValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.VizValue.prototype.hasIntegerValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float float_value = 4;
 * @return {number}
 */
proto.qai_hub.api.VizValue.prototype.getFloatValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.VizValue} returns this
 */
proto.qai_hub.api.VizValue.prototype.setFloatValue = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.qai_hub.api.VizValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.VizValue} returns this
 */
proto.qai_hub.api.VizValue.prototype.clearFloatValue = function() {
  return jspb.Message.setOneofField(this, 4, proto.qai_hub.api.VizValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.VizValue.prototype.hasFloatValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool bool_value = 5;
 * @return {boolean}
 */
proto.qai_hub.api.VizValue.prototype.getBoolValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qai_hub.api.VizValue} returns this
 */
proto.qai_hub.api.VizValue.prototype.setBoolValue = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.qai_hub.api.VizValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.VizValue} returns this
 */
proto.qai_hub.api.VizValue.prototype.clearBoolValue = function() {
  return jspb.Message.setOneofField(this, 5, proto.qai_hub.api.VizValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.VizValue.prototype.hasBoolValue = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional StringList string_list = 6;
 * @return {?proto.qai_hub.api.VizValue.StringList}
 */
proto.qai_hub.api.VizValue.prototype.getStringList = function() {
  return /** @type{?proto.qai_hub.api.VizValue.StringList} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.VizValue.StringList, 6));
};


/**
 * @param {?proto.qai_hub.api.VizValue.StringList|undefined} value
 * @return {!proto.qai_hub.api.VizValue} returns this
*/
proto.qai_hub.api.VizValue.prototype.setStringList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.qai_hub.api.VizValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.VizValue} returns this
 */
proto.qai_hub.api.VizValue.prototype.clearStringList = function() {
  return this.setStringList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.VizValue.prototype.hasStringList = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional IntegerList integer_list = 7;
 * @return {?proto.qai_hub.api.VizValue.IntegerList}
 */
proto.qai_hub.api.VizValue.prototype.getIntegerList = function() {
  return /** @type{?proto.qai_hub.api.VizValue.IntegerList} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.VizValue.IntegerList, 7));
};


/**
 * @param {?proto.qai_hub.api.VizValue.IntegerList|undefined} value
 * @return {!proto.qai_hub.api.VizValue} returns this
*/
proto.qai_hub.api.VizValue.prototype.setIntegerList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.qai_hub.api.VizValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.VizValue} returns this
 */
proto.qai_hub.api.VizValue.prototype.clearIntegerList = function() {
  return this.setIntegerList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.VizValue.prototype.hasIntegerList = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional FloatList float_list = 8;
 * @return {?proto.qai_hub.api.VizValue.FloatList}
 */
proto.qai_hub.api.VizValue.prototype.getFloatList = function() {
  return /** @type{?proto.qai_hub.api.VizValue.FloatList} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.VizValue.FloatList, 8));
};


/**
 * @param {?proto.qai_hub.api.VizValue.FloatList|undefined} value
 * @return {!proto.qai_hub.api.VizValue} returns this
*/
proto.qai_hub.api.VizValue.prototype.setFloatList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.qai_hub.api.VizValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.VizValue} returns this
 */
proto.qai_hub.api.VizValue.prototype.clearFloatList = function() {
  return this.setFloatList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.VizValue.prototype.hasFloatList = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional BoolList bool_list = 9;
 * @return {?proto.qai_hub.api.VizValue.BoolList}
 */
proto.qai_hub.api.VizValue.prototype.getBoolList = function() {
  return /** @type{?proto.qai_hub.api.VizValue.BoolList} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.VizValue.BoolList, 9));
};


/**
 * @param {?proto.qai_hub.api.VizValue.BoolList|undefined} value
 * @return {!proto.qai_hub.api.VizValue} returns this
*/
proto.qai_hub.api.VizValue.prototype.setBoolList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.qai_hub.api.VizValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.VizValue} returns this
 */
proto.qai_hub.api.VizValue.prototype.clearBoolList = function() {
  return this.setBoolList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.VizValue.prototype.hasBoolList = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.qai_hub.api.VizAttribute.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.qai_hub.api.VizAttribute.TypeCase = {
  TYPE_NOT_SET: 0,
  VALUE: 1,
  TENSOR: 2
};

/**
 * @return {proto.qai_hub.api.VizAttribute.TypeCase}
 */
proto.qai_hub.api.VizAttribute.prototype.getTypeCase = function() {
  return /** @type {proto.qai_hub.api.VizAttribute.TypeCase} */(jspb.Message.computeOneofCase(this, proto.qai_hub.api.VizAttribute.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.VizAttribute.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.VizAttribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.VizAttribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizAttribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && proto.qai_hub.api.VizValue.toObject(includeInstance, f),
    tensor: (f = msg.getTensor()) && proto.qai_hub.api.VizTensor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.VizAttribute}
 */
proto.qai_hub.api.VizAttribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.VizAttribute;
  return proto.qai_hub.api.VizAttribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.VizAttribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.VizAttribute}
 */
proto.qai_hub.api.VizAttribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qai_hub.api.VizValue;
      reader.readMessage(value,proto.qai_hub.api.VizValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.VizTensor;
      reader.readMessage(value,proto.qai_hub.api.VizTensor.deserializeBinaryFromReader);
      msg.setTensor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.VizAttribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.VizAttribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.VizAttribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.VizAttribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qai_hub.api.VizValue.serializeBinaryToWriter
    );
  }
  f = message.getTensor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.VizTensor.serializeBinaryToWriter
    );
  }
};


/**
 * optional VizValue value = 1;
 * @return {?proto.qai_hub.api.VizValue}
 */
proto.qai_hub.api.VizAttribute.prototype.getValue = function() {
  return /** @type{?proto.qai_hub.api.VizValue} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.VizValue, 1));
};


/**
 * @param {?proto.qai_hub.api.VizValue|undefined} value
 * @return {!proto.qai_hub.api.VizAttribute} returns this
*/
proto.qai_hub.api.VizAttribute.prototype.setValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.qai_hub.api.VizAttribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.VizAttribute} returns this
 */
proto.qai_hub.api.VizAttribute.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.VizAttribute.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional VizTensor tensor = 2;
 * @return {?proto.qai_hub.api.VizTensor}
 */
proto.qai_hub.api.VizAttribute.prototype.getTensor = function() {
  return /** @type{?proto.qai_hub.api.VizTensor} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.VizTensor, 2));
};


/**
 * @param {?proto.qai_hub.api.VizTensor|undefined} value
 * @return {!proto.qai_hub.api.VizAttribute} returns this
*/
proto.qai_hub.api.VizAttribute.prototype.setTensor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.qai_hub.api.VizAttribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.VizAttribute} returns this
 */
proto.qai_hub.api.VizAttribute.prototype.clearTensor = function() {
  return this.setTensor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.VizAttribute.prototype.hasTensor = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.LayerDetail.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.LayerDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.LayerDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.LayerDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.LayerDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    computeUnit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    layerTypeName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    id: jspb.Message.getFieldWithDefault(msg, 4, ""),
    delegateName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    delegateExtraInfo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    executionTime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    segmentId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    delegateReportedOpsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    executionCycles: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.LayerDetail}
 */
proto.qai_hub.api.LayerDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.LayerDetail;
  return proto.qai_hub.api.LayerDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.LayerDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.LayerDetail}
 */
proto.qai_hub.api.LayerDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.qai_hub.api.ComputeUnit} */ (reader.readEnum());
      msg.setComputeUnit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLayerTypeName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelegateName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelegateExtraInfo(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setExecutionTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSegmentId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addDelegateReportedOps(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setExecutionCycles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.LayerDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.LayerDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.LayerDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.LayerDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComputeUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLayerTypeName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDelegateName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDelegateExtraInfo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDelegateReportedOpsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeUint64(
      10,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.qai_hub.api.LayerDetail.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.LayerDetail} returns this
 */
proto.qai_hub.api.LayerDetail.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ComputeUnit compute_unit = 2;
 * @return {!proto.qai_hub.api.ComputeUnit}
 */
proto.qai_hub.api.LayerDetail.prototype.getComputeUnit = function() {
  return /** @type {!proto.qai_hub.api.ComputeUnit} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.qai_hub.api.ComputeUnit} value
 * @return {!proto.qai_hub.api.LayerDetail} returns this
 */
proto.qai_hub.api.LayerDetail.prototype.setComputeUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string layer_type_name = 3;
 * @return {string}
 */
proto.qai_hub.api.LayerDetail.prototype.getLayerTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.LayerDetail} returns this
 */
proto.qai_hub.api.LayerDetail.prototype.setLayerTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string id = 4;
 * @return {string}
 */
proto.qai_hub.api.LayerDetail.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.LayerDetail} returns this
 */
proto.qai_hub.api.LayerDetail.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string delegate_name = 5;
 * @return {string}
 */
proto.qai_hub.api.LayerDetail.prototype.getDelegateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.LayerDetail} returns this
 */
proto.qai_hub.api.LayerDetail.prototype.setDelegateName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string delegate_extra_info = 6;
 * @return {string}
 */
proto.qai_hub.api.LayerDetail.prototype.getDelegateExtraInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.LayerDetail} returns this
 */
proto.qai_hub.api.LayerDetail.prototype.setDelegateExtraInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint64 execution_time = 7;
 * @return {number}
 */
proto.qai_hub.api.LayerDetail.prototype.getExecutionTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.LayerDetail} returns this
 */
proto.qai_hub.api.LayerDetail.prototype.setExecutionTime = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.LayerDetail} returns this
 */
proto.qai_hub.api.LayerDetail.prototype.clearExecutionTime = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LayerDetail.prototype.hasExecutionTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string segment_id = 8;
 * @return {string}
 */
proto.qai_hub.api.LayerDetail.prototype.getSegmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.LayerDetail} returns this
 */
proto.qai_hub.api.LayerDetail.prototype.setSegmentId = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.LayerDetail} returns this
 */
proto.qai_hub.api.LayerDetail.prototype.clearSegmentId = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LayerDetail.prototype.hasSegmentId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string delegate_reported_ops = 9;
 * @return {!Array<string>}
 */
proto.qai_hub.api.LayerDetail.prototype.getDelegateReportedOpsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qai_hub.api.LayerDetail} returns this
 */
proto.qai_hub.api.LayerDetail.prototype.setDelegateReportedOpsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.LayerDetail} returns this
 */
proto.qai_hub.api.LayerDetail.prototype.addDelegateReportedOps = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.LayerDetail} returns this
 */
proto.qai_hub.api.LayerDetail.prototype.clearDelegateReportedOpsList = function() {
  return this.setDelegateReportedOpsList([]);
};


/**
 * optional uint64 execution_cycles = 10;
 * @return {number}
 */
proto.qai_hub.api.LayerDetail.prototype.getExecutionCycles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.LayerDetail} returns this
 */
proto.qai_hub.api.LayerDetail.prototype.setExecutionCycles = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.LayerDetail} returns this
 */
proto.qai_hub.api.LayerDetail.prototype.clearExecutionCycles = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.LayerDetail.prototype.hasExecutionCycles = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.SegmentDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.SegmentDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.SegmentDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.SegmentDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    computeUnit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    delegateName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    delegateExtraInfo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    executionTime: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.SegmentDetail}
 */
proto.qai_hub.api.SegmentDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.SegmentDetail;
  return proto.qai_hub.api.SegmentDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.SegmentDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.SegmentDetail}
 */
proto.qai_hub.api.SegmentDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.qai_hub.api.ComputeUnit} */ (reader.readEnum());
      msg.setComputeUnit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelegateName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelegateExtraInfo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setExecutionTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.SegmentDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.SegmentDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.SegmentDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.SegmentDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComputeUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDelegateName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDelegateExtraInfo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.qai_hub.api.SegmentDetail.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.SegmentDetail} returns this
 */
proto.qai_hub.api.SegmentDetail.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ComputeUnit compute_unit = 2;
 * @return {!proto.qai_hub.api.ComputeUnit}
 */
proto.qai_hub.api.SegmentDetail.prototype.getComputeUnit = function() {
  return /** @type {!proto.qai_hub.api.ComputeUnit} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.qai_hub.api.ComputeUnit} value
 * @return {!proto.qai_hub.api.SegmentDetail} returns this
 */
proto.qai_hub.api.SegmentDetail.prototype.setComputeUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string delegate_name = 3;
 * @return {string}
 */
proto.qai_hub.api.SegmentDetail.prototype.getDelegateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.SegmentDetail} returns this
 */
proto.qai_hub.api.SegmentDetail.prototype.setDelegateName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string delegate_extra_info = 4;
 * @return {string}
 */
proto.qai_hub.api.SegmentDetail.prototype.getDelegateExtraInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.SegmentDetail} returns this
 */
proto.qai_hub.api.SegmentDetail.prototype.setDelegateExtraInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint64 execution_time = 5;
 * @return {number}
 */
proto.qai_hub.api.SegmentDetail.prototype.getExecutionTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.SegmentDetail} returns this
 */
proto.qai_hub.api.SegmentDetail.prototype.setExecutionTime = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.SegmentDetail} returns this
 */
proto.qai_hub.api.SegmentDetail.prototype.clearExecutionTime = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.SegmentDetail.prototype.hasExecutionTime = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.Range.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.Range.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.Range} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.Range.toObject = function(includeInstance, msg) {
  var f, obj = {
    lower: jspb.Message.getFieldWithDefault(msg, 1, 0),
    upper: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.Range}
 */
proto.qai_hub.api.Range.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.Range;
  return proto.qai_hub.api.Range.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.Range} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.Range}
 */
proto.qai_hub.api.Range.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLower(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUpper(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.Range.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.Range.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.Range} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.Range.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLower();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getUpper();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint64 lower = 1;
 * @return {number}
 */
proto.qai_hub.api.Range.prototype.getLower = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.Range} returns this
 */
proto.qai_hub.api.Range.prototype.setLower = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 upper = 2;
 * @return {number}
 */
proto.qai_hub.api.Range.prototype.getUpper = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.Range} returns this
 */
proto.qai_hub.api.Range.prototype.setUpper = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.RuntimeConfigProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.RuntimeConfigProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.RuntimeConfigProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.RuntimeConfigProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.RuntimeConfigProperty}
 */
proto.qai_hub.api.RuntimeConfigProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.RuntimeConfigProperty;
  return proto.qai_hub.api.RuntimeConfigProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.RuntimeConfigProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.RuntimeConfigProperty}
 */
proto.qai_hub.api.RuntimeConfigProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.RuntimeConfigProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.RuntimeConfigProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.RuntimeConfigProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.RuntimeConfigProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.qai_hub.api.RuntimeConfigProperty.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.RuntimeConfigProperty} returns this
 */
proto.qai_hub.api.RuntimeConfigProperty.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.qai_hub.api.RuntimeConfigProperty.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.RuntimeConfigProperty} returns this
 */
proto.qai_hub.api.RuntimeConfigProperty.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.RuntimeConfig.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.RuntimeConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.RuntimeConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.RuntimeConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.RuntimeConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertiesList: jspb.Message.toObjectList(msg.getPropertiesList(),
    proto.qai_hub.api.RuntimeConfigProperty.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.RuntimeConfig}
 */
proto.qai_hub.api.RuntimeConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.RuntimeConfig;
  return proto.qai_hub.api.RuntimeConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.RuntimeConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.RuntimeConfig}
 */
proto.qai_hub.api.RuntimeConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.RuntimeConfigProperty;
      reader.readMessage(value,proto.qai_hub.api.RuntimeConfigProperty.deserializeBinaryFromReader);
      msg.addProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.RuntimeConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.RuntimeConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.RuntimeConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.RuntimeConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.qai_hub.api.RuntimeConfigProperty.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.qai_hub.api.RuntimeConfig.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.RuntimeConfig} returns this
 */
proto.qai_hub.api.RuntimeConfig.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated RuntimeConfigProperty properties = 2;
 * @return {!Array<!proto.qai_hub.api.RuntimeConfigProperty>}
 */
proto.qai_hub.api.RuntimeConfig.prototype.getPropertiesList = function() {
  return /** @type{!Array<!proto.qai_hub.api.RuntimeConfigProperty>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qai_hub.api.RuntimeConfigProperty, 2));
};


/**
 * @param {!Array<!proto.qai_hub.api.RuntimeConfigProperty>} value
 * @return {!proto.qai_hub.api.RuntimeConfig} returns this
*/
proto.qai_hub.api.RuntimeConfig.prototype.setPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.qai_hub.api.RuntimeConfigProperty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.RuntimeConfigProperty}
 */
proto.qai_hub.api.RuntimeConfig.prototype.addProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.qai_hub.api.RuntimeConfigProperty, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.RuntimeConfig} returns this
 */
proto.qai_hub.api.RuntimeConfig.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.ToolVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.ToolVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.ToolVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.ToolVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.ToolVersion}
 */
proto.qai_hub.api.ToolVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.ToolVersion;
  return proto.qai_hub.api.ToolVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.ToolVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.ToolVersion}
 */
proto.qai_hub.api.ToolVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.ToolVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.ToolVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.ToolVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.ToolVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.qai_hub.api.ToolVersion.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.ToolVersion} returns this
 */
proto.qai_hub.api.ToolVersion.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string version = 2;
 * @return {string}
 */
proto.qai_hub.api.ToolVersion.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qai_hub.api.ToolVersion} returns this
 */
proto.qai_hub.api.ToolVersion.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.ProfileDetail.repeatedFields_ = [4,30,31,32,33,34,35,36];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.ProfileDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.ProfileDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.ProfileDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.ProfileDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    executionTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    peakMemoryUsage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    loadTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    layerDetailsList: jspb.Message.toObjectList(msg.getLayerDetailsList(),
    proto.qai_hub.api.LayerDetail.toObject, includeInstance),
    majorVersion: jspb.Message.getFieldWithDefault(msg, 5, 0),
    minorVersion: jspb.Message.getFieldWithDefault(msg, 6, 0),
    afterColdLoadPeakMemory: jspb.Message.getFieldWithDefault(msg, 7, 0),
    afterCompilePeakMemory: jspb.Message.getFieldWithDefault(msg, 8, 0),
    afterExecutionPeakMemory: jspb.Message.getFieldWithDefault(msg, 9, 0),
    afterWarmLoadPeakMemory: jspb.Message.getFieldWithDefault(msg, 10, 0),
    beforeColdLoadCurrentMemory: jspb.Message.getFieldWithDefault(msg, 11, 0),
    beforeColdLoadPeakMemory: jspb.Message.getFieldWithDefault(msg, 12, 0),
    beforeCompileCurrentMemory: jspb.Message.getFieldWithDefault(msg, 13, 0),
    beforeCompilePeakMemory: jspb.Message.getFieldWithDefault(msg, 14, 0),
    beforeExecutionCurrentMemory: jspb.Message.getFieldWithDefault(msg, 15, 0),
    beforeExecutionPeakMemory: jspb.Message.getFieldWithDefault(msg, 16, 0),
    beforeWarmLoadCurrentMemory: jspb.Message.getFieldWithDefault(msg, 17, 0),
    beforeWarmLoadPeakMemory: jspb.Message.getFieldWithDefault(msg, 18, 0),
    coldLoadTime: jspb.Message.getFieldWithDefault(msg, 19, 0),
    compileTime: jspb.Message.getFieldWithDefault(msg, 20, 0),
    warmLoadTime: jspb.Message.getFieldWithDefault(msg, 21, 0),
    afterColdLoadCurrentMemory: jspb.Message.getFieldWithDefault(msg, 22, 0),
    afterWarmLoadCurrentMemory: jspb.Message.getFieldWithDefault(msg, 23, 0),
    afterCompileCurrentMemory: jspb.Message.getFieldWithDefault(msg, 24, 0),
    afterExecutionCurrentMemory: jspb.Message.getFieldWithDefault(msg, 25, 0),
    compileMemory: (f = msg.getCompileMemory()) && proto.qai_hub.api.ProfileDetail.MemoryUsage.toObject(includeInstance, f),
    coldLoadMemory: (f = msg.getColdLoadMemory()) && proto.qai_hub.api.ProfileDetail.MemoryUsage.toObject(includeInstance, f),
    warmLoadMemory: (f = msg.getWarmLoadMemory()) && proto.qai_hub.api.ProfileDetail.MemoryUsage.toObject(includeInstance, f),
    executionMemory: (f = msg.getExecutionMemory()) && proto.qai_hub.api.ProfileDetail.MemoryUsage.toObject(includeInstance, f),
    allCompileTimesList: (f = jspb.Message.getRepeatedField(msg, 30)) == null ? undefined : f,
    allColdLoadTimesList: (f = jspb.Message.getRepeatedField(msg, 31)) == null ? undefined : f,
    allWarmLoadTimesList: (f = jspb.Message.getRepeatedField(msg, 32)) == null ? undefined : f,
    allExecutionTimesList: (f = jspb.Message.getRepeatedField(msg, 33)) == null ? undefined : f,
    segmentDetailsList: jspb.Message.toObjectList(msg.getSegmentDetailsList(),
    proto.qai_hub.api.SegmentDetail.toObject, includeInstance),
    runtimeConfigList: jspb.Message.toObjectList(msg.getRuntimeConfigList(),
    proto.qai_hub.api.RuntimeConfig.toObject, includeInstance),
    toolVersionsList: jspb.Message.toObjectList(msg.getToolVersionsList(),
    proto.qai_hub.api.ToolVersion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.ProfileDetail}
 */
proto.qai_hub.api.ProfileDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.ProfileDetail;
  return proto.qai_hub.api.ProfileDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.ProfileDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.ProfileDetail}
 */
proto.qai_hub.api.ProfileDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setExecutionTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPeakMemoryUsage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLoadTime(value);
      break;
    case 4:
      var value = new proto.qai_hub.api.LayerDetail;
      reader.readMessage(value,proto.qai_hub.api.LayerDetail.deserializeBinaryFromReader);
      msg.addLayerDetails(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMajorVersion(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMinorVersion(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAfterColdLoadPeakMemory(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAfterCompilePeakMemory(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAfterExecutionPeakMemory(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAfterWarmLoadPeakMemory(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBeforeColdLoadCurrentMemory(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBeforeColdLoadPeakMemory(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBeforeCompileCurrentMemory(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBeforeCompilePeakMemory(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBeforeExecutionCurrentMemory(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBeforeExecutionPeakMemory(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBeforeWarmLoadCurrentMemory(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBeforeWarmLoadPeakMemory(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setColdLoadTime(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCompileTime(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setWarmLoadTime(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAfterColdLoadCurrentMemory(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAfterWarmLoadCurrentMemory(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAfterCompileCurrentMemory(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAfterExecutionCurrentMemory(value);
      break;
    case 26:
      var value = new proto.qai_hub.api.ProfileDetail.MemoryUsage;
      reader.readMessage(value,proto.qai_hub.api.ProfileDetail.MemoryUsage.deserializeBinaryFromReader);
      msg.setCompileMemory(value);
      break;
    case 27:
      var value = new proto.qai_hub.api.ProfileDetail.MemoryUsage;
      reader.readMessage(value,proto.qai_hub.api.ProfileDetail.MemoryUsage.deserializeBinaryFromReader);
      msg.setColdLoadMemory(value);
      break;
    case 28:
      var value = new proto.qai_hub.api.ProfileDetail.MemoryUsage;
      reader.readMessage(value,proto.qai_hub.api.ProfileDetail.MemoryUsage.deserializeBinaryFromReader);
      msg.setWarmLoadMemory(value);
      break;
    case 29:
      var value = new proto.qai_hub.api.ProfileDetail.MemoryUsage;
      reader.readMessage(value,proto.qai_hub.api.ProfileDetail.MemoryUsage.deserializeBinaryFromReader);
      msg.setExecutionMemory(value);
      break;
    case 30:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAllCompileTimes(values[i]);
      }
      break;
    case 31:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAllColdLoadTimes(values[i]);
      }
      break;
    case 32:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAllWarmLoadTimes(values[i]);
      }
      break;
    case 33:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAllExecutionTimes(values[i]);
      }
      break;
    case 34:
      var value = new proto.qai_hub.api.SegmentDetail;
      reader.readMessage(value,proto.qai_hub.api.SegmentDetail.deserializeBinaryFromReader);
      msg.addSegmentDetails(value);
      break;
    case 35:
      var value = new proto.qai_hub.api.RuntimeConfig;
      reader.readMessage(value,proto.qai_hub.api.RuntimeConfig.deserializeBinaryFromReader);
      msg.addRuntimeConfig(value);
      break;
    case 36:
      var value = new proto.qai_hub.api.ToolVersion;
      reader.readMessage(value,proto.qai_hub.api.ToolVersion.deserializeBinaryFromReader);
      msg.addToolVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.ProfileDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.ProfileDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.ProfileDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.ProfileDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExecutionTime();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getPeakMemoryUsage();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getLayerDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.qai_hub.api.LayerDetail.serializeBinaryToWriter
    );
  }
  f = message.getMajorVersion();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getMinorVersion();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getAfterColdLoadPeakMemory();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getAfterCompilePeakMemory();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getAfterExecutionPeakMemory();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getAfterWarmLoadPeakMemory();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getBeforeColdLoadCurrentMemory();
  if (f !== 0) {
    writer.writeUint64(
      11,
      f
    );
  }
  f = message.getBeforeColdLoadPeakMemory();
  if (f !== 0) {
    writer.writeUint64(
      12,
      f
    );
  }
  f = message.getBeforeCompileCurrentMemory();
  if (f !== 0) {
    writer.writeUint64(
      13,
      f
    );
  }
  f = message.getBeforeCompilePeakMemory();
  if (f !== 0) {
    writer.writeUint64(
      14,
      f
    );
  }
  f = message.getBeforeExecutionCurrentMemory();
  if (f !== 0) {
    writer.writeUint64(
      15,
      f
    );
  }
  f = message.getBeforeExecutionPeakMemory();
  if (f !== 0) {
    writer.writeUint64(
      16,
      f
    );
  }
  f = message.getBeforeWarmLoadCurrentMemory();
  if (f !== 0) {
    writer.writeUint64(
      17,
      f
    );
  }
  f = message.getBeforeWarmLoadPeakMemory();
  if (f !== 0) {
    writer.writeUint64(
      18,
      f
    );
  }
  f = message.getColdLoadTime();
  if (f !== 0) {
    writer.writeUint64(
      19,
      f
    );
  }
  f = message.getCompileTime();
  if (f !== 0) {
    writer.writeUint64(
      20,
      f
    );
  }
  f = message.getWarmLoadTime();
  if (f !== 0) {
    writer.writeUint64(
      21,
      f
    );
  }
  f = message.getAfterColdLoadCurrentMemory();
  if (f !== 0) {
    writer.writeUint64(
      22,
      f
    );
  }
  f = message.getAfterWarmLoadCurrentMemory();
  if (f !== 0) {
    writer.writeUint64(
      23,
      f
    );
  }
  f = message.getAfterCompileCurrentMemory();
  if (f !== 0) {
    writer.writeUint64(
      24,
      f
    );
  }
  f = message.getAfterExecutionCurrentMemory();
  if (f !== 0) {
    writer.writeUint64(
      25,
      f
    );
  }
  f = message.getCompileMemory();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.qai_hub.api.ProfileDetail.MemoryUsage.serializeBinaryToWriter
    );
  }
  f = message.getColdLoadMemory();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.qai_hub.api.ProfileDetail.MemoryUsage.serializeBinaryToWriter
    );
  }
  f = message.getWarmLoadMemory();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.qai_hub.api.ProfileDetail.MemoryUsage.serializeBinaryToWriter
    );
  }
  f = message.getExecutionMemory();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.qai_hub.api.ProfileDetail.MemoryUsage.serializeBinaryToWriter
    );
  }
  f = message.getAllCompileTimesList();
  if (f.length > 0) {
    writer.writePackedUint64(
      30,
      f
    );
  }
  f = message.getAllColdLoadTimesList();
  if (f.length > 0) {
    writer.writePackedUint64(
      31,
      f
    );
  }
  f = message.getAllWarmLoadTimesList();
  if (f.length > 0) {
    writer.writePackedUint64(
      32,
      f
    );
  }
  f = message.getAllExecutionTimesList();
  if (f.length > 0) {
    writer.writePackedUint64(
      33,
      f
    );
  }
  f = message.getSegmentDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      34,
      f,
      proto.qai_hub.api.SegmentDetail.serializeBinaryToWriter
    );
  }
  f = message.getRuntimeConfigList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      35,
      f,
      proto.qai_hub.api.RuntimeConfig.serializeBinaryToWriter
    );
  }
  f = message.getToolVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      36,
      f,
      proto.qai_hub.api.ToolVersion.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.ProfileDetail.MemoryUsage.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.ProfileDetail.MemoryUsage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.ProfileDetail.MemoryUsage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.ProfileDetail.MemoryUsage.toObject = function(includeInstance, msg) {
  var f, obj = {
    increase: (f = msg.getIncrease()) && proto.qai_hub.api.Range.toObject(includeInstance, f),
    peak: (f = msg.getPeak()) && proto.qai_hub.api.Range.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.ProfileDetail.MemoryUsage}
 */
proto.qai_hub.api.ProfileDetail.MemoryUsage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.ProfileDetail.MemoryUsage;
  return proto.qai_hub.api.ProfileDetail.MemoryUsage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.ProfileDetail.MemoryUsage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.ProfileDetail.MemoryUsage}
 */
proto.qai_hub.api.ProfileDetail.MemoryUsage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qai_hub.api.Range;
      reader.readMessage(value,proto.qai_hub.api.Range.deserializeBinaryFromReader);
      msg.setIncrease(value);
      break;
    case 2:
      var value = new proto.qai_hub.api.Range;
      reader.readMessage(value,proto.qai_hub.api.Range.deserializeBinaryFromReader);
      msg.setPeak(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.ProfileDetail.MemoryUsage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.ProfileDetail.MemoryUsage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.ProfileDetail.MemoryUsage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.ProfileDetail.MemoryUsage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncrease();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.qai_hub.api.Range.serializeBinaryToWriter
    );
  }
  f = message.getPeak();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qai_hub.api.Range.serializeBinaryToWriter
    );
  }
};


/**
 * optional Range increase = 1;
 * @return {?proto.qai_hub.api.Range}
 */
proto.qai_hub.api.ProfileDetail.MemoryUsage.prototype.getIncrease = function() {
  return /** @type{?proto.qai_hub.api.Range} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Range, 1));
};


/**
 * @param {?proto.qai_hub.api.Range|undefined} value
 * @return {!proto.qai_hub.api.ProfileDetail.MemoryUsage} returns this
*/
proto.qai_hub.api.ProfileDetail.MemoryUsage.prototype.setIncrease = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileDetail.MemoryUsage} returns this
 */
proto.qai_hub.api.ProfileDetail.MemoryUsage.prototype.clearIncrease = function() {
  return this.setIncrease(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileDetail.MemoryUsage.prototype.hasIncrease = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Range peak = 2;
 * @return {?proto.qai_hub.api.Range}
 */
proto.qai_hub.api.ProfileDetail.MemoryUsage.prototype.getPeak = function() {
  return /** @type{?proto.qai_hub.api.Range} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.Range, 2));
};


/**
 * @param {?proto.qai_hub.api.Range|undefined} value
 * @return {!proto.qai_hub.api.ProfileDetail.MemoryUsage} returns this
*/
proto.qai_hub.api.ProfileDetail.MemoryUsage.prototype.setPeak = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileDetail.MemoryUsage} returns this
 */
proto.qai_hub.api.ProfileDetail.MemoryUsage.prototype.clearPeak = function() {
  return this.setPeak(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileDetail.MemoryUsage.prototype.hasPeak = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 execution_time = 1;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getExecutionTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setExecutionTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 peak_memory_usage = 2;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getPeakMemoryUsage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setPeakMemoryUsage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 load_time = 3;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getLoadTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setLoadTime = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.clearLoadTime = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileDetail.prototype.hasLoadTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated LayerDetail layer_details = 4;
 * @return {!Array<!proto.qai_hub.api.LayerDetail>}
 */
proto.qai_hub.api.ProfileDetail.prototype.getLayerDetailsList = function() {
  return /** @type{!Array<!proto.qai_hub.api.LayerDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qai_hub.api.LayerDetail, 4));
};


/**
 * @param {!Array<!proto.qai_hub.api.LayerDetail>} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
*/
proto.qai_hub.api.ProfileDetail.prototype.setLayerDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.qai_hub.api.LayerDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.LayerDetail}
 */
proto.qai_hub.api.ProfileDetail.prototype.addLayerDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.qai_hub.api.LayerDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.clearLayerDetailsList = function() {
  return this.setLayerDetailsList([]);
};


/**
 * optional uint64 major_version = 5;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getMajorVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setMajorVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 minor_version = 6;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getMinorVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setMinorVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 after_cold_load_peak_memory = 7;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getAfterColdLoadPeakMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setAfterColdLoadPeakMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 after_compile_peak_memory = 8;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getAfterCompilePeakMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setAfterCompilePeakMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 after_execution_peak_memory = 9;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getAfterExecutionPeakMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setAfterExecutionPeakMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint64 after_warm_load_peak_memory = 10;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getAfterWarmLoadPeakMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setAfterWarmLoadPeakMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint64 before_cold_load_current_memory = 11;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getBeforeColdLoadCurrentMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setBeforeColdLoadCurrentMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint64 before_cold_load_peak_memory = 12;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getBeforeColdLoadPeakMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setBeforeColdLoadPeakMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint64 before_compile_current_memory = 13;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getBeforeCompileCurrentMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setBeforeCompileCurrentMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint64 before_compile_peak_memory = 14;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getBeforeCompilePeakMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setBeforeCompilePeakMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint64 before_execution_current_memory = 15;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getBeforeExecutionCurrentMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setBeforeExecutionCurrentMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint64 before_execution_peak_memory = 16;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getBeforeExecutionPeakMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setBeforeExecutionPeakMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional uint64 before_warm_load_current_memory = 17;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getBeforeWarmLoadCurrentMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setBeforeWarmLoadCurrentMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional uint64 before_warm_load_peak_memory = 18;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getBeforeWarmLoadPeakMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setBeforeWarmLoadPeakMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional uint64 cold_load_time = 19;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getColdLoadTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setColdLoadTime = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional uint64 compile_time = 20;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getCompileTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setCompileTime = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional uint64 warm_load_time = 21;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getWarmLoadTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setWarmLoadTime = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional uint64 after_cold_load_current_memory = 22;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getAfterColdLoadCurrentMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setAfterColdLoadCurrentMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional uint64 after_warm_load_current_memory = 23;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getAfterWarmLoadCurrentMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setAfterWarmLoadCurrentMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional uint64 after_compile_current_memory = 24;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getAfterCompileCurrentMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setAfterCompileCurrentMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional uint64 after_execution_current_memory = 25;
 * @return {number}
 */
proto.qai_hub.api.ProfileDetail.prototype.getAfterExecutionCurrentMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setAfterExecutionCurrentMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional MemoryUsage compile_memory = 26;
 * @return {?proto.qai_hub.api.ProfileDetail.MemoryUsage}
 */
proto.qai_hub.api.ProfileDetail.prototype.getCompileMemory = function() {
  return /** @type{?proto.qai_hub.api.ProfileDetail.MemoryUsage} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.ProfileDetail.MemoryUsage, 26));
};


/**
 * @param {?proto.qai_hub.api.ProfileDetail.MemoryUsage|undefined} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
*/
proto.qai_hub.api.ProfileDetail.prototype.setCompileMemory = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.clearCompileMemory = function() {
  return this.setCompileMemory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileDetail.prototype.hasCompileMemory = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional MemoryUsage cold_load_memory = 27;
 * @return {?proto.qai_hub.api.ProfileDetail.MemoryUsage}
 */
proto.qai_hub.api.ProfileDetail.prototype.getColdLoadMemory = function() {
  return /** @type{?proto.qai_hub.api.ProfileDetail.MemoryUsage} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.ProfileDetail.MemoryUsage, 27));
};


/**
 * @param {?proto.qai_hub.api.ProfileDetail.MemoryUsage|undefined} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
*/
proto.qai_hub.api.ProfileDetail.prototype.setColdLoadMemory = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.clearColdLoadMemory = function() {
  return this.setColdLoadMemory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileDetail.prototype.hasColdLoadMemory = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional MemoryUsage warm_load_memory = 28;
 * @return {?proto.qai_hub.api.ProfileDetail.MemoryUsage}
 */
proto.qai_hub.api.ProfileDetail.prototype.getWarmLoadMemory = function() {
  return /** @type{?proto.qai_hub.api.ProfileDetail.MemoryUsage} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.ProfileDetail.MemoryUsage, 28));
};


/**
 * @param {?proto.qai_hub.api.ProfileDetail.MemoryUsage|undefined} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
*/
proto.qai_hub.api.ProfileDetail.prototype.setWarmLoadMemory = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.clearWarmLoadMemory = function() {
  return this.setWarmLoadMemory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileDetail.prototype.hasWarmLoadMemory = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional MemoryUsage execution_memory = 29;
 * @return {?proto.qai_hub.api.ProfileDetail.MemoryUsage}
 */
proto.qai_hub.api.ProfileDetail.prototype.getExecutionMemory = function() {
  return /** @type{?proto.qai_hub.api.ProfileDetail.MemoryUsage} */ (
    jspb.Message.getWrapperField(this, proto.qai_hub.api.ProfileDetail.MemoryUsage, 29));
};


/**
 * @param {?proto.qai_hub.api.ProfileDetail.MemoryUsage|undefined} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
*/
proto.qai_hub.api.ProfileDetail.prototype.setExecutionMemory = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.clearExecutionMemory = function() {
  return this.setExecutionMemory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qai_hub.api.ProfileDetail.prototype.hasExecutionMemory = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * repeated uint64 all_compile_times = 30;
 * @return {!Array<number>}
 */
proto.qai_hub.api.ProfileDetail.prototype.getAllCompileTimesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 30));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setAllCompileTimesList = function(value) {
  return jspb.Message.setField(this, 30, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.addAllCompileTimes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 30, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.clearAllCompileTimesList = function() {
  return this.setAllCompileTimesList([]);
};


/**
 * repeated uint64 all_cold_load_times = 31;
 * @return {!Array<number>}
 */
proto.qai_hub.api.ProfileDetail.prototype.getAllColdLoadTimesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 31));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setAllColdLoadTimesList = function(value) {
  return jspb.Message.setField(this, 31, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.addAllColdLoadTimes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 31, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.clearAllColdLoadTimesList = function() {
  return this.setAllColdLoadTimesList([]);
};


/**
 * repeated uint64 all_warm_load_times = 32;
 * @return {!Array<number>}
 */
proto.qai_hub.api.ProfileDetail.prototype.getAllWarmLoadTimesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 32));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setAllWarmLoadTimesList = function(value) {
  return jspb.Message.setField(this, 32, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.addAllWarmLoadTimes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 32, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.clearAllWarmLoadTimesList = function() {
  return this.setAllWarmLoadTimesList([]);
};


/**
 * repeated uint64 all_execution_times = 33;
 * @return {!Array<number>}
 */
proto.qai_hub.api.ProfileDetail.prototype.getAllExecutionTimesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 33));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.setAllExecutionTimesList = function(value) {
  return jspb.Message.setField(this, 33, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.addAllExecutionTimes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 33, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.clearAllExecutionTimesList = function() {
  return this.setAllExecutionTimesList([]);
};


/**
 * repeated SegmentDetail segment_details = 34;
 * @return {!Array<!proto.qai_hub.api.SegmentDetail>}
 */
proto.qai_hub.api.ProfileDetail.prototype.getSegmentDetailsList = function() {
  return /** @type{!Array<!proto.qai_hub.api.SegmentDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qai_hub.api.SegmentDetail, 34));
};


/**
 * @param {!Array<!proto.qai_hub.api.SegmentDetail>} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
*/
proto.qai_hub.api.ProfileDetail.prototype.setSegmentDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 34, value);
};


/**
 * @param {!proto.qai_hub.api.SegmentDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.SegmentDetail}
 */
proto.qai_hub.api.ProfileDetail.prototype.addSegmentDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 34, opt_value, proto.qai_hub.api.SegmentDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.clearSegmentDetailsList = function() {
  return this.setSegmentDetailsList([]);
};


/**
 * repeated RuntimeConfig runtime_config = 35;
 * @return {!Array<!proto.qai_hub.api.RuntimeConfig>}
 */
proto.qai_hub.api.ProfileDetail.prototype.getRuntimeConfigList = function() {
  return /** @type{!Array<!proto.qai_hub.api.RuntimeConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qai_hub.api.RuntimeConfig, 35));
};


/**
 * @param {!Array<!proto.qai_hub.api.RuntimeConfig>} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
*/
proto.qai_hub.api.ProfileDetail.prototype.setRuntimeConfigList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 35, value);
};


/**
 * @param {!proto.qai_hub.api.RuntimeConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.RuntimeConfig}
 */
proto.qai_hub.api.ProfileDetail.prototype.addRuntimeConfig = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 35, opt_value, proto.qai_hub.api.RuntimeConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.clearRuntimeConfigList = function() {
  return this.setRuntimeConfigList([]);
};


/**
 * repeated ToolVersion tool_versions = 36;
 * @return {!Array<!proto.qai_hub.api.ToolVersion>}
 */
proto.qai_hub.api.ProfileDetail.prototype.getToolVersionsList = function() {
  return /** @type{!Array<!proto.qai_hub.api.ToolVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qai_hub.api.ToolVersion, 36));
};


/**
 * @param {!Array<!proto.qai_hub.api.ToolVersion>} value
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
*/
proto.qai_hub.api.ProfileDetail.prototype.setToolVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 36, value);
};


/**
 * @param {!proto.qai_hub.api.ToolVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.ToolVersion}
 */
proto.qai_hub.api.ProfileDetail.prototype.addToolVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 36, opt_value, proto.qai_hub.api.ToolVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.ProfileDetail} returns this
 */
proto.qai_hub.api.ProfileDetail.prototype.clearToolVersionsList = function() {
  return this.setToolVersionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.CompileDetail.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.CompileDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.CompileDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.CompileDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.CompileDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    toolVersionsList: jspb.Message.toObjectList(msg.getToolVersionsList(),
    proto.qai_hub.api.ToolVersion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.CompileDetail}
 */
proto.qai_hub.api.CompileDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.CompileDetail;
  return proto.qai_hub.api.CompileDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.CompileDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.CompileDetail}
 */
proto.qai_hub.api.CompileDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qai_hub.api.ToolVersion;
      reader.readMessage(value,proto.qai_hub.api.ToolVersion.deserializeBinaryFromReader);
      msg.addToolVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.CompileDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.CompileDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.CompileDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.CompileDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToolVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.qai_hub.api.ToolVersion.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ToolVersion tool_versions = 1;
 * @return {!Array<!proto.qai_hub.api.ToolVersion>}
 */
proto.qai_hub.api.CompileDetail.prototype.getToolVersionsList = function() {
  return /** @type{!Array<!proto.qai_hub.api.ToolVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qai_hub.api.ToolVersion, 1));
};


/**
 * @param {!Array<!proto.qai_hub.api.ToolVersion>} value
 * @return {!proto.qai_hub.api.CompileDetail} returns this
*/
proto.qai_hub.api.CompileDetail.prototype.setToolVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.qai_hub.api.ToolVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.ToolVersion}
 */
proto.qai_hub.api.CompileDetail.prototype.addToolVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.qai_hub.api.ToolVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.CompileDetail} returns this
 */
proto.qai_hub.api.CompileDetail.prototype.clearToolVersionsList = function() {
  return this.setToolVersionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.LinkDetail.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.LinkDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.LinkDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.LinkDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.LinkDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    toolVersionsList: jspb.Message.toObjectList(msg.getToolVersionsList(),
    proto.qai_hub.api.ToolVersion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.LinkDetail}
 */
proto.qai_hub.api.LinkDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.LinkDetail;
  return proto.qai_hub.api.LinkDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.LinkDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.LinkDetail}
 */
proto.qai_hub.api.LinkDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qai_hub.api.ToolVersion;
      reader.readMessage(value,proto.qai_hub.api.ToolVersion.deserializeBinaryFromReader);
      msg.addToolVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.LinkDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.LinkDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.LinkDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.LinkDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToolVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.qai_hub.api.ToolVersion.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ToolVersion tool_versions = 1;
 * @return {!Array<!proto.qai_hub.api.ToolVersion>}
 */
proto.qai_hub.api.LinkDetail.prototype.getToolVersionsList = function() {
  return /** @type{!Array<!proto.qai_hub.api.ToolVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qai_hub.api.ToolVersion, 1));
};


/**
 * @param {!Array<!proto.qai_hub.api.ToolVersion>} value
 * @return {!proto.qai_hub.api.LinkDetail} returns this
*/
proto.qai_hub.api.LinkDetail.prototype.setToolVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.qai_hub.api.ToolVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.ToolVersion}
 */
proto.qai_hub.api.LinkDetail.prototype.addToolVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.qai_hub.api.ToolVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.LinkDetail} returns this
 */
proto.qai_hub.api.LinkDetail.prototype.clearToolVersionsList = function() {
  return this.setToolVersionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qai_hub.api.QuantizeDetail.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qai_hub.api.QuantizeDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.qai_hub.api.QuantizeDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qai_hub.api.QuantizeDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.QuantizeDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    toolVersionsList: jspb.Message.toObjectList(msg.getToolVersionsList(),
    proto.qai_hub.api.ToolVersion.toObject, includeInstance),
    psnrMap: (f = msg.getPsnrMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qai_hub.api.QuantizeDetail}
 */
proto.qai_hub.api.QuantizeDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qai_hub.api.QuantizeDetail;
  return proto.qai_hub.api.QuantizeDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qai_hub.api.QuantizeDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qai_hub.api.QuantizeDetail}
 */
proto.qai_hub.api.QuantizeDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.qai_hub.api.ToolVersion;
      reader.readMessage(value,proto.qai_hub.api.ToolVersion.deserializeBinaryFromReader);
      msg.addToolVersions(value);
      break;
    case 2:
      var value = msg.getPsnrMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readFloat, null, "", 0.0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qai_hub.api.QuantizeDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qai_hub.api.QuantizeDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qai_hub.api.QuantizeDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qai_hub.api.QuantizeDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToolVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.qai_hub.api.ToolVersion.serializeBinaryToWriter
    );
  }
  f = message.getPsnrMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeFloat);
  }
};


/**
 * repeated ToolVersion tool_versions = 1;
 * @return {!Array<!proto.qai_hub.api.ToolVersion>}
 */
proto.qai_hub.api.QuantizeDetail.prototype.getToolVersionsList = function() {
  return /** @type{!Array<!proto.qai_hub.api.ToolVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.qai_hub.api.ToolVersion, 1));
};


/**
 * @param {!Array<!proto.qai_hub.api.ToolVersion>} value
 * @return {!proto.qai_hub.api.QuantizeDetail} returns this
*/
proto.qai_hub.api.QuantizeDetail.prototype.setToolVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.qai_hub.api.ToolVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qai_hub.api.ToolVersion}
 */
proto.qai_hub.api.QuantizeDetail.prototype.addToolVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.qai_hub.api.ToolVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qai_hub.api.QuantizeDetail} returns this
 */
proto.qai_hub.api.QuantizeDetail.prototype.clearToolVersionsList = function() {
  return this.setToolVersionsList([]);
};


/**
 * map<string, float> psnr = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.qai_hub.api.QuantizeDetail.prototype.getPsnrMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qai_hub.api.QuantizeDetail} returns this
 */
proto.qai_hub.api.QuantizeDetail.prototype.clearPsnrMap = function() {
  this.getPsnrMap().clear();
  return this;};


/**
 * @enum {number}
 */
proto.qai_hub.api.JobState = {
  JOB_STATE_UNSPECIFIED: 0,
  JOB_STATE_CREATED: 1,
  JOB_STATE_DONE: 10,
  JOB_STATE_FAILED: 30,
  JOB_STATE_OPTIMIZING_MODEL: 50,
  JOB_STATE_PROVISIONING_DEVICE: 60,
  JOB_STATE_MEASURING_PERFORMANCE: 70,
  JOB_STATE_RUNNING_INFERENCE: 80,
  JOB_STATE_QUANTIZING_MODEL: 90,
  JOB_STATE_LINKING_MODELS: 100
};

/**
 * @enum {number}
 */
proto.qai_hub.api.TensorDtype = {
  TENSOR_DTYPE_UNSPECIFIED: 0,
  TENSOR_DTYPE_FLOAT32: 1,
  TENSOR_DTYPE_FLOAT16: 8,
  TENSOR_DTYPE_INT32: 2,
  TENSOR_DTYPE_INT64: 3,
  TENSOR_DTYPE_INT8: 4,
  TENSOR_DTYPE_UINT8: 5,
  TENSOR_DTYPE_INT16: 6,
  TENSOR_DTYPE_UINT16: 7
};

/**
 * @enum {number}
 */
proto.qai_hub.api.QuantizeDtype = {
  QUANTIZE_DTYPE_UNSPECIFIED: 0,
  QUANTIZE_DTYPE_INT8: 1,
  QUANTIZE_DTYPE_UINT8: 2,
  QUANTIZE_DTYPE_INT16: 3,
  QUANTIZE_DTYPE_UINT16: 4,
  QUANTIZE_DTYPE_INT4: 5,
  QUANTIZE_DTYPE_UINT4: 6
};

/**
 * @enum {number}
 */
proto.qai_hub.api.ModelType = {
  MODEL_TYPE_UNSPECIFIED: 0,
  MODEL_TYPE_TORCHSCRIPT: 1,
  MODEL_TYPE_MLMODEL: 2,
  MODEL_TYPE_DEPRECATED_UNTRACED_TORCHSCRIPT: 3,
  MODEL_TYPE_TFLITE: 4,
  MODEL_TYPE_MLMODELC: 5,
  MODEL_TYPE_ONNX: 6,
  MODEL_TYPE_ORT: 7,
  MODEL_TYPE_MLPACKAGE: 8,
  MODEL_TYPE_TETRART: 9,
  MODEL_TYPE_QNN_LIB_AARCH64_ANDROID: 10,
  MODEL_TYPE_QNN_CONTEXT_BINARY: 11,
  MODEL_TYPE_AIMET_ONNX: 12,
  MODEL_TYPE_AIMET_PT: 13,
  MODEL_TYPE_QNN_LIB_X86_64_LINUX: 14
};

/**
 * @enum {number}
 */
proto.qai_hub.api.ModelMetadataKey = {
  MODEL_METADATA_KEY_UNSPECIFIED: 0,
  MODEL_METADATA_KEY_QNN_SDK_VERSION: 1,
  MODEL_METADATA_KEY_QNN_SDK_VARIANT: 2,
  MODEL_METADATA_KEY_QNN_CONTEXT_BIN_GRAPH_NAMES: 30,
  MODEL_METADATA_KEY_QNN_CONTEXT_BIN_HEXAGON_VERSION: 31,
  MODEL_METADATA_KEY_QNN_CONTEXT_BIN_SOC_MODEL: 32,
  MODEL_METADATA_KEY_QNN_CONTEXT_BIN_BACKEND: 33,
  MODEL_METADATA_KEY_QNN_CONTEXT_BIN_VTCM: 34,
  MODEL_METADATA_KEY_QNN_CONTEXT_BIN_OPTIMIZATION_LEVEL: 35
};

/**
 * @enum {number}
 */
proto.qai_hub.api.JobType = {
  JOB_TYPE_UNSPECIFIED: 0,
  JOB_TYPE_PROFILE: 1,
  JOB_TYPE_INFERENCE: 2,
  JOB_TYPE_COMPILE: 3,
  JOB_TYPE_QUANTIZE: 4,
  JOB_TYPE_LINK: 5
};

/**
 * @enum {number}
 */
proto.qai_hub.api.VizGraphType = {
  VIZ_GRAPH_TYPE_UNSPECIFIED: 0,
  VIZ_GRAPH_TYPE_MLMODEL: 1,
  VIZ_GRAPH_TYPE_MLPROGRAM: 2,
  VIZ_GRAPH_TYPE_ESPRESSO: 3,
  VIZ_GRAPH_TYPE_TFLITE: 10,
  VIZ_GRAPH_TYPE_ONNX: 11,
  VIZ_GRAPH_TYPE_ORT: 12,
  VIZ_GRAPH_TYPE_QNN: 20
};

/**
 * @enum {number}
 */
proto.qai_hub.api.VizDtype = {
  VIZ_DTYPE_UNSPECIFIED: 0,
  VIZ_DTYPE_FLOAT16: 1,
  VIZ_DTYPE_FLOAT32: 2,
  VIZ_DTYPE_FLOAT64: 3,
  VIZ_DTYPE_UINT8: 4,
  VIZ_DTYPE_INT8: 5,
  VIZ_DTYPE_UINT16: 6,
  VIZ_DTYPE_INT16: 7,
  VIZ_DTYPE_UINT32: 8,
  VIZ_DTYPE_INT32: 9,
  VIZ_DTYPE_UINT64: 10,
  VIZ_DTYPE_INT64: 11,
  VIZ_DTYPE_COMPLEX64: 12,
  VIZ_DTYPE_COMPLEX128: 13,
  VIZ_DTYPE_STRING: 14,
  VIZ_DTYPE_BOOL: 15,
  VIZ_DTYPE_BFLOAT16: 16,
  VIZ_DTYPE_UINT1: 50,
  VIZ_DTYPE_UINT2: 51,
  VIZ_DTYPE_UINT3: 52,
  VIZ_DTYPE_UINT4: 53,
  VIZ_DTYPE_UINT5: 54,
  VIZ_DTYPE_UINT6: 55,
  VIZ_DTYPE_UINT7: 56,
  VIZ_DTYPE_INT4: 63,
  VIZ_DTYPE_QINT4: 70,
  VIZ_DTYPE_QINT8: 71,
  VIZ_DTYPE_QINT16: 72,
  VIZ_DTYPE_QINT32: 73,
  VIZ_DTYPE_QUINT4: 80,
  VIZ_DTYPE_QUINT8: 81,
  VIZ_DTYPE_QUINT16: 82,
  VIZ_DTYPE_QUINT32: 83,
  VIZ_DTYPE_DICT_INT64_TO_FLOAT64: 100,
  VIZ_DTYPE_DICT_STRING_TO_FLOAT64: 101,
  VIZ_DTYPE_TFLITE_RESOURCE: 110,
  VIZ_DTYPE_TFLITE_VARIANT: 111
};

/**
 * @enum {number}
 */
proto.qai_hub.api.ComputeUnit = {
  COMPUTE_UNIT_UNSPECIFIED: 0,
  COMPUTE_UNIT_CPU: 1,
  COMPUTE_UNIT_GPU: 2,
  COMPUTE_UNIT_NPU: 3
};

goog.object.extend(exports, proto.qai_hub.api);
